import IMenuEntry from "common/interfaces/IMenuEntry";
import {appUrlsService} from "common/services";
import {DEFAULT_ACCESS} from "./enums";
import {UserRole} from "@vidazoo/ui-framework";

export default [
	{
		title: "Dashbords",
		iconName: "icon-dashboard-2",
		link: {to: appUrlsService.dashboards()},
		access: DEFAULT_ACCESS,
		items: [
			{
				title: "Dashbords List",
				link: {to: appUrlsService.dashboards()},
				access: DEFAULT_ACCESS,
			},
			{
				title: "Create Dashbord",
				link: {to: appUrlsService.createDashboard()},
				access: [UserRole.ADMIN, UserRole.SUPER_ADMIN],
			}
		]
	},
	{
		title: "Charts",
		iconName: "icon-dashboard",
		link: {to: appUrlsService.charts()},
		access: DEFAULT_ACCESS,
		items: [
			{
				title: "Charts List",
				link: {to: appUrlsService.charts()},
				access: DEFAULT_ACCESS,
			},
			{
				title: "Create Chart",
				link: {to: appUrlsService.createChart()},
				access: [UserRole.ADMIN, UserRole.SUPER_ADMIN],
			}
		]
	},
	{
		title: "Reporting",
		iconName: "icon-reporting",
		link: {to: appUrlsService.reporting()},
		access: DEFAULT_ACCESS,
	},
	{
		title: "Schedule Reporting",
		iconName: "icon-reporting",
		link: {to: appUrlsService.scheduleReports()},
		access: [UserRole.ROOT_ADMIN]
	},
	{
		title: "Change Logs",
		iconName: "icon-history",
		link: {to: appUrlsService.changeLogs()},
		access: DEFAULT_ACCESS,
	},
] as IMenuEntry[];
