import * as React from "react";
import {observer} from "mobx-react";
import {RouteComponentProps} from "react-router";
import {Surface, PageMessage, Loader, IDataGridHeader, DataGrid, DataGridRow, DataGridHeaderColumn} from "@vidazoo/ui";
import ChangeLogsContext from "../../containers/ChangeLogsContext";
import * as theme from "./theme.scss";
import ChangeLogsRow from "../changeLogRow/ChangeLogsRow";

export interface IChangeLogsListProps extends RouteComponentProps<any> {
	setSelectedItem: (item: any) => void;
}

export interface IChangeLogsListState {

}

const FIELDS = ["scope", "account", "auth", "action", "model_name", "model_id", "date", "diff"];

const headers: IDataGridHeader[] = [{
	label: "Scope",
	sortBy: "scope",
	searchKey: "scope"
}, {
	label: "Account",
	sortBy: "account",
	searchKey: "account",
}, {
	label: "user",
}, {
	label: "action",
	sortBy: "action",
	searchKey: "action"
}, {
	label: "model name",
	sortBy: "model_name",
	searchKey: "model_name",
}, {
	label: "model id",
	sortBy: "model_id",
	searchKey: "model_id",
}, {
	label: "date",
	sortBy: "date"
}, {
	label: "diff",
}];

@observer
export default class ChangeLogsList extends React.Component<IChangeLogsListProps, IChangeLogsListState> {

	public static defaultProps: Partial<IChangeLogsListProps> = {};
	public static contextType = ChangeLogsContext;

	constructor(props, context) {
		super(props, context);
	}

	private renderHeaderAndFooter: any = (scrollTop, scrollLeft, columnsWidth, setFreesColumn, freezeColumnIndex) => {
		const {sortDirection, setSearchQuery, sortBy, sort, searchQueries} = this.context.store;

		return (
			<DataGridRow columnsWidth={columnsWidth}>
				{headers.map((header: IDataGridHeader) => {
					const props = {
						blue: true,
						label: header.label,
						sortable: !!header.sortBy,
						sortBy: header.sortBy,
						sortActive: sortBy === header.sortBy,
						searchKey: header.searchKey,
						searchable: !!header.searchKey,
						searchQuery: searchQueries[header.searchKey || ""],
						onSearch: setSearchQuery,
						onSort: sort,
						freezeColumnIndex,
						columnsWidth,
						setFreesColumn,
						sortDirection,
					};
					return (
						<DataGridHeaderColumn {...props}/>
					);
				})}
			</DataGridRow>
		);
	};

	private onColumnResize = (columnsWidth) => {
		this.context.store.setColumnsWidth(columnsWidth);
	};

	private renderRow: any = (index, viewportIndex, columnsWidth, freezeColumnIndex) => {
		const {filteredItems, nameByIdAndScope} = this.context.store;
		const {setSelectedItem} = this.props;

		return (<ChangeLogsRow
			nameByIdAndScope={nameByIdAndScope}
			item={filteredItems[index]}
			columnsWidth={columnsWidth}
			freezeColumnIndex={freezeColumnIndex}
			index={index}
			key={index}
			setSelectedItem={setSelectedItem}
		/>);
	};

	private renderLoader() {
		return (
			<div className={theme.message}>
				<Loader size={1} gray className={theme.loader}/>
				<PageMessage
					small
					title="Loading"
					description="Please wait..."
				/>
			</div>
		);
	}

	public render(): JSX.Element {
		const {filteredItems, isLoading, getColumnsWidth} = this.context.store;

		if (isLoading) {
			return this.renderLoader();
		}
		const columnsWidth = getColumnsWidth();

		return (
			<DataGrid
				onColumnResize={this.onColumnResize}
				className={theme.list}
				extraData={filteredItems}
				headersCount={headers.length}
				itemsCount={filteredItems.length}
				renderRow={this.renderRow}
				rowHeight={60}
				columnsWidth={columnsWidth}
				renderHeader={this.renderHeaderAndFooter}/>
		);
	}
}
