import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import {ILabelValuePair} from "@vidazoo/ui-framework";

export const tagTypes: ILabelValuePair[] = [
	{value: "vast", label: "Vast"},
	{value: "vpaid", label: "Vpaid"},
	{value: "vpaid_js", label: "Vpaid Js"},
	{value: "ima", label: "Ima"},
	{value: "ima_swf", label: "Ima SWF"},
];

export default class TagTypeFilter extends BaseFilter {

	public static type = PlatformReportingFilterType.TagType;

	constructor() {
		super();

		this.allowNew = false;
		this.labelKey = "label";
		this.valueKey = "value";
		this.items = tagTypes;
	}
}
