import * as React from "react";
import * as PropTypes from "prop-types";
import {observer} from "mobx-react";
import DataTableStore from "../../../stores/DataTableStore";
import {Density, PageMessage} from "@vidazoo/ui";
import ReportingDataGrid from "./reportingList/ReportingDataGrid";
import {withRouter} from "react-router-dom";

export interface IDataTableProps {
	chart: any;
	report: any;
	listClassName?: any;
	columnsWidth?: {
		[index: string]: number;
	};
	setColumnsWidth?: (columnsWidth: { [index: string]: number }) => void;
}

export interface IDataTableState {
}

class DiffTable extends React.Component<IDataTableProps, IDataTableState> {

	public static defaultProps: Partial<IDataTableProps> = {};
	private dataTableStore: DataTableStore;

	constructor(props: IDataTableProps) {
		super(props);

		this.dataTableStore = new DataTableStore(props.report);
	}

	private renderNoData(): JSX.Element {
		return (
			<div>
				<PageMessage
					small
					title="Cant find data, try to change chart or filter"
				/>
			</div>
		);
	}

	public render(): JSX.Element {
		if (!this.props.report || (this.props.setColumnsWidth && !this.props.columnsWidth)) {
			return null;
		}

		if (!this.dataTableStore.results || this.dataTableStore.results.length < 1) {
			return this.renderNoData();
		}

		return (
			<ReportingDataGrid
				onSort={this.dataTableStore.sort}
				sortBy={this.dataTableStore.ui.sortBy}
				density={Density.Small}
				onSearch={this.dataTableStore.setSearchQuery}
				sortDirection={this.dataTableStore.ui.sortDirection}
				searchQueries={this.dataTableStore.searchQueries}
				filteredResults={this.dataTableStore.filteredResults}
				results={this.dataTableStore.results}
				listClassName={this.props.listClassName}
				customStyles={this.props.chart.customStyles}
				columnsWidth={this.props.columnsWidth}
				setColumnsWidth={this.props.setColumnsWidth}
			/>
		);
	}
}

export default withRouter<any, any>(observer(DiffTable));
