import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";

export default class CountryGroupFilter extends BaseFilter {

	public static type = PlatformReportingFilterType.CountryGroup;

	constructor() {
		super();

		this.items = [{value: "gdprCountries", label: "GDPR Countries"}];
		this.allowNew = false;
		this.labelKey = "label";
		this.valueKey = "value";
	}
}
