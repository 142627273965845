import * as React from "react";
import * as PropTypes from "prop-types";
import * as Highcharts from "highcharts";
import {fastClone, DayField, SpreadPageLoader} from "@vidazoo/ui-framework";
import chartConfig from "./chartConfig";
import {Chart} from "../../../../../common/components";
import {Surface} from "@vidazoo/ui";
import * as theme from "./theme.scss";
import * as randomColor from "randomcolor";
import chartConfig2 from "./chartConfig2";

export interface IPerformanceChartProps {
	series?: Highcharts.DataPoint[][];
	height?: number | string;
	labels?: string[];
	dayField?: DayField;
	isLoading?: boolean;
}

export interface IPerformanceChartState {

}

export default class PerformanceChart extends React.Component<IPerformanceChartProps, IPerformanceChartState> {

	public static defaultProps: Partial<IPerformanceChartProps> = {
		series: [],

		labels: [],

		height: 262
	};

	constructor(props, context) {
		super(props, context);
	}

	public render(): JSX.Element {
		const {series, labels, height, dayField, isLoading} = this.props;

		const config = fastClone<Highcharts.Options>(chartConfig2);

		let displayNoData = !isLoading;

		if (series.length === 0) {
			displayNoData = true;
		}

		if (!isLoading) {
			const colors = randomColor({
				count: series.length,
				luminosity: "random",
				hue: "random",
				format: "rgb"
			});
			colors && (config.colors = colors);
			config.series = fastClone(series) as any;
			if (series.length > 0 && series[0].length > 0) {
				displayNoData = false;
			}
			if (dayField) {
				if (dayField === DayField.DATE) {
					config.tooltip.xDateFormat = "%H:%M";
				} else if (dayField === DayField.DAY) {
					config.tooltip.xDateFormat = "%e %b '%y %A";
				}
			}
		}

		return (
			<div className={theme.container}>
				{
					isLoading
						? <SpreadPageLoader blue/>
						: <Chart
							config={config}
							displayNoData={displayNoData}
							style={{height}}
						/>
				}
			</div>
		);
	}
}
