import {PlatformReportingFilterType} from "../ReportingFilterType";
import BaseFilter from "../BaseFilter";
import {action} from "mobx";
import accountsAPI from "modules/accounts/api/accountsAPI";

export default class EnterpriseAccountFilter extends BaseFilter {

	public static type = PlatformReportingFilterType.EnterpriseAccount;
	private promise: Promise<any>;

	constructor() {
		super();

		this.allowNew = false;
		this.labelKey = "name";
		this.valueKey = "_id";
	}

	@action public initialize(): Promise<any> {
		if (this.isLoading) {
			return this.promise;
		}
		this.isLoading = true;

		return this.promise = accountsAPI.getEnterpriseAccountFilter()
			.then(action((res) => {
				this.items = res.data.results;
				this.isLoading = false;
			}));
	}
}
