import {action, computed, observable, transaction} from "mobx";
import {BaseListStore, IDictionary} from "@vidazoo/ui-framework";
import {cloudStore, editChartsManagerStore, navigationStore, notificationsStore} from "common/stores";
import INetwork from "common/interfaces/INetwork";
import IUser from "common/interfaces/IUser";
import IDashboard from "../interfaces/IDashboard";
import dashboardsAPI from "../api/dashboardsAPI";
import {appUrlsService, storageService} from "../../../common/services";
import IChart from "../../chart/interfaces/IChart";
import chartsAPI from "../../chart/api/chartsAPI";
import * as _ from "lodash";
import {ChartType, ChartTypesLabels} from "../../../common/enums";

const LIST_SEARCHABLE_FIELDS = ["name", "type"];
const LIST_FIELDS = {name: 1, type: 1, created: 1, isActive: 1, layouts: 1};

export default class DashboardsStore extends BaseListStore<IDashboard, IUser, INetwork> {

	@observable public selectedDashboard: IDashboard;
	@observable public isDashboardsListOpen: boolean;
	@observable public chartsById: IDictionary<IChart>;
	@observable public isLoadingCharts: boolean;
	@observable public charts: IChart[];
	@observable public dashboardsById: IDictionary<IDashboard>;

	constructor() {
		super(notificationsStore, LIST_SEARCHABLE_FIELDS, "dashboards", "name");
	}

	@action
	public reset() {
		transaction(() => {
			super.reset();
			this.dashboardsById = {};
			this.selectedDashboard = null;
			this.chartsById = {};
			this.charts = [];
			this.isLoadingCharts = false;
		});
	}

	@action public getItems = () => {
		this._getItems();
	};

	@action
	public _getItems() {
		if (this.isLoading) {
			return;
		}

		transaction(() => {
			this.isLoading = true;
			this.selectedValues = [];
			this.resetSearchQueries();
			this.setPromptDeleteItem(null);
		});

		const sort = {
			[this.sortBy]: this.sortDir
		};
		this.getAllCharts();

		dashboardsAPI.getAll({fields: LIST_FIELDS, sort: {updated: -1}})
			.then((res) => this.onLoadItemsSuccess(res))
			.then(() => this.setDashboardsById())
			.catch((res) => this.onLoadItemsError(res));
	}

	@action
	public setDashboardsById() {
		this.dashboardsById = _.keyBy(this.items, "_id");
	}

	@action
	public getAllCharts() {
		if (this.isLoadingCharts || (this.charts && this.charts.length > 0)) {
			return;
		}
		this.isLoadingCharts = true;
		chartsAPI.getAll({})
			.then((res) => this.setCharts(res))
			.catch((err) => this.onLoadItemsError(err));
	};

	@action
	private setCharts(res) {
		this.isLoadingCharts = false;
		this.chartsById = _.keyBy(res.data.results, "_id");
		this.charts = res.data.results.map((chart) => {
			chart.label = `${chart.name} (${ChartTypesLabels[chart.type]})`;
			return chart;
		});

		editChartsManagerStore.initChartStores();
	}

	@computed get defaultCharts() {
		const defaultCharts = this.charts.filter((chart) => chart.isDefault && chart.type === ChartType.DATA_TABLE);
		return defaultCharts.sort((a, b) => {
			const countA = cloudStore.topCharts[a._id] ? cloudStore.topCharts[a._id].count || 0 : 0;
			const countB = cloudStore.topCharts[b._id] ? cloudStore.topCharts[b._id].count || 0 : 0;
			return countB - countA;
		});
	}

	@action public selectDashboard = (selected) => {
		this.selectedDashboard = selected;
		navigationStore.push(appUrlsService.editDashboard(this.selectedDashboard._id));
	};

	@action public toggleActiveState = (item: IDashboard) => {
		item.isActive = !item.isActive;

		dashboardsAPI.active(item._id, item.isActive)
			.then(() => this.onUpdateStateSuccess())
			.catch(() => this.onUpdateStateError(item));
	};

	@action public deleteItem = () => {
		dashboardsAPI.delete(this.promptDeleteItem._id)
			.then(() => this.onDeleteItemSuccess(this.promptDeleteItem._id))
			.catch(() => this.onDeleteItemError());
	};

	protected deleteSelectedValues() {
		return Promise.all(this.selectedValues.map((x) => dashboardsAPI.delete(x)));
	}

	protected setActiveSelectedValues(state: boolean) {
		return Promise.all(this.selectedValues.map((x) => dashboardsAPI.active(x, state)));
	}

	@action public toggleDashboardsListOpen = () => {
		this.isDashboardsListOpen = !this.isDashboardsListOpen;
	};

}
