import {observer} from "mobx-react";
import {FormSelect} from "@vidazoo/ui/lib/components/select";
import * as theme from "../theme.scss";
import {AUTH_SCOPE_TYPES, CHANGE_LOG_ACTIONS, FilterOperators} from "../../../../../common/enums";
import * as React from "react";
import {IChangeLogFilter} from "../../../interfaces/IChangeLogFilter";
import {IChangeLogsContext} from "../../../containers/ChangeLogsContext";

export interface IActionFilterProps extends React.InputHTMLAttributes<HTMLInputElement> {
	filter: IChangeLogFilter;
	context: IChangeLogsContext;
}

function ActionFilter({filter, context}: IActionFilterProps): JSX.Element {

	function onChangeFilterParam(e) {
		const {setFilterParam} = context.store;
		const {name, value} = e.target;
		setFilterParam(filter, name, value);
	}

	return (
		<>
			<FormSelect
				className={theme.select}
				items={[FilterOperators.isAnyOf, FilterOperators.isNotAnyOf]}
				maxItems={5}
				itemValueKey="value"
				itemLabelKey="label"
				value={filter.operator || FilterOperators.isAnyOf}
				name="operator"
				required
				hideValidationMessage
				onChange={onChangeFilterParam}
				searchable
			/>
			<FormSelect
				className={theme.select}
				items={CHANGE_LOG_ACTIONS}
				maxItems={5}
				itemValueKey="value"
				itemLabelKey="label"
				value={filter.value}
				name="value"
				required
				hideValidationMessage
				onChange={onChangeFilterParam}
				searchable
			/>
		</>
	);
}

export default observer(ActionFilter);
