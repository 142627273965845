import {MutableRefObject, Ref, useEffect} from "react";

export const useScaleRelativeToParent = (opt: { element: MutableRefObject<HTMLDivElement>, buffer: number }) => {
	const {element, buffer} = opt;

	useEffect(() => {
		const handleResize = () => {
			if (element.current) {
				const scrollWidth = element.current.scrollWidth;
				const parentWidth = element.current.parentElement.offsetWidth;
				let ratio = (parentWidth / scrollWidth) - buffer;
				if (ratio > 1) {
					ratio = 1;
				}
				element.current.style.transform = `scale(${ratio})`;
			}
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, [element]);

}
