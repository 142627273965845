import * as React from "react";
import * as theme from "styles/_baseForm.scss";
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {RouteComponentProps} from "react-router";
import {BaseContainer} from "@vidazoo/ui-framework";
import {Surface, Form, Button, PageContainer, SliderPanel, CircleLoader} from "@vidazoo/ui";
import {FormMode} from "@vidazoo/ui-framework";
import Breadcrumbs, {IBreadcrumb} from "@vidazoo/ui/lib/components/breadcrumbs";
import {appUrlsService} from "common/services";
import CreateChartStore from "../../stores/CreateChartStore";
import duplicateEntityManager from "common/managers/duplicateEntityManager";
import {Entity} from "common/enums";
import ChartContext from "./ChartContext";
import DetailsForm from "./DetailsForm";
import {metaDataStore} from "../../../../common/stores";
import ReportForm from "./ReportForm";
import ChartDisplay from "./ChartDisplay";

export interface ICreateChartProps extends RouteComponentProps<any> {

}

export interface ICreateChartState {
}

class CreateChart extends BaseContainer<ICreateChartProps, ICreateChartState> {

	public static defaultProps: Partial<ICreateChartProps> = {};

	private store: CreateChartStore;

	constructor(props, context) {
		super(props, context);

		this.store = new CreateChartStore(
			duplicateEntityManager.getEntityDuplication(Entity.Chart)
		);

	}

	private getBreadcrumbs(): IBreadcrumb[] {
		return [{
			label: "Charts",
			iconName: "icon-scenarios",
			link: {to: appUrlsService.charts()}
		}, {
			label: "Create Chart",
			primary: true
		}];
	}

	private onSubmit = () => {
		this.store.submit();
	};

	private onCancelClick = () => {
		const {history} = this.props;

		history.push(appUrlsService.charts());
	};

	public render(): JSX.Element {

		return (
			<PageContainer pageTitle="Create Chart" inner top={1.2}>
				<Breadcrumbs className={theme.breadcrumbs} breadcrumbs={this.getBreadcrumbs()}/>
				<Form className={theme.form} onSubmit={this.onSubmit}>
					<ChartContext.Provider value={{store: this.store, metaDataStore}}>
						<DetailsForm mode={FormMode.Create}/>
					<SliderPanel>
						<div className={theme.actions}>
							<Button type="button" medium gray uppercase onClick={this.onCancelClick}>Cancel</Button>
							<Button type="button" medium green uppercase
									onClick={this.store.getReportForPreview}>Preview</Button>
							<Button type="submit" medium blue uppercase>Create</Button>
						</div>
					</SliderPanel>
						{this.store.chartReportByTimePreset[metaDataStore.timePreset] ? (
							<ChartDisplay
								columnsWidth={this.store.columnsWidth}
								setColumnsWidth={this.store.setColumnsWidth}
								isLoadingReport={this.store.chartReportByTimePreset[metaDataStore.timePreset].isLoading}
								chart={this.store.item}
								chartReport={this.store.chartReportByTimePreset[metaDataStore.timePreset].results}/>
						) : null}
					</ChartContext.Provider>
				</Form>
			</PageContainer>
		);
	}
}

export default withRouter<any, any>(observer(CreateChart));
