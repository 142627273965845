import * as React from "react";
import {
	DataGrid,
	DataGridHeaderColumn,
	DataGridRow,
	SortDirection,
	Density, Label,
} from "@vidazoo/ui";
import {IDictionary} from "@vidazoo/ui-framework/lib/interfaces";
import * as theme from "./theme.scss";
import ReportingRow from "./ReportingRow";
import {MemoryClipboard} from "@vidazoo/ui-framework";
import ICustomStyle from "../../../../../chart/interfaces/ICustomStyle";

export interface IReportingDataGridProps {
	onSort: (sortBy: string, preserveDirection?: boolean) => void;
	sortBy: string;
	density: Density;
	onSearch: (searchKey: string, value: string) => void;
	sortDirection: SortDirection;
	searchQueries: IDictionary<string>;
	results: any[];
	filteredResults: any[];
	listClassName: any;
	customStyles?: ICustomStyle[];
	columnsWidth?: {
		[index: string]: number;
	};
	setColumnsWidth?: (columnsWidth: { [index: string]: number }) => void;
}

export interface IReportingDataGridState {
}

export default class ReportingDataGrid extends React.Component<IReportingDataGridProps, IReportingDataGridState> {

	constructor(props, context) {
		super(props, context);
	}

	private renderHeaderAndFooter: any = (scrollTop, scrollLeft, columnsWidth, setFreezeColumn, freezeColumnIndex) => {
		const {
			sortDirection,
			onSearch,
			sortBy,
			onSort,
			searchQueries,
			results
		} = this.props;
		if (freezeColumnIndex === -1) {
			setFreezeColumn({target: {checked: true, value: 0}});
		}

		return (
			<DataGridRow columnsWidth={columnsWidth}>
				{results[0].map((header, index) => {
					const props = {
						blue: true,
						sortable: true,
						sortBy: header.name,
						sortActive: sortBy === header.name,
						searchKey: header.name,
						searchable: header.rowType === "group",
						searchQuery: searchQueries[header.name || ""],
						index,
						freezeColumnIndex,
						columnsWidth,
						sortDirection,
						onSort,
						onSearch,
						contextMenuItems: [
							{
								label: freezeColumnIndex === index ? "Unfreeze Column" : "Freeze Column",
								onClick: () => setFreezeColumn({
									target: {
										checked: freezeColumnIndex !== index,
										value: index
									}
								})
							},
							{label: `Order ${sortDirection}`, onClick: () => onSort(header.name)},
							{label: "Copy", onClick: () => MemoryClipboard.copy(header.name)},
						],
					};
					return (
						<DataGridHeaderColumn {...props}>
							<Label spacing={1.25} size={14} monserrat weight={700}>{header.name}</Label>
						</DataGridHeaderColumn>
					);
				})}
			</DataGridRow>
		);
	};

	private renderRow: any = (index, viewportIndex, columnsWidth, freezeColumnIndex) => {
		const {filteredResults, customStyles} = this.props;

		return (
			<ReportingRow
				item={filteredResults[index]}
				columnsWidth={columnsWidth}
				freezeColumnIndex={freezeColumnIndex}
				index={index}
				customStyles={customStyles}
			/>
		);
	};

	private onColumnResize = (columnsWidth) => {
		this.props.setColumnsWidth && this.props.setColumnsWidth(columnsWidth);
	};

	public render() {
		const {filteredResults, listClassName, columnsWidth} = this.props;

		return (
			<DataGrid
				onColumnResize={this.onColumnResize}
				className={listClassName || theme.list}
				extraData={filteredResults}
				headersCount={(filteredResults && filteredResults[0]) ? filteredResults[0].length : 0}
				itemsCount={(filteredResults || []).length}
				renderRow={this.renderRow}
				rowHeight={40}
				columnsWidth={columnsWidth}
				renderHeader={this.renderHeaderAndFooter}/>
		);
	}
}
