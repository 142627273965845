import * as React from "react";
import * as PropTypes from "prop-types";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { ButtonsList, PageTitle, Modal, ConfirmPopMessage, Button, Loader, Label, PageMessage, PageContainer } from "@vidazoo/ui";
import { RouteComponentProps } from "react-router";
import * as theme from "styles/_baseList.scss";
import { appUrlsService } from "common/services";
import { AddItemButton } from "common/components";
import IChart from "../../interfaces/IChart";
import ChartsList from "./ChartsList";
import ChartsStore from "../../stores/ChartsStore";

export interface IChartsProps extends RouteComponentProps<any> {

}

export interface IChartsState {
	activeBulkAction: string;
}

class Charts extends React.Component<IChartsProps, IChartsState> {

	public static defaultProps: Partial<IChartsProps> = {

	};

	private store: ChartsStore;

	constructor(props, context) {
		super(props, context);

		this.state = { activeBulkAction: null };

		this.store = new ChartsStore();
	}

	public componentDidMount() {
		this.store.getItems();
	}

	private cancelDeleteItem = () => {
		this.store.setPromptDeleteItem(null);
	}

	private onAddItem = () => {
		const { history } = this.props;

		history.push(appUrlsService.createChart());
	}

	private onEditItem = (item: IChart) => {
		const { history } = this.props;

		history.push(appUrlsService.editChart(item._id));
	}

	private onBulkActionClick = (e) => {
		const action = e.currentTarget.name;

		this.setState({ activeBulkAction: action });
	}

	private dismissBulkAction = () => {
		this.setState({ activeBulkAction: null });
	}

	private confirmBulkAction = () => {
		const { activeBulkAction } = this.state;

		this.dismissBulkAction();

		this.store.applyBulkAction(activeBulkAction);
	}

	private toggleAllItems = () => {
		this.store.toggleAllItems(false);
	}

	private renderLoader(): JSX.Element {
		return (
			<div className={theme.message}>
				<Loader size={1} gray className={theme.loader} />
				<PageMessage
					small
					title="Loading Charts"
					description="Please wait..."
				/>
			</div>
		);
	}

	public render(): JSX.Element {

		const { activeBulkAction } = this.state;

		return (
			<PageContainer pageTitle="Charts" className={theme.list} top={1.2}>
				<div className={theme.actions}>
					<PageTitle iconName="icon-scenarios" label="Charts" />
					<div className={theme.buttons}>
						<AddItemButton label="Create Chart" iconName="icon-plus-small" medium blue onClick={this.onAddItem} />
						<ButtonsList label="Bulk Action" disabled={!this.store.selectedValues.length}>
							<Button onClick={this.onBulkActionClick} name="delete">Delete</Button>
							<Button onClick={this.onBulkActionClick} name="activate">Activate</Button>
							<Button onClick={this.onBulkActionClick} name="deactivate">Deactivate</Button>
						</ButtonsList>
					</div>
				</div>

				{!this.store.isLoading ? (
					<ChartsList
						items={this.store.filteredItems}
						sortBy={this.store.sortBy}
						onSort={this.store.sort}
						onEdit={this.onEditItem}
						density={this.store.density}
						onSearch={this.store.setSearchQuery}
						onDelete={this.store.setPromptDeleteItem}
						toggleItem={this.store.toggleItem}
						sortDirection={this.store.sortDirection}
						searchQueries={this.store.searchQueries}
						onActiveChange={this.store.toggleActiveState}
						selectedValues={this.store.selectedValues}
						toggleAllItems={this.toggleAllItems}
						allItemsChecked={this.store.allItemsChecked}
					 />
				) : null}

				{this.store.isLoading ? this.renderLoader() : null}

				<Modal external exitOnClickOutside onClose={this.cancelDeleteItem} isOpen={!!this.store.promptDeleteItem}>
					<ConfirmPopMessage
						red
						title="Delete Chart?"
						text={<span>Are you sure you want to delete {this.store.promptDeleteItem ? <Label weight={600} block={false}>{`${this.store.promptDeleteItem.name}`}</Label> : ""}?</span>}
						cancelText="No, Cancel"
						confirmText="Yes, Delete"
						onCancelClick={this.cancelDeleteItem}
						onConfirmClick={this.store.deleteItem}
					/>
				</Modal>
				<Modal external exitOnClickOutside onClose={this.dismissBulkAction} isOpen={!!activeBulkAction}>
					<ConfirmPopMessage
						yellow
						title="Confirm action"
						text={`Are you sure you want to ${activeBulkAction} ${this.store.selectedValues.length} Charts?`}
						cancelText="Cancel"
						confirmText="Confirm"
						onCancelClick={this.dismissBulkAction}
						onConfirmClick={this.confirmBulkAction}
					/>
				</Modal>
			</PageContainer>
		);
	}
}

export default withRouter<any, any>(observer(Charts));
