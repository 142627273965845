import * as React from "react";
import * as PropTypes from "prop-types";
import * as theme from "styles/_baseForm.scss";
import {RouteComponentProps} from "react-router";
import {BaseContainer} from "@vidazoo/ui-framework";
import {
	Surface,
	Label,
	Form,
	Button,
	PageContainer,
	Tab,
	TabsPanel,
	Loader,
	PageMessage,
	Modal,
	ConfirmPopMessage,
	SliderPanel,
	Chips, Spacer
} from "@vidazoo/ui";
import {FormMode} from "@vidazoo/ui-framework";
import Breadcrumbs, {IBreadcrumb} from "@vidazoo/ui/lib/components/breadcrumbs";
import EditScheduleReportStore from "../../../stores/EditScheduleReportStore";
import {appUrlsService} from "../../../../../common/services";
import {DetailsForm, ReportForm} from "../../components";
import {AddItemButton} from "../../../../../common/components";
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";

export interface IEditScheduleReportProps extends RouteComponentProps<{ id: string }> {
}

export interface IEditScheduleReportState {
	showDeleteMessage: boolean;
	showRunNowMessage: boolean;
}

class EditScheduleReport extends BaseContainer<IEditScheduleReportProps, IEditScheduleReportState> {

	public static defaultProps: Partial<IEditScheduleReportProps> = {};
	private store: EditScheduleReportStore;

	constructor(props, context) {
		super(props, context);
		this.store = new EditScheduleReportStore();

		this.setInitialState({showDeleteMessage: false, showRunNowMessage: false});
	}

	public componentWillMount() {
		const {match} = this.props;
		const {resetAgain, getItem} = this.store;
		resetAgain();
		getItem(match.params.id);
	}

	private getTitle(): string {
		const {isLoading, loadError, item} = this.store;
		return isLoading ? "Loading..." : loadError ? "Error" : item.name;
	}

	private getBreadcrumbs(): IBreadcrumb[] {
		return [{
			label: "Schedule Reports",
			iconName: "icon-calendar",
			link: {to: appUrlsService.scheduleReports()}
		}, {
			label: "update"
		}, {
			label: this.getTitle(),
			primary: true
		}];
	}

	private getPageTitle() {
		const title = ["Edit Schedule Report", this.getTitle()];
		return title;
	}

	private renderLoader() {
		return (
			<div className={theme.message}>
				<Loader size={1} gray className={theme.loader}/>
				<PageMessage
					small
					title="Loading Schedule Report"
					description="Please wait..."
				/>
			</div>
		);
	}

	private onCancelClick = () => {
		const {history} = this.props;
		history.push(appUrlsService.scheduleReports());
	};

	private hideDeleteMessage = () => {
		this.setState({
			showDeleteMessage: false
		});
	};

	private showDeleteMessage = () => {
		this.setState({
			showDeleteMessage: true
		});
	};

	private hideRunNowMessage = () => {
		this.setState({
			showRunNowMessage: false
		});
	};

	private showRunNowMessage = () => {
		this.setState({
			showRunNowMessage: true
		});
	};

	private deleteScheduleReport = () => {
		this.hideDeleteMessage();
		this.store.delete();
	};

	private renderDeleteMessage(): JSX.Element {
		const {item} = this.store;
		const {showDeleteMessage} = this.state;

		return (
			<Modal external exitOnClickOutside onClose={this.hideDeleteMessage} isOpen={showDeleteMessage}>
				<ConfirmPopMessage
					red
					title="Delete Schedule Report?"
					text={<span>Are you sure you want to delete <Label weight={600}
																	   block={false}>{item.name}</Label>?</span>}
					cancelText="No, Cancel"
					confirmText="Yes, Delete"
					onCancelClick={this.hideDeleteMessage}
					onConfirmClick={this.deleteScheduleReport}
				/>
			</Modal>
		);
	}

	private renderRunNowMessage(): JSX.Element {
		const {showRunNowMessage} = this.state;
		const {item, runNowRecipients} = this.store;

		return (
			<Modal external exitOnClickOutside onClose={this.hideRunNowMessage} isOpen={showRunNowMessage}>
				<ConfirmPopMessage
					green
					title="Run Schedule Report?"
					text={<React.Fragment>
						<div>Are you sure you want to run <Label weight={600}
																 block={false}>{item.name}</Label>?
						</div>
						<div>The report will be sent in the next few minutes to your selected recipients below:</div>
						<Spacer/>
						<div className={theme.row}>
							<Chips name="runNowRecipients" searchPlaceholder="Add Recipient Email" allowCreate={true}
								   allowOrder={false} onSelect={this.onAddRecipient} onDelete={this.onRemoveRecipient}
								   chips={runNowRecipients} fill/>
						</div>
					</React.Fragment>}
					cancelText="Cancel"
					confirmText="OK"
					onCancelClick={this.hideRunNowMessage}
					onConfirmClick={this.onRunNowClick}
				/>
			</Modal>
		);
	}

	private onAddRecipient = (value: string, label: string, isNew: boolean, name: string) => {
		this.store.addToRunNowRecipients(value);
	};

	private onRemoveRecipient = (value: string, label: string, name: string) => {
		this.store.removeFromRunNowRecipients(value, null);
	};

	private renderError() {
		return (
			<div className={theme.message}>
				<PageMessage
					small
					title="Failed to load schedule report"
					description="We had an error loading the requested schedule report."
					iconName="icon-rejected-status"
				/>
			</div>
		);
	}

	private onRunNowClick = () => {
		this.store.runNow();
		this.hideRunNowMessage();
	};

	private renderForm() {
		const {submit, duplicate} = this.store;

		return (
			<Form className={theme.form} onSubmit={submit}>
				<TabsPanel animate selectedIndex={this.state.selectedIndex} onChange={this.onTabChange}
						   className={theme.midContent}>
					<Tab hash="details" label="Details">
						<div className={theme.midContent}>
							<Surface className={theme.surface} level={1} style={{marginBottom: 68}}>
								<DetailsForm mode={FormMode.Edit} store={this.store}/>
							</Surface>
							<Surface className={theme.surface} level={1}>
								<ReportForm mode={FormMode.Edit} store={this.store}/>
							</Surface>
						</div>
					</Tab>
				</TabsPanel>
				<SliderPanel>
					<div className={theme.actions}>
						<Button type="button" medium gray uppercase onClick={this.onCancelClick}>Cancel</Button>
						<Button type="button" medium green uppercase onClick={this.showRunNowMessage}>Run Now</Button>
						<Button type="button" medium red uppercase onClick={this.showDeleteMessage}>Delete</Button>
						<AddItemButton label="Duplicate" medium gray60 uppercase onClick={duplicate}/>
						<Button type="submit" medium blue uppercase>Update</Button>
					</div>
				</SliderPanel>
			</Form>
		);
	}

	public render(): JSX.Element {
		const {isLoading, loadError} = this.store;

		return (
			<PageContainer pageTitle={this.getPageTitle()} inner top={1.2}>
				<div className={[theme.breadcrumbs, "flex", "justify-space-between"].join(" ")}>
					<Breadcrumbs breadcrumbs={this.getBreadcrumbs()}/>
				</div>
				{isLoading ? this.renderLoader() : null}
				{loadError ? this.renderError() : null}
				{!isLoading && !loadError ? this.renderForm() : null}
				{this.renderDeleteMessage()}
				{this.renderRunNowMessage()}
			</PageContainer>
		);
	}
}

export default withRouter<any, any>(observer(EditScheduleReport));
