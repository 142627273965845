import * as React from "react";
import * as theme from "./theme.scss";
import {Label} from "@vidazoo/ui";
import {useEffect} from "react";
import {useScaleRelativeToParent} from "../../../../../common/hooks/useScaleRelativeToParent";

export interface ISummaryProps {
	fields: any[];
}

export interface ISummaryState {

}

const SummaryItem = ({value, label}) => (
	<div className={theme.item}>
		<div className={theme.value}>{value}</div>
		<Label className={theme.label} uppercase monserrat size={12} spacing={1.25}>{label}</Label>
	</div>
);

export default ({fields}: ISummaryProps): JSX.Element => {
	const element = React.useRef<HTMLDivElement>(null);
	useScaleRelativeToParent({element, buffer: 0.17});

	return (
		<div className={theme.summary} ref={element}>
			{fields && fields.map((field) => <SummaryItem key={field.name} value={field.displayValue}
														  label={field.name}/>)}
		</div>);
};
