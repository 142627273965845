import { BaseAPI } from "@vidazoo/ui-framework";
import {IGetAllPg} from "../../../common/interfaces/IGetAllPg";

class ChangeLogsAPI extends BaseAPI {
	public getAll({ page = 1, pageSize = 100, filter = [], fields = [], sort = {} }: IGetAllPg) {
		return this.request({
			method: "post",
			url: "/api/change-logs/",
			data: { page, pageSize, filter, fields, sort }
		});
	}

}

export default new ChangeLogsAPI(window.__BASE_API_URL__);
