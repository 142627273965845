import * as React from "react";
import * as theme from "styles/_baseForm.scss";
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {RouteComponentProps} from "react-router";
import {BaseContainer, FormMode} from "@vidazoo/ui-framework";
import {
	Label,
	Form,
	Button,
	PageContainer,
	Loader,
	PageMessage,
	Modal,
	ConfirmPopMessage,
	SliderPanel,
	Tooltip, Surface, CircleLoader
} from "@vidazoo/ui";
import Breadcrumbs, {IBreadcrumb} from "@vidazoo/ui/lib/components/breadcrumbs";
import {appUrlsService} from "common/services";
import {AddItemButton} from "common/components";
import EditChartStore from "../../stores/EditChartStore";
import ChartContext from "./ChartContext";
import {currentUserStore, metaDataStore} from "../../../../common/stores";
import DetailsForm from "./DetailsForm";
import ChartDisplay from "./ChartDisplay";

export interface IEditChartProps extends RouteComponentProps<{ id: string }> {

}

export interface IEditChartState {
	showDeleteMessage: boolean;
}

class EditChart extends BaseContainer<IEditChartProps, IEditChartState> {

	public static defaultProps: Partial<IEditChartProps> = {};

	private store: EditChartStore;

	constructor(props, context) {
		super(props, context);

		this.store = new EditChartStore();

		this.setInitialState({showDeleteMessage: false});
	}

	public componentWillMount() {
		const {match} = this.props;

		this.store.getItem(match.params.id);
	}

	public componentDidUpdate() {
		if (!this.store.chartReportByTimePreset[metaDataStore.timePreset] || !this.store.chartReportByTimePreset[metaDataStore.timePreset].results) {
			this.store.getReport();
		}
	}

	private getTitle(): string {
		return this.store.isLoading
			? "Loading..."
			: this.store.loadError
				? "Error"
				: this.store.item.name;
	}

	private getBreadcrumbs(): IBreadcrumb[] {
		return [{
			label: "Charts",
			iconName: "icon-reporting",
			link: {to: appUrlsService.charts()}
		}, {
			label: "update"
		}, {
			label: this.getTitle(),
			primary: true
		}];
	}

	private getPageTitle() {
		return ["Edit Chart", this.getTitle()];
	}

	private onSubmit = () => {
		this.store.submit();
	};

	private renderLoader() {
		return (
			<div className={theme.message}>
				<Loader size={1} gray className={theme.loader}/>
				<PageMessage
					small
					title="Loading Chart"
					description="Please wait..."
				/>
			</div>
		);
	}

	private onCancelClick = () => {
		const {history} = this.props;

		history.push(appUrlsService.charts());
	};

	private onDuplicateClick = () => {
		this.store.duplicate();
	};

	private hideDeleteMessage = () => {
		this.setState({
			showDeleteMessage: false
		});
	};

	private showDeleteMessage = () => {
		this.setState({
			showDeleteMessage: true
		});
	};

	private delete = () => {
		this.hideDeleteMessage();

		this.store.delete();
	};

	private renderDeleteMessage(): JSX.Element {
		const {showDeleteMessage} = this.state;

		return (
			<Modal external exitOnClickOutside onClose={this.hideDeleteMessage} isOpen={showDeleteMessage}>
				<ConfirmPopMessage
					red
					title="Delete Chart?"
					text={<span>Are you sure you want to delete <Label weight={600}
																	   block={false}>{this.store.item.name}</Label>?</span>}
					cancelText="No, Cancel"
					confirmText="Yes, Delete"
					onCancelClick={this.hideDeleteMessage}
					onConfirmClick={this.delete}
				/>
			</Modal>
		);
	}

	private renderForm() {
		const isBelongToUser = currentUserStore.isBelongToUser(this.store.item.user as any);
		return (
			<Form className={theme.form} onSubmit={this.onSubmit}>
				<ChartContext.Provider value={{store: this.store, metaDataStore}}>
					<DetailsForm mode={FormMode.Edit}/>
					{this.store.chartReportByTimePreset[metaDataStore.timePreset] ? (
						<ChartDisplay
							columnsWidth={this.store.columnsWidth}
							setColumnsWidth={this.store.setColumnsWidth}
							isLoadingReport={this.store.chartReportByTimePreset[metaDataStore.timePreset].isLoading}
							chart={this.store.item}
							chartReport={this.store.chartReportByTimePreset[metaDataStore.timePreset].results}/>
					) : (
						<Surface className={theme.center}>
							<CircleLoader size={8} gray/>
						</Surface>
					)}

				</ChartContext.Provider>
				<SliderPanel>
					<div className={theme.actions}>
						<Button type="button" medium gray uppercase onClick={this.onCancelClick}>Cancel</Button>
						<Button type="button" medium red uppercase onClick={this.showDeleteMessage}>Delete</Button>
						<AddItemButton label="Duplicate" medium gray60 uppercase onClick={this.onDuplicateClick}/>
						<Tooltip disabled={isBelongToUser} title="You can not update item that you not create">
							<div>
								<Button type="submit" disabled={!isBelongToUser} medium blue uppercase>Update</Button>
							</div>
						</Tooltip>
					</div>
				</SliderPanel>
			</Form>
		);
	}

	private renderError() {
		return (
			<div className={theme.message}>
				<PageMessage
					small
					title="Failed to load chart"
					description="We had an error loading the requested chart."
					iconName="icon-rejected-status"
				/>
			</div>
		);
	}

	public render(): JSX.Element {
		return (
			<PageContainer pageTitle={this.getPageTitle()} inner top={1.2}>
				<Breadcrumbs className={theme.breadcrumbs} breadcrumbs={this.getBreadcrumbs()}/>
				{this.store.isLoading ? this.renderLoader() : null}
				{this.store.loadError ? this.renderError() : null}
				{!this.store.isLoading && !this.store.loadError ? this.renderForm() : null}
				{this.renderDeleteMessage()}
			</PageContainer>
		);
	}
}

export default withRouter<any, any>(observer(EditChart));
