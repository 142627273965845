import * as React from "react";
import {DataGridRow, DataGridColumn} from "@vidazoo/ui";
import * as selfTheme from "./theme.scss";
import ReportGroupValueCell from "./ReportGroupValueCell";
import ReportFieldValueCell from "./ReportFieldValueCell";
import ICustomStyle from "../../../../../chart/interfaces/ICustomStyle";

export interface IReportingRowProps {
	item: any;
	sortBy: string;
	columnsWidth: any;
	freezeColumnIndex: number;
	index?: number;
	customStyles?: ICustomStyle[];
}

export interface IReportingRowState {

}

export default class ReportingRow extends React.Component<IReportingRowProps, IReportingRowState> {

	public static defaultProps: Partial<IReportingRowProps> = {};

	constructor(props, context) {
		super(props, context);
	}

	private getStyle = (row) => {
		const {customStyles} = this.props;
		let threshold = 30;
		let highColor = "#1D8000";
		let lowColor = "#FB0201";
		if (customStyles && customStyles.length > 0) {
			const customStyle = customStyles.find((style) => `${style.metric}_d` === row.type);
			if (customStyle) {
				threshold = customStyle.threshold;
				lowColor = customStyle.lowColor;
				highColor = customStyle.highColor;
			}
		}
		if (row.rowType === "field" && row.name.includes("(D)")) {
			const style: any = {color: "black", fontWeight: "900"};
			if (row.value < -threshold) {
				style.backgroundColor = lowColor;
				style.color = "white";
				return style;
			} else if (row.value > threshold) {
				style.backgroundColor = highColor;
				style.color = "white";
				return style;
			}
		}
		return {};
	};

	public render(): JSX.Element {

		const {
			index,
			columnsWidth,
			freezeColumnIndex,
			item
		} = this.props;

		return (
			<DataGridRow className={selfTheme.row} index={index} columnsWidth={columnsWidth} key={index}
						 freezeColumnIndex={freezeColumnIndex}>
				{item.map((row) => (
					<DataGridColumn key={row.value} className={selfTheme.column} tooltip={row.rowType === "group"}
									style={this.getStyle(row)}>
						{row.rowType === "group" ? (
							<ReportGroupValueCell group={row} enableQuickFilter={false}/>
						) : (
							<ReportFieldValueCell field={row} enableQuickConstraint={false}/>
						)}
					</DataGridColumn>
				))}
			</DataGridRow>
		);
	}

}
