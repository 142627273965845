import * as React from "react";
import * as PropTypes from "prop-types";
import Switch from "react-router-dom/Switch";
import Redirect from "react-router-dom/Redirect";
import {AuthRoute} from "common/components";
import {appUrlsService} from "common/services";
import CreateChart from "./CreateChart";
import EditChart from "./EditChart";
import ViewChart from "./ViewChart";

export default (): JSX.Element => (
	<Switch>
		<AuthRoute path={appUrlsService.createChart()} exact component={CreateChart}/>
		<AuthRoute path={appUrlsService.editChart()} exact component={EditChart}/>
		<AuthRoute path={appUrlsService.viewChart()} exact component={ViewChart}/>
		<Redirect to={appUrlsService.createChart()}/>
	</Switch>
);
