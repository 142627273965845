(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("_"), require("moment"), require("ReactDOM"), require("Highcharts"), require("setimmediate"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "_", "moment", "ReactDOM", "Highcharts", "setimmediate"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("React"), require("_"), require("moment"), require("ReactDOM"), require("Highcharts"), require("setimmediate")) : factory(root["React"], root["_"], root["moment"], root["ReactDOM"], root["Highcharts"], root["setimmediate"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__27__, __WEBPACK_EXTERNAL_MODULE__42__, __WEBPACK_EXTERNAL_MODULE__261__, __WEBPACK_EXTERNAL_MODULE__354__) {
return 