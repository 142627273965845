import {action, observable, transaction} from "mobx";
import * as _ from "lodash";
import {navigationStore} from "common/stores";
import {appUrlsService} from "common/services";
import {fastClone} from "@vidazoo/ui-framework";
import duplicateEntityManager from "common/managers/duplicateEntityManager";
import {Entity} from "common/enums";
import BaseDashboardStore from "./BaseDashboardStore";
import dashboardsAPI from "../api/dashboardsAPI";
import IDashboard from "../interfaces/IDashboard";

export default class EditDashboardStore extends BaseDashboardStore {

	@action
	public getItem(id: string) {
		this.isLoading = true;
		this.loadError = false;

		dashboardsAPI.getOne(id)
			.then((item) => this.setItem(item.data))
			.then(this.getAllCharts)
			.catch((err) => this.onSubmitFailed(err));
	}

	@action
	public submit() {
		dashboardsAPI.update(this.item._id, this.item)
			.then(() => this.onSubmitSuccess())
			.catch((err) => this.onSubmitFailed(err));
	}

	@action
	public delete() {
		dashboardsAPI.delete(this.item._id)
			.then(() => this.onSubmitSuccess())
			.then(() => navigationStore.push(appUrlsService.dashboards()))
			.catch((err) => this.onSubmitFailed(err));
	}

	@action
	public duplicate() {
		const item = fastClone(this.item);

		_.unset(item, "_id");

		item.name = "";

		duplicateEntityManager.setEntityDuplication(Entity.Dashboard, item);

		navigationStore.push(appUrlsService.createDashboard());
	}
}
