import BaseNeededGroup from "../BaseNeededGroup";
import {REPORT_VERTICAL_TYPE} from "../../../../../common/enums";
import {PlatformNeededGroups} from "../NeededGroups";
import {PlatformReportingFilterType} from "../ReportingFilterType";

export default class WidgetTypeFilter extends BaseNeededGroup {

	public static type = PlatformReportingFilterType.WidgetType;

	constructor() {
		super(REPORT_VERTICAL_TYPE.PLATFORM, PlatformNeededGroups.WidgetsSettings);
		this.allowNew = false;
		this.labelKey = "type";
	}
}
