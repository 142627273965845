import * as React from "react";
import {
	PageTitle,
	Modal,
	ConfirmPopMessage,
	Button,
	Loader,
	Label,
	PageMessage,
	PageContainer,
	ButtonsList
} from "@vidazoo/ui";
import * as theme from "styles/_baseList.scss";
import {RouteComponentProps} from "react-router";
import ScheduleReportsStore from "../../stores/ScheduleReportsStore";
import {IScheduleReport} from "../../interfaces/IScheduleReport";
import {appUrlsService} from "../../../../common/services";
import {AddItemButton} from "../../../../common/components";
import {List} from "../components";
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";

export interface IScheduleReportsProps extends RouteComponentProps<any> {
}

export interface IScheduleReportsState {
}

class ScheduleReports extends React.Component<IScheduleReportsProps, IScheduleReportsState> {

	private store: ScheduleReportsStore;

	constructor(props, context) {
		super(props, context);
		this.store = new ScheduleReportsStore();
	}
	public componentDidMount() {
		const {getItems} = this.store;
		getItems();
	}

	private cancelDeleteItem = () => {
		const {setPromptDeleteItem} = this.store;
		setPromptDeleteItem(null);
	};

	private onAddItem = () => {
		const {history} = this.props;
		history.push(appUrlsService.createScheduleReport());
	};

	private onEditItem = (item: IScheduleReport) => {
		const {history} = this.props;
		history.push(appUrlsService.editScheduleReport(item._id));
	};

	private onAdminLogs = () => {
		const {history} = this.props;
		// history.push(appUrlsService.adminLogsScheduleReports());
	};

	private onAdminAllReports = () => {
		const {history} = this.props;
		// history.push(appUrlsService.adminScheduleReportsAll());
	};

	private renderLoader(): JSX.Element {
		return (
			<div className={theme.message}>
				<Loader size={1} gray className={theme.loader}/>
				<PageMessage
					small
					title="Loading Schedule Reports"
					description="Please wait..."
				/>
			</div>
		);
	}

	public render(): JSX.Element {

		const {
			isLoading,
			items,
			sortBy,
			sortDirection,
			sort,
			searchQueries,
			promptDeleteItem,
			deleteItem,
			setPromptDeleteItem,
			toggleActiveState,
			setSearchQuery,
			density,
			getColumnsWidth,
			setColumnsWidth
		} = this.store;

		return (
			<PageContainer pageTitle="Schedule Reports" className={theme.list} top={1.2}>
				<div className={theme.actions}>
					<PageTitle iconName="icon-calendar" label="Schedule Reports"/>
					<div className={theme.buttons}>
						<AddItemButton label="Create Schedule Report" iconName="icon-plus-small" medium blue
									   onClick={this.onAddItem}/>
					</div>
				</div>

				{!isLoading ? (
					<List
						items={items}
						sortBy={sortBy}
						onSort={sort}
						sortDirection={sortDirection}
						searchQueries={searchQueries}
						onSearch={setSearchQuery}
						onDelete={setPromptDeleteItem}
						onActiveChange={toggleActiveState}
						onEdit={this.onEditItem}
						density={density}
						setColumnsWidth={setColumnsWidth}
						getColumnsWidth={getColumnsWidth}
					/>
				) : null}

				{isLoading ? this.renderLoader() : null}

				<Modal external exitOnClickOutside onClose={this.cancelDeleteItem} isOpen={!!promptDeleteItem}>
					<ConfirmPopMessage
						red
						title="Delete Schedule Report?"
						text={<span>Are you sure you want to delete {promptDeleteItem ?
							<Label weight={600} block={false}>{`${promptDeleteItem.name}`}</Label> : ""}?</span>}
						cancelText="No, Cancel"
						confirmText="Yes, Delete"
						onCancelClick={this.cancelDeleteItem}
						onConfirmClick={deleteItem}
					/>
				</Modal>
			</PageContainer>
		);
	}
}

export default withRouter<any, any>(observer(ScheduleReports));
