import {action, observable} from "mobx";
import {BaseListStore} from "@vidazoo/ui-framework";
import {navigationStore, notificationsStore, reportingStore} from "../../../common/stores";
import {IReportHistory} from "../interfaces/IReportHistory";
import reportingHistoryAPI from "../api/reportingHistoryAPI";
import {appUrlsService, storageService} from "../../../common/services";
import reportingService from "../services/reportingService";
import * as moment from "moment";
import {GetUrlAction, ReportStatus} from "../../../common/enums";

export default class HistoryReportsStore extends BaseListStore<IReportHistory, string, any> {

	@observable public selectedReport: IReportHistory;

	constructor() {
		super(notificationsStore, ["description", "dimensions", "metrics", "filtersStr", "constraintsStr", "subGroupsStr"], "History Reports", "name");
	}

	public init() {
		const splitPathname = location.pathname.split("/");
		const id = splitPathname[splitPathname.length - 1];
		if (id && id !== "reporting") {
			this.findItemAndSetSelectedReport(id);
		}
	}

	@action
	public removeSelectedReport = () => {
		this.selectedReport = null;
	};

	@action
	public onCreateReportSuccess = (item: IReportHistory) => {
		const prepared = this._prepareItem(item);
		this.setSelectedReport(prepared);
		this.items.unshift(prepared);
	};

	@action
	public findItemAndSetSelectedReport = (id: string) => {
		const selectedItem = this.items.find((item) => item._id === id);

		if (selectedItem) {
			this.selectedReport = selectedItem;
			if (selectedItem.status === ReportStatus.Finished) {
				reportingStore.getReportFromReportingHistoryUrls(selectedItem, GetUrlAction.Reuse);
			} else {
				reportingStore.setLoading();
			}
		}
	};

	@action
	public setSelectedReport = (item: IReportHistory) => {
		navigationStore.push({pathname: appUrlsService.historyReport(item._id)});
		this.selectedReport = item;
	};

	@action public getItems = () => {
		this.isLoading = true;

		reportingHistoryAPI.getAll()
			.then((res) => this.onLoadSuccess(res.data))
			.catch((res) => this.onLoadItemsError(res));
	};

	@action
	public onLoadSuccess = (res: { count: number, results: IReportHistory[] }) => {
		try {
			this.isLoading = false;

			const prepared = res.results.map((row) => this._prepareItem(row));
			this.items = observable.array(prepared, {deep: false});
			this.count = res.count;
			this.init();
		} catch (e) {
			console.log(e);
		}
	};

	private _prepareItem(item: IReportHistory) {
		item.duration = `${moment(item.params.from * 1000).format("DD/MM/YYYY, hh:mm")} - ${moment(item.params.to * 1000).format("DD/MM/YYYY, hh:mm")}`;
		item.dimensions = item.params.groups.map((group) => {
			const reportingEntry = reportingService.getGroupByValue(group, item.params.verticalType);
			return reportingEntry ? reportingEntry.label : "";
		}).join(", ");
		item.metrics = item.params.fields.map((field) => {
			const reportingEntry = reportingService.getFieldByValue(field, item.params.verticalType);
			return reportingEntry ? reportingEntry.label : "";
		}).join(", ");

		if (item.params.filters && item.params.filters.length > 0) {
			item.filtersStr = item.params.filters.map((filter) => {
				return filter.key;
			}).join(", ");
		}

		if (item.params.constraints && item.params.constraints.length > 0) {
			item.constraintsStr = item.params.constraints.map((constraint) => {
				return constraint.name;
			}).join(", ");
		}

		if (item.params.subGroups && item.params.subGroups.length > 0) {
			item.subGroupsStr = item.params.subGroups.map((subGroup) => {
				return `${subGroup.group} - ${subGroup.entities.join(" -")}`;
			}).join(", ");
		}

		return item;
	}

	public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
		storageService.setColumnsWidth(`HistoryReports`, columnsWidth);
	};

	@action
	public setReportStatus = (reportHistory: IReportHistory, status: ReportStatus) => {
		const prepared = this._prepareItem(reportHistory);
		prepared.status = status;

		const readyItemIdx = this.items.findIndex((item) => item._id === reportHistory._id);
		if (readyItemIdx > -1) {
			this.items[readyItemIdx] = reportHistory;

		} else {
			this.items.unshift(prepared);
		}
	};

	public getColumnsWidth = (): { [index: string]: number } => {
		const columnsWidth = storageService.getColumnsWidth(`HistoryReports`);
		return columnsWidth || {0: 130, 1: 315, 2: 184, 3: 429, 4: 234, 5: 267, 6: 199};
	};

	public update = (id: string, data: Partial<IReportHistory>) => {
		reportingHistoryAPI.update(id, data)
			.then(() => this.onUpdateReportingHistorySuccess(id, data))
			.catch(() => this.onUpdateStateError({} as any));
	};

	@action
	private onUpdateReportingHistorySuccess = (id: string, data: Partial<IReportHistory>) => {
		this.onUpdateStateSuccess();

		const readyItemIdx = this.items.findIndex((item) => item._id === id);
		if (readyItemIdx > -1) {
			this.items[readyItemIdx] = {...this.items[readyItemIdx], ...data};
		}
	};
}
