import {ILabelValuePair, UserRole} from "@vidazoo/ui-framework";
import CreateScheduleReportStore from "../modules/scheduleReports/stores/CreateScheduleReportStore";

export enum Entity {
	Dashboard = "dashboard",
	Chart = "chart",
	ScheduleReport = "scheduleReport"
}

export enum NetworkFeatures {
	VIDAZOO = "vidazoo",
}

export const DEFAULT_ACCESS = [UserRole.USER, UserRole.ADMIN, UserRole.SUPER_ADMIN];

export const dateFields = ["created", "updated", "date", "from", "to"];

export enum ChartType {
	DATA_TABLE = "data_table",
	SUMMARY = "summary",
	PERFORMANCE = "performance",
}

export const ChartTypes: ILabelValuePair[] = [
	{value: ChartType.DATA_TABLE, label: "Data Table"},
	{value: ChartType.SUMMARY, label: "Summary"},
	{value: ChartType.PERFORMANCE, label: "Performance"},
];

export const ChartTypesWithImg = [
	{
		value: ChartType.DATA_TABLE,
		label: "Data Table",
		image: "https://s3.amazonaws.com/vidazoo-cms/videos/733765ed-342a-b1b7-0b7a-80e5bdf9a667.png"
	},
	{
		value: ChartType.SUMMARY,
		label: "Summary",
		image: "https://s3.amazonaws.com/vidazoo-cms/videos/7109e1c3-f0b5-5bc2-4d33-7a03ea5ccb12.png"
	},
	{
		value: ChartType.PERFORMANCE,
		label: "Performance",
		image: "https://s3.amazonaws.com/vidazoo-cms/videos/c28540db-cb43-7810-b1bc-e6f8ad80d98e.png"
	},
];

export const TimePreset: ILabelValuePair[] = [
	{value: "today", label: "Today"},
	{value: "yesterday", label: "Yesterday"},
	{value: "last7days", label: "Last 7 Days"},
	{value: "weektodate", label: "Week to Date"},
	{value: "lastmonth", label: "Last Month"},
	{value: "monthtodate", label: "Month to Date"},
];

export enum TimePresets {
	TwoHours = "2_hours",
	FourHours = "4_hours",
	Today = "today",
	Yesterday = "yesterday"
}

export enum ReportPresetType {
	Public = "public",
	Private = "private"
}

export const ReportingTimePreset = [{value: "now", label: "Now"}]
	.concat(TimePreset)
	.concat([{value: "custom", label: "Custom"}]);

export const ValidTimeZones: string[] = [
	"Etc/GMT+12",
	"Etc/GMT+11",
	"Etc/GMT+10",
	"Etc/GMT+9",
	"Etc/GMT+8",
	"Etc/GMT+7",
	"Etc/GMT+6",
	"Etc/GMT+5",
	"Etc/GMT+4",
	"Etc/GMT+3",
	"Etc/GMT+2",
	"Etc/GMT+1",
	"Etc/GMT+0",
	"Etc/GMT-1",
	"Etc/GMT-2",
	"Etc/GMT-3",
	"Etc/GMT-4",
	"Etc/GMT-5",
	"Etc/GMT-6",
	"Etc/GMT-7",
	"Etc/GMT-8",
	"Etc/GMT-9",
	"Etc/GMT-10",
	"Etc/GMT-11",
	"Etc/GMT-12"
];

export const Operators: string[] = [
	"=",
	"!=",
	">=",
	"<=",
	">",
	"<"
];

export enum REPORT_VERTICAL_TYPE {
	PLATFORM = "platform",
	CONNECTION = "connection",
	OPEN_RTB = "open_rtb",
}

export const ReportVerticalTypes: ILabelValuePair[] = [
	{value: REPORT_VERTICAL_TYPE.PLATFORM, label: "Platform"},
	{value: REPORT_VERTICAL_TYPE.CONNECTION, label: "Connection"},
	{value: REPORT_VERTICAL_TYPE.OPEN_RTB, label: "OpenRtb"},
];

export enum FilterTypes {
	Scope = "scope",
	Account = "account",
	User = "user",
	Action = "action",
	ModelId = "modelId",
	ModelName = "modelName",
	PlatformTags = "platformTags"
}

export const FilterOptions = [
	{value: FilterTypes.User, label: "User"},
	{value: FilterTypes.Scope, label: "Scope"},
	{value: FilterTypes.Action, label: "Action"},
	{value: FilterTypes.Account, label: "Account"},
	{value: FilterTypes.ModelId, label: "model_id"},
	{value: FilterTypes.ModelName, label: "model_name"},
	{value: FilterTypes.PlatformTags, label: "Platform Tags"},
];

export const ChangeLogModelNames = [
	{value: "tags", label: "Tags"},
	{value: "players", label: "Players"},
	{value: "widgets", label: "Widgets"},
	{value: "videos", label: "Videos"},
	{value: "crawlers", label: "Crawlers"},
	{value: "scenarios", label: "Scenarios"},
	{value: "domainlists", label: "Lists"},
	{value: "widgetsettings", label: "Widget Settings"},
	{value: "demandpartners", label: "Demand Partners"},
	{value: "users", label: "Users"},
	{value: "platformsettings", label: "Platform Settings"},
	{value: "presetreports", label: "Preset Reports"},
	{value: "networks", label: "Networks"},
	{value: "dynamicmacros", label: "Dynamic Macros"},
	{value: "contextualplaylists", label: "Contextual Playlists"},
	{value: "publishers", label: "Publishers"},
	{value: "countrylists", label: "Country Lists"},
	{value: "schedulereports", label: "Schedule Reports"},
	{value: "connections", label: "Connections"},
	{value: "networksettings", label: "Network Settings"},
	{value: "abtestpolicies", label: "AB Test Policies"},
	{value: "exchanges", label: "Exchanges"},
];

export enum FilterOperators {
	isAnyOf = "Is Any Of",
	isNotAnyOf = "Is Not Any Of",
	containAnyOf = "Contain Any Of",
	notContainAnyOf = "Not Contain Any Of"
}

export enum AuthScope {
	AUTH = "auth",
	PLATFORM = "platform",
	CONNECTIONS = "connections",
	PUBLISHERS = "publishers",
	IP_BLOCKER = "ip_blocker",
	MONEY_BOOKER = "moneybooker",
	ALGOTRADER = "algotrader",
	SKYNET = "skynet",
	AUTOSCALER = "autoscaler",
	NIGHTWATCH = "nightwatch",
	DISPLAY = "display",
	OPEN_RTB = "open_rtb",
	ADVERTISERS = "advertisers",
	DSP_GATEWAY = "dsp_gateway",
	CAPTAINS_LOG = "captains_log",
	BI = "bi",
	CONTENT = "content",
	CAPCHU = "capchu",
	CAMPAIGN_DUPLICATOR = "campaign_duplicator",
	VAULT = "vault",
	NETWORK = "network",
	VROKU = "vroku",
	MESSAGES = "messages",
	REPORTING = "reporting",
	BI_DASHBOARD = "bi_dashboard",
	BI_DASHBOARDS = "bi_dashboards",
	GAMES = "games",
	WEB_DIRECTORY = "web_directory",
	ADS_TXT = "ads_txt",
	SITES = "sites",
	FEATURE_TOGGLES = "feature_toggles",
	AUTOMATIONS = "automations",
	CONSOLE_RADIO = "console_radio",
	OS = "os",
	WIDGETS = "widgets"
}

export const AUTH_SCOPE_TYPES = [
	{name: "Auth", value: AuthScope.AUTH},
	{name: "Platform", value: AuthScope.PLATFORM},
	{name: "Connections", value: AuthScope.CONNECTIONS},
	{name: "Publishers", value: AuthScope.PUBLISHERS},
	{name: "IpBlocker", value: AuthScope.IP_BLOCKER},
	{name: "MoneyBooker", value: AuthScope.MONEY_BOOKER},
	{name: "AlgoTrader", value: AuthScope.ALGOTRADER},
	{name: "Skynet", value: AuthScope.SKYNET},
	{name: "AutoScaler", value: AuthScope.AUTOSCALER},
	{name: "Nightwatch", value: AuthScope.NIGHTWATCH},
	{name: "OpenRtb", value: AuthScope.OPEN_RTB},
	{name: "Advertisers", value: AuthScope.ADVERTISERS},
	{name: "Dsp Gateway", value: AuthScope.DSP_GATEWAY},
	{name: "Captains Log", value: AuthScope.CAPTAINS_LOG},
	{name: "Bi", value: AuthScope.BI},
	{name: "Capchu", value: AuthScope.CAPCHU},
	{name: "Campaign Duplicator", value: AuthScope.CAMPAIGN_DUPLICATOR},
	{name: "Vault", value: AuthScope.VAULT},
	{name: "Capchu", value: AuthScope.NETWORK},
	{name: "Vroku", value: AuthScope.VROKU},
	{name: "Messages", value: AuthScope.MESSAGES},
	{name: "Reporting", value: AuthScope.REPORTING},
	{name: "Content", value: AuthScope.CONTENT},
	{name: "Display", value: AuthScope.DISPLAY},
	{name: "BI Dashboard", value: AuthScope.BI_DASHBOARD},
	{name: "BI Dashboards", value: AuthScope.BI_DASHBOARDS},
	{name: "Games", value: AuthScope.GAMES},
	{name: "Web Directory", value: AuthScope.WEB_DIRECTORY},
	{name: "Ads.txt", value: AuthScope.ADS_TXT},
	{name: "Sites", value: AuthScope.SITES},
	{name: "Feature Toggles", value: AuthScope.FEATURE_TOGGLES},
	{name: "Automations", value: AuthScope.AUTOMATIONS},
	{name: "Console Radio", value: AuthScope.CONSOLE_RADIO},
	{name: "OS", value: AuthScope.OS},
	{name: "Widgets", value: AuthScope.WIDGETS},
];

export const CHANGE_LOG_ACTIONS = [
	{label: "Update", value: "update"},
	{label: "Create", value: "create"}
];

export enum TagType {
	VAST = "vast",
	IMA = "ima",
	PREBID = "pre_bid",
	OPEN_RTB = "open_rtb",
	DFP_DISPLAY = "dfp_display",
	YANDEX = "yandex",
	AMAZON = "amazon",
	OPEN_RTB_CLIENT = "open_rtb_client",
}

export const TagTypes: ILabelValuePair[] = [
	{value: TagType.VAST, label: "Vast"},
	{value: TagType.IMA, label: "IMA"},
	{value: TagType.PREBID, label: "Header Bidding (Prebid.js)"},
	{value: TagType.DFP_DISPLAY, label: "DFP Display"},
	{value: TagType.YANDEX, label: "Yandex"},
	{value: TagType.OPEN_RTB_CLIENT, label: "Open RTB Client"},
	{value: TagType.OPEN_RTB, label: "Open RTB Server"},
];

export const TagTypeLabels: ILabelValuePair[] = [
	{value: TagType.IMA, label: "GoogleAdManager"},
	{value: TagType.DFP_DISPLAY, label: "GoogleDFP"},
	{value: TagType.VAST, label: "Other"},
	{value: TagType.YANDEX, label: "Yandex"},
	{value: TagType.AMAZON, label: "Amazon"},
	{value: TagType.PREBID, label: "Pre Bid"},
	{value: TagType.OPEN_RTB, label: "Open RTB"},
];

export enum ContextMenuItemsType {
	fields = "fields",
	groups = "groups",
	filters = "filters",
	constraints = "constraints",
	subGroups = "subGroups"
}

export const ChartTypesLabels = {
	[ChartType.DATA_TABLE]: "Data Table",
	[ChartType.SUMMARY]: "Summary",
	[ChartType.PERFORMANCE]: "Performance"
};

export const ChartHeights = {
	[ChartType.SUMMARY]: 20,
	[ChartType.DATA_TABLE]: 70,
	[ChartType.PERFORMANCE]: 50
};

export const DefaultTopCharts = [
	{id: "5fbcc9963c98420004ccf7de", count: 1},
	{id: "6329aa5e4e34610190c53a6c", count: 1},
	{id: "6329d29abf3704140feba2b6", count: 1},
	{id: "60040ed09a59df627ea3d7d8", count: 1},
];

export enum ChipsColors {
	blue = "blue",
	orange = "orange",
	purple = "purple",
	deepPurple = "deepPurple"
}

export enum ReportingDbType {
	BigQuery = "BigQuery",
	SnowFlake = "SnowFlake",
	MemSqlTemp = "MemSqlTemp",
	ClickHouse = "ClickHouse",
	ClickHouseDr = "ClickHouseDr",
}

export const AdminReportingDbTypes = [
	{value: null, label: "No Preference"},
	{value: ReportingDbType.ClickHouse, label: ReportingDbType.ClickHouse},
	{value: ReportingDbType.ClickHouseDr, label: ReportingDbType.ClickHouseDr},
	{value: ReportingDbType.SnowFlake, label: ReportingDbType.SnowFlake},
	{value: ReportingDbType.BigQuery, label: ReportingDbType.BigQuery},
];

export const UsersReportingDbTypes = [
	{value: null, label: "No Preference"},
	{value: ReportingDbType.ClickHouse, label: ReportingDbType.ClickHouse},
	{value: ReportingDbType.SnowFlake, label: ReportingDbType.SnowFlake},
	{value: ReportingDbType.ClickHouseDr, label: ReportingDbType.ClickHouseDr},
];

export enum ReportStatus {
	Finished = "Finished",
	Error = "Error",
	Processing = "Processing"
}

export enum GetUrlAction {
	Download = "download",
	Reuse = "reuse",
	Generate = "generate"
}

export const AccountManagers = [
	{value: "6155967489c1160004d1d41d", label: "Inbar Elkouby"},
	{value: "6155965c74fe8c00046733ce", label: "Daniel Goldman"},
	{value: "61d16805802f09000441893b", label: "Shahaf Saar Sayah"},
	{value: "633582c27a7210909494a92d", label: "Matan Raz"},
	{value: "6196610d11fcdc000444ffef", label: "Tai Moscovich"},
	{value: "644f6a4d6bbff3272434163e", label: "Ronit Milikovski"},
	{value: "5a8eddff12eda300040fc082", label: "Yoav Peled"},
];
