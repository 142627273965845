import * as _ from "lodash";
import * as moment from "moment";
import * as papaito from "papaparse";
import {IDictionary, IReportingEntry} from "@vidazoo/ui-framework";

class ExportToCsvService {

	private getFileName(type: string): string {
		const date = moment().format("DD.MM.YY_HHmm");

		return `${type}_${date}.csv`;
	}

	private download(content: any, fileName: string) {
		const blob = new Blob([content]);
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.setAttribute("href", url);
		link.setAttribute("download", fileName);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	}

	public exportHistoryReport(report: any[], params) {

		const csvArray = [[...params.groups, ...params.fields]];

		report.forEach((r) => {
			const rowValues = [];
			params.groups.forEach((groupLabel) => {
				rowValues.push(`"${r.groups[groupLabel]}"`);
			});
			params.fields.forEach((fieldLabel) => {
				rowValues.push(`"${r.fields[fieldLabel]}"`);
			});
			csvArray.push(rowValues);
		});

		const csv = csvArray.map((r) => (r.join())).join("\n");
		this.download(csv, this.getFileName("vidazoo_report_history"));
	}

	public exportReport(results: Array<{ fields: Array<{ name: string, value: any }>, groups: Array<{ name: string, value: number }> }>, fieldsParams: IReportingEntry[]) {

		// filter needed fields
		results.forEach((row) => row.fields = row.fields.filter((field) => !!fieldsParams.find((curr) => curr.label === field.name)));

		const head = results[0].groups.map((g) => g.name).concat(results[0].fields.map((f) => f.name));
		const body = results.map((row) => {
			const groups = row.groups.map((g) => g.value);
			const fields = row.fields.map((f) => {
				if (_.isArray(f.value)) {
					const val = f.value;
					return String([val[0], val[3].toFixed(2)]);
				}
				return String(f.value);
			});
			return [].concat(groups, fields);
		});

		const csv = papaito.unparse({
			fields: head,
			data: body,
		});

		this.download(csv, this.getFileName("vidazoo_report"));
	}

	public exportDashboardReport(chartData: IDictionary<any>) {
		const head = chartData[0].map((data) => data.name);
		const body = chartData.map((row) => row.map((data) => data.value));
		const csv = papaito.unparse({
			fields: head,
			data: body
		});
		this.download(csv, this.getFileName("vidazoo_dash_report"));
	}
}

export default new ExportToCsvService();
