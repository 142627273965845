import {action} from "mobx";
import {metaDataStore, navigationStore} from "common/stores";
import {appUrlsService} from "common/services";
import chartsAPI from "../api/chartsAPI";
import BaseChartStore from "./BaseChartStore";
import {ChartType} from "../../../common/enums";
import {guid} from "@vidazoo/ui-framework";

export default class CreateChartStore extends BaseChartStore {

	@action
	public submit() {
		const item = this.setAccounts();

		item.preparedReportParams = this.reportingService.getReportParamsForChart(item.reportParams);

		chartsAPI.create(item)
			.then(() => navigationStore.push(appUrlsService.charts()))
			.then(() => this.onSubmitSuccess())
			.catch((err) => this.onSubmitFailed(err));
	}

	@action
	public getReportForPreview = async () => {
		if (this.chartReportByTimePreset[metaDataStore.timePreset] && (this.chartReportByTimePreset[metaDataStore.timePreset].isLoading)) {
			return;
		}

		if (!this.item.reportParams.fields || !this.item.reportParams.fields.length) {
			return;
		}
		if (this.item.type !== ChartType.SUMMARY && !this.item.reportParams.groups.length) {
			return;
		}
		if (this.item.type === ChartType.DATA_TABLE) {
			this.initColumnsWidth();
		}

		const chartId = guid();


		const preparedReportParams = this.reportingService.getReportParamsForChart(this.item.reportParams);

		this.chartReportByTimePreset[metaDataStore.timePreset] = {results: null, isLoading: true};

		const {
			results,
			timePreset
		} = await this.reportingService.getReportByChartCreate(chartId, this.item.type, metaDataStore.timePreset, this.timezone, this.item.reportParams, preparedReportParams);

		this.setPreviewResults(results, timePreset);

	}
	@action
	private setPreviewResults = (results: any[], timePreset: string) => {
		if (results && timePreset && this.chartReportByTimePreset[timePreset]) {

			this.chartReportByTimePreset[timePreset].results = results;
			this.chartReportByTimePreset[timePreset].isLoading = false;
		}
	}

	@action
	public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
		this.columnsWidth = columnsWidth;
	};

	@action
	private initColumnsWidth = () => {
		const columnsWidth = {};
		const length = this.item.reportParams.fields.length + (this.item.reportParams.groups.length * 3) + 1;
		for (let i = 0; i < length; i++) {
			columnsWidth[i] = 140;
		}
		this.setColumnsWidth(columnsWidth);
	}

}
