import * as React from "react";
import * as theme from "styles/_baseForm.scss";
import {RouteComponentProps} from "react-router";
import {BaseContainer} from "@vidazoo/ui-framework";
import {Surface, Form, Button, PageContainer, SliderPanel} from "@vidazoo/ui";
import Breadcrumbs, {IBreadcrumb} from "@vidazoo/ui/lib/components/breadcrumbs";
import {FormMode} from "@vidazoo/ui-framework";
import * as _ from "lodash";
import CreateScheduleReportStore from "../../../stores/CreateScheduleReportStore";
import {appUrlsService} from "../../../../../common/services";
import {DetailsForm, ReportForm} from "../../components";
import {observer} from "mobx-react";
import {withRouter} from "react-router";

export interface ICreateScheduleReportProps extends RouteComponentProps<{}> {
}

export interface ICreateScheduleReportState {

}

class CreateScheduleReport extends BaseContainer<ICreateScheduleReportProps, ICreateScheduleReportState> {

	public static defaultProps: Partial<ICreateScheduleReportProps> = {};
	private store: CreateScheduleReportStore;

	constructor(props, context) {
		super(props, context);
		this.store = new CreateScheduleReportStore();
	}

	public componentWillMount() {
		const {location} = this.props;

		if (_.get(location, "query.duplicate")) {
			return;
		}

		this.store.reset();
	}

	private getBreadcrumbs(): IBreadcrumb[] {
		return [{
			label: "Schedule Reports",
			iconName: "icon-calendar",
			link: {to: appUrlsService.scheduleReports()}
		}, {
			label: "Create Schedule Report",
			primary: true
		}];
	}

	private onCancelClick = () => {
		const {history} = this.props;

		history.push(appUrlsService.scheduleReports());
	};

	public render(): JSX.Element {
		const {submit} = this.store;

		return (
			<PageContainer pageTitle="Create Schedule Report" inner top={1.2}>
				<Breadcrumbs className={theme.breadcrumbs} breadcrumbs={this.getBreadcrumbs()}/>
				<Form className={theme.form} onSubmit={submit}>
					<div className={theme.midContent}>
						<Surface className={theme.surface} level={1} style={{marginBottom: 68}}>
							<DetailsForm mode={FormMode.Create} store={this.store}/>
						</Surface>
						<Surface className={theme.surface} level={1}>
							<ReportForm mode={FormMode.Create} store={this.store}/>
						</Surface>
					</div>
					<SliderPanel>
						<div className={theme.actions}>
							<Button type="button" medium gray uppercase onClick={this.onCancelClick}>Cancel</Button>
							<Button type="submit" medium blue uppercase>Create</Button>
						</div>
					</SliderPanel>
				</Form>
			</PageContainer>
		);
	}
}

export default withRouter<any, any>(observer(CreateScheduleReport));
