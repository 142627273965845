import {observable, action, transaction} from "mobx";
import BaseItemStore from "@vidazoo/ui-framework/lib/stores/BaseItemStore";
import {notificationsStore} from "common/stores";
import IChart from "../interfaces/IChart";
import {ChartType, REPORT_VERTICAL_TYPE} from "../../../common/enums";
import IReportParams from "../../reporting/interfaces/IReportParams";
import {guid, IReportingConstraint, IReportingFilter} from "@vidazoo/ui-framework";
import reportingFiltersManager from "../../reporting/stores/filters/reportingFiltersManager";
import * as _ from "lodash";
import reportingService, {ReportingService} from "../../reporting/services/reportingService";
import {storageService} from "../../../common/services";

export default abstract class BaseChartStore extends BaseItemStore<IChart> {

	public reportingService: ReportingService;
	@observable public chartReportByTimePreset: { [index: string]: { results: any, isLoading: boolean } };
	@observable public timezone: string;
	@observable public columnsWidth: { [index: number]: number };
	protected setColumnsWidthDebounce: () => void;

	constructor(fromDuplicate?: IChart) {
		super(notificationsStore, "chart");
		this.reportingService = reportingService;

		this.reset();

		if (fromDuplicate) {
			this.setItem(fromDuplicate);
		}
	}

	@action
	public reset() {
		transaction(() => {
			super.reset();
			this.timezone = storageService.getGlobalTimezone() || "Etc/GMT+0";
			this.chartReportByTimePreset = observable({}) as any;

			this.item = {
				...this.item,
				name: "",
				description: "",
				type: ChartType.DATA_TABLE,
				reportParams: this.initialParams,
				preparedReportParams: {},
				dashboardLink: "",
				mustFilter: false,
				isDefault: false,
				customStyles: []
			};
		});
	}

	private get initialParams(): IReportParams {
		return {
			fields: [],
			groups: [],
			filters: [],
			constraints: [],
			verticalType: REPORT_VERTICAL_TYPE.PLATFORM,
			ids: []
		};
	}

	@action public addConstraint = (values?: Partial<IReportingConstraint>) => {
		this.item.reportParams.constraints = this.item.reportParams.constraints.concat({
			id: guid(),
			name: "",
			op: "",
			value: "",
			...values
		});

		return this.item.reportParams.constraints[this.item.reportParams.constraints.length - 1];
	};

	@action public setConstraintParam = (constraint: IReportingConstraint, key: string, value: any) => {
		constraint[key] = value;
	};

	@action public addFilter = (): IReportingFilter => {
		const filter = observable<IReportingFilter>({
			id: guid(),
			key: "",
			values: [],
			filterList: [],
			isLoading: false,
			filterValueKey: "",
			filterLabelKey: "",
			allowNew: false,
			exclude: false,
			operator: "",
		});

		this.item.reportParams.filters.push(filter);

		return filter;
	};

	@action public pushFilterValue = (filter: IReportingFilter, value: string, label: string) => {
		let item: any = value;

		if (filter.filterLabelKey && filter.filterValueKey) {
			item = {
				[filter.filterLabelKey]: label,
				[filter.filterValueKey]: value
			};
		}

		filter.values = filter.values.concat(item);
	};

	@action public removeFilterValue = (filter: IReportingFilter, value: string) => {
		filter.values = filter.filterValueKey
			? _.filter(filter.values, (item) => item[filter.filterValueKey] !== value)
			: _.filter(filter.values, (item) => item !== value);
	};

	@action public removeFromArrayInObjByKey = (obj, key, value, objKey) => {
		this.item[obj][key] = this.item[obj][key].filter((item) => (item[objKey] !== value));
	};

	@action public setFilterParam = (filter: IReportingFilter, key: string, value: any) => {
		if (key === "key") {
			this.setFilterKey(filter, value);
			return;
		}

		filter[key] = value;
	};

	@action public addCustomStyle = () => {
		if (!this.item.customStyles) {
			this.item.customStyles = [];
		}

		if (this.item.customStyles.length >= this.item.reportParams.fields.length) {
			return;
		}
		this.item.customStyles.push({
			metric: "",
			threshold: 30,
			highColor: "var(--color-green)",
			lowColor: "var(--color-red)",
			id: guid()
		});
	};

	@action public removeCustomStyle = (indexToDelete: number): void => {
		this.item.customStyles = this.item.customStyles.filter((style, index) => indexToDelete !== index);
	};

	@action public onCustomStyleChange = (value, name, index) => {
		this.item.customStyles[index][name] = value;
	};

	@action public setFilterKey = (filter: IReportingFilter, key: string, values?: any): Promise<any> => {
		const filterHandler = reportingFiltersManager.getFilter(key, this.item.reportParams.verticalType);

		filter.key = key;
		filter.filterLabelKey = filterHandler.labelKey;
		filter.filterValueKey = filterHandler.valueKey;
		filter.allowNew = filterHandler.allowNew;
		filter.isLoading = filterHandler.isLoading;
		filter.values = values || [];

		return filterHandler.initialize().then(action(() => {
			filter.filterList = filterHandler.items;
			filter.isLoading = filterHandler.isLoading;
		}));
	};

	@action
	public resetChartByReport = () => {
		this.chartReportByTimePreset = observable({}) as any;
	}
}
