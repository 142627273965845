import {BaseStorageService, IReportingEntry, IReportingFilter, IReportingConstraint} from "@vidazoo/ui-framework";
import {REPORT_VERTICAL_TYPE, TimePresets} from "../enums";
import {ISubGroup} from "../../modules/reporting/interfaces/IBiReportingParams";

class StorageService extends BaseStorageService {

	public getReportingEntries = (entry: "groups" | "fields", verticalType: REPORT_VERTICAL_TYPE): IReportingEntry[] => {
		return this.get(`reporting.${verticalType}.${entry}`, null);
	};

	public setReportingEntries = (entry: "groups" | "fields", values: IReportingEntry[], verticalType: REPORT_VERTICAL_TYPE) => {
		this.set(`reporting.${verticalType}.${entry}`, values);
	};

	public getReportingFilters = (verticalType: REPORT_VERTICAL_TYPE): IReportingFilter[] => {
		return this.get(`reporting.${verticalType}.filters`, null);
	};

	public setReportingFilters = (filters: IReportingFilter[], verticalType: REPORT_VERTICAL_TYPE) => {
		this.set(`reporting.${verticalType}.filters`, filters);
	};

	public getReportingConstraints = (verticalType: REPORT_VERTICAL_TYPE): IReportingConstraint[] => {
		return this.get(`reporting.${verticalType}.constraints`, null);
	};

	public setReportingConstraints = (filters: IReportingConstraint[], verticalType: REPORT_VERTICAL_TYPE) => {
		this.set(`reporting.${verticalType}.constraints`, filters);
	};

	public getReportingSubGroups = (verticalType: REPORT_VERTICAL_TYPE): ISubGroup[] => {
		return this.get(`reporting.${verticalType}.subGroups`, null);
	};

	public setReportingSubGroups = (subGroups: ISubGroup[], verticalType: REPORT_VERTICAL_TYPE) => {
		this.set(`reporting.${verticalType}.subGroups`, subGroups);
	};

	public getGlobalTimezone = (): string => {
		return this.get(`global.timezone`, "");
	};

	public setGlobalTimezone = (timezone: string) => {
		this.set(`global.timezone`, timezone);
	};

	public setDashboardsList = (isOpen: boolean) => {
		this.set(`dashboardsList`, JSON.stringify({isOpen}));
	};

	public getDashboardsList = (): string => {
		return this.get(`dashboardsList`, "");
	};

	public setTimePreset = (timePreset) => {
		this.set(`timePreset`, timePreset);
	};

	public getTimePreset = () => {
		return this.get(`timePreset`, TimePresets.TwoHours);
	};

	public setColumnsWidth = (entity: string, columnsWidth) => {
		const allColumnsWidth = this.get("columnsWidth", {});
		allColumnsWidth[entity] = columnsWidth;
		this.set(`columnsWidth`, allColumnsWidth);
	};

	public getColumnsWidth = (entity: string) => {
		const allColumnsWidth = this.get("columnsWidth", {});
		return allColumnsWidth[entity] || {};
	};

	public getReportingSumOnTop = () => {
		return this.get(`reportingSumOnTop`, true);
	};

	public setReportingSumOnTop = (reportingSumOnTop: boolean) => {
		this.set(`reportingSumOnTop`, reportingSumOnTop);
	};
}

export default new StorageService();
