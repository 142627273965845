import { BaseSocketService } from "@vidazoo/ui-framework";

class HistoryReportsSocketService extends BaseSocketService {

	protected onSocketError(err) {
		if (err === "unauthorized") {
			window.__sdk__.auth.logout();
		}
	}

	public getReportingHistory(data) {
		return this.request("getReportingHistory", data, 10 * 60 * 1000);
	}

	public getCompareReportingHistory(data) {
		return this.request("getCompareReportingHistory", data, 10 * 60 * 1000);
	}

	public startListenToReportHistoryIsReady(authUserId: string, fn: (data: any) => void) {
		this.socket.on(`reportHistoryIsReady-bi_dashboards-${authUserId}`, fn);
	}

	public startListenToReportHistoryUploadToS3(authUserId: string, fn: (data: any) => void) {
		this.socket.on(`reportHistoryUploadToS3-bi_dashboards-${authUserId}`, fn);
	}

	public removeListenToReportHistoryIsReady(authUserId: string) {
		this.socket.off(`reportHistoryIsReady-bi_dashboards-${authUserId}`);
	}
}

export default new HistoryReportsSocketService();
