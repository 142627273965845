import {observer} from "mobx-react";
import {FormSelect} from "@vidazoo/ui/lib/components/select";
import * as theme from "../theme.scss";
import {FilterOperators, REPORT_VERTICAL_TYPE} from "../../../../../common/enums";
import * as React from "react";
import {IChangeLogFilter} from "../../../interfaces/IChangeLogFilter";
import {IChangeLogsContext} from "../../../containers/ChangeLogsContext";
import {Chips} from "@vidazoo/ui";
import {metaDataStore} from "../../../../../common/stores";
import {useEffect} from "react";

export interface IAccountFilterProps extends React.InputHTMLAttributes<HTMLInputElement> {
	filter: IChangeLogFilter;
	context: IChangeLogsContext;
}

function PlatformTagsFilter({filter, context}: IAccountFilterProps): JSX.Element {

	function onChangeFilterParam(e) {
		const {setFilterParam} = context.store;
		const {name, value} = e.target;
		setFilterParam(filter, name, value);
	}

	function addFilter(value: string) {
		const {addFilterParamArray} = context.store;
		addFilterParamArray(filter, "value", value);
	}

	function onDeleteValue(value: string, label: string) {
		const {removeFilterParamArray} = context.store;
		removeFilterParamArray(filter, "value", value);
	}

	function onSelectAccount(e) {
		const {setAccount} = context.store;
		const {value} = e.target;
		setAccount(value);
	}

	return (
		<>
			<FormSelect
				className={theme.select}
				items={[FilterOperators.isAnyOf, FilterOperators.isNotAnyOf]}
				maxItems={5}
				itemValueKey="value"
				itemLabelKey="label"
				value={filter.operator || FilterOperators.isAnyOf}
				name="operator"
				required
				hideValidationMessage
				onChange={onChangeFilterParam}
				searchable
			/>
			<FormSelect
				className={theme.select}
				items={metaDataStore.accountsByVertical[REPORT_VERTICAL_TYPE.PLATFORM]}
				maxItems={5}
				itemValueKey="_id"
				itemLabelKey="username"
				value={context.store.account}
				name="account"
				required
				hideValidationMessage
				onChange={onSelectAccount}
				searchable
			/>
			<Chips
				deepPurple
				allowOrder={false}
				leaveOpenAfterSelect
				allowCreate={false}
				selectValueOnBlur={false}
				dataSource={context.store.platformTagsByAccount}
				itemLabelKey="name"
				itemValueKey="_id"
				chips={filter.value}
				maxItems={5}
				onSelect={addFilter}
				onDelete={onDeleteValue}
			/>
		</>
	);
}

export default observer(PlatformTagsFilter);
