import {action, observable} from "mobx";
import {formatNumber, IReportingEntry} from "@vidazoo/ui-framework";
import {notificationsStore} from "common/stores";
import BaseNotificationsStore from "@vidazoo/ui-framework/lib/stores/BaseNotificationsStore";
import IAccount from "../interfaces/IAccount";
import {ChipsColors, TimePresets} from "../../../common/enums";
import {storageService} from "../../../common/services";
import * as _ from "lodash";
import IUser from "../../../common/interfaces/IUser";
import {compileFieldFormula} from "../../../common/utils";

export default class MetaDataStore {

	protected readonly NUMBER_FORMAT_REGEXP = /(.?)\.(0+)(.?)/;
	protected notificationsStore: BaseNotificationsStore;
	@observable public timePreset: TimePresets;

	@observable public metaDataByVertical: {
		[vertical: string]: {
			fields: IReportingEntry[];
			groups: IReportingEntry[];
			filters: IReportingEntry[];
			capsules: IReportingEntry[];
		}
	};

	@observable public users: IUser[];
	@observable public usersById: { [index: string]: IAccount };
	@observable public accounts: IAccount[];
	@observable public accountsById: { [index: string]: IAccount };
	@observable public accountsByVertical: {
		[vertical: string]: IAccount[];
	};
	@observable public isLoading: boolean;

	constructor() {
		this.notificationsStore = notificationsStore;
		this.isLoading = false;
		this.metaDataByVertical = {};
		this.timePreset = TimePresets.Yesterday;
	}

	@action
	public setMetaDataByVertical(metaDataByVertical) {
		this.metaDataByVertical = metaDataByVertical;
		for (const vertical in this.metaDataByVertical) {
			const {fields} = this.metaDataByVertical[vertical];
			const {capsules} = this.metaDataByVertical[vertical];
			this.metaDataByVertical[vertical].capsules = capsules.map(this.enhanceCapsules)
			this.metaDataByVertical[vertical].fields = fields.map(this.enhanceField.bind(this));
		}
	}

	private enhanceCapsules = (capsule: any) => {
		capsule.value = capsule.label;
		capsule.vidazooOnly = true;
		capsule.chipColor = ChipsColors.blue;
		capsule.type = "capsule";
		return capsule;
	}

	protected enhanceField(field: IReportingEntry): IReportingEntry {

		if (field.formula) {
			field.formula = compileFieldFormula(field.formula);
		}

		if (field.format) {
			field.format = this.compileFieldFormat(field.format);
		}

		return field;
	}

	protected compileFieldFormat(format): (value: number) => string {
		const matches = format.match(this.NUMBER_FORMAT_REGEXP);
		if (matches) {
			const [pre, decimals, post] = matches.slice(1);
			return (value) => pre + formatNumber(value, decimals.length) + post;
		}
	}

	@action
	public setAccountsByVertical(accountsByVertical) {
		this.accountsByVertical = accountsByVertical;
		this.accounts = [];
		Object.keys(accountsByVertical).forEach((vertical) => {
			accountsByVertical[vertical].forEach((account) => {
				this.accounts.push({...account, username: `${account.username} (${vertical})`});
			});
		});

		this.accountsById = _.keyBy(this.accounts, "_id");
	}

	@action
	public setUsers(users: IUser[]) {
		this.users = users;
		this.users.unshift({email: "VidazooDev@vidazoo.com", _id: "bot"} as any);
		this.usersById = _.keyBy(users, "_id");
	}

	@action public initTimePreset = () => {
		const timePreset = storageService.getTimePreset();
		if (timePreset) {
			this.timePreset = timePreset;
		}
	};

	@action public setTimePreset = (timePreset) => {
		this.timePreset = timePreset;
		storageService.setTimePreset(timePreset);
	};
}
