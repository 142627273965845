import * as React from "react";
import ChangeLogsHeader from "../components/changeLogsHeader/ChangeLogsHeader";
import ChangeLogsContext from "./ChangeLogsContext";
import ChangeLogsStore from "../stores/ChangeLogsStore";
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {BaseContainer} from "@vidazoo/ui-framework";
import ChangeLogsList from "../components/changeLogsList/ChangeLogsList";
import * as theme from "../../../styles/_baseList.scss";
import {Label, Modal, Surface} from "@vidazoo/ui";
import {renderValueSnapshot} from "@vidazoo/ui-framework/lib/components/historyLog/utils";
import * as selfTheme from "./theme.scss";

export interface IChangeLogsProps {
	location: Location
}

export interface IChangeLogsState {
	selectedItem: any;
}

class ChangeLogs extends BaseContainer<IChangeLogsProps, IChangeLogsState> {

	public static defaultProps: Partial<IChangeLogsProps> = {};
	public store: ChangeLogsStore;

	constructor(props, context) {
		super(props, context);
		this.store = new ChangeLogsStore(this.props.location.search);
		this.state = {selectedItem: null};
	}

	public componentDidMount() {
		this.store.getItems();
		this.store.getPlatformTags();
	}

	public setSelectedItem = (item) => {
		this.setState({selectedItem: item});
	};

	public removeSelectedItem = () => {
		this.setState({selectedItem: null});
	};

	public render(): JSX.Element {
		const {selectedItem} = this.state;

		return (
			<div className={theme.dataGrid}>
				<ChangeLogsContext.Provider value={{store: this.store}}>
					<ChangeLogsHeader/>
					<ChangeLogsList setSelectedItem={this.setSelectedItem}/>
					<Modal external exitOnClickOutside onClose={this.removeSelectedItem} isOpen={!!selectedItem}>
						<Surface className={selfTheme.modal}>
							<Label style={{cursor: "text"}} uppercase={false}>
								{renderValueSnapshot(selectedItem)}
							</Label>
						</Surface>
					</Modal>
				</ChangeLogsContext.Provider>
			</div>
		);
	}
}

export default withRouter<any, any>(observer(ChangeLogs));
