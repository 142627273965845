import { action } from "mobx";
import {dashboardsStore, navigationStore} from "common/stores";
import { appUrlsService } from "common/services";
import dashboardsAPI from "../api/dashboardsAPI";
import BaseDashboardStore from "./BaseDashboardStore";

export default class CreateDashboardStore extends BaseDashboardStore {

	@action public submit() {
		const item = this.setAccounts();

		dashboardsAPI.create(item)
			.then(() => navigationStore.push(appUrlsService.dashboards()))
			.then(() => dashboardsStore.getItems())
			.then(() => this.onSubmitSuccess())
			.catch((err) => this.onSubmitFailed(err));
	}
}
