import osOptions from "@vidazoo/ui-framework/lib/common/osOptions";
import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";

export default class OsFilter extends BaseFilter {

	public static type = PlatformReportingFilterType.OS;

	constructor() {
		super();

		this.items = osOptions;
		this.valueKey = "value";
		this.labelKey = "label";
		this.allowNew = true;
	}
}
