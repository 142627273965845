import * as React from "react";
import * as theme from "../theme.scss";
import {observer} from "mobx-react";
import {Button, Loader, Modal} from "@vidazoo/ui";
import AddChartStore from "../../../stores/AddChartStore";
import EditDashboardStore from "../../../stores/EditDashboardStore";
import CreateDashboardStore from "../../../stores/CreateDashboardStore";
import ChartTypesSelect from "../../../../chart/containers/chart/chartTypes/ChartTypesSelect";
import {FormMultiSelect, MultiSelectOption} from "@vidazoo/ui/lib/components/multiSelect";
import ChartDisplay from "../../../../chart/containers/chart/ChartDisplay";
import {editChartsManagerStore, metaDataStore} from "../../../../../common/stores";

export interface IAddChartModalProps {
	isOpen: boolean;
	toggleModal: () => void;
	store: EditDashboardStore | CreateDashboardStore;
}

export interface IAddChartModalState {
}

@observer
export default class AddChartModal extends React.Component<IAddChartModalProps, IAddChartModalState> {

	private addChartStore: AddChartStore;

	constructor(props, context) {
		super(props, context);
		this.addChartStore = new AddChartStore();
	}

	private onAddClick = () => {
		const {store} = this.props;
		store.onAddLayout(this.addChartStore.selectedChart);
		this.props.toggleModal();
	};

	private onCancelClick = () => {
		this.props.toggleModal();
	};

	public render(): JSX.Element {
		const {isLoadingCharts, charts} = this.props.store;
		const {selectedChart, selectedType} = this.addChartStore;
		const filteredCharts = selectedType ? charts.filter((chart) => chart.type === selectedType) : charts;

		const chartStore = selectedChart ? editChartsManagerStore.chartStores[selectedChart] : null;

		return (
			<Modal isOpen={this.props.isOpen} exitOnClickOutside panel title="Add Chart"
				   onClose={this.props.toggleModal}>
				<div className={theme.modal}>
					<div className={theme.row}>
						{isLoadingCharts ? (
							<Loader/>
						) : (
							<FormMultiSelect className={theme.select} surfaceLevel={0} searchable focus maxItems={5}
											 value={selectedChart}
											 onSelectionChange={this.addChartStore.selectChart} multiple={false}>
								{filteredCharts.map((chart) => {
									return (
										<MultiSelectOption label={chart.label} value={chart._id} key={chart._id}/>
									);
								})}
							</FormMultiSelect>
						)}
						<ChartTypesSelect onSelectType={this.addChartStore.onSelectType}
										  selectedType={selectedType}/>
					</div>
					<div className={theme.chartView}>
						{(selectedChart && chartStore) && (
							<ChartDisplay
								columnsWidth={chartStore.columnsWidth}
								setColumnsWidth={chartStore.setColumnsWidth}
								isLoadingReport={chartStore.chartReportByTimePreset[metaDataStore.timePreset].isLoading}
								chart={chartStore.item}
								chartReport={chartStore.chartReportByTimePreset[metaDataStore.timePreset].results}/>
						)}
					</div>
					<div className={theme.actions}>
						<Button type="button" medium gray uppercase onClick={this.onCancelClick}>Cancel</Button>
						<Button type="button" disabled={!selectedChart} medium blue uppercase onClick={this.onAddClick}>Add</Button>
					</div>
				</div>
			</Modal>
		);
	}
}
