import App from "./App";
import { inject, observer } from "mobx-react";

export default inject((stores: any, props: any, context: any): any => ({
	userName: stores.currentUserStore.fullName,
	userEmail: stores.currentUserStore.email,
	userAvatarUrl: stores.currentUserStore.avatar,
	onSignOut: stores.sessionStore.logout,
	notificationsStore: stores.notificationsStore,
	uiStore: stores.uiStore,
	userScopes: stores.sessionStore.userScopes,
	setTimePreset: stores.metaDataStore.setTimePreset,
	timePreset: stores.metaDataStore.timePreset,
	userId: stores.currentUserStore._id,
	cloudDashboardsStars: stores.cloudStore.dashboardsStars
}))(observer(App));
