import * as React from "react";
import Switch from "react-router-dom/Switch";
import Redirect from "react-router-dom/Redirect";
import {UserRole, SpreadPageLoader, BaseNotificationsStore} from "@vidazoo/ui-framework";
import {AuthRoute} from "common/components";
import {AppHeader, DefaultCharts} from "../../components";
import {NotificationStack} from "@vidazoo/ui/lib/components/notificationStack";
import * as theme from "./theme.scss";
import {appUrlsService} from "common/services";
import {currentUserStore, dashboardsStore} from "common/stores";
import UIStore from "common/stores/UIStore";
import Profile from "@vidazoo/ui-framework/lib/containers/profile";
import Dashboard from "../../../dashboard/containers/dashboard/Dashboard";
import Dashboards from "../../../dashboard/containers/dashboards/Dashboards";
import Charts from "../../../chart/containers/charts/Charts";
import Chart from "../../../chart/containers/chart/Chart";
import {TimePresets} from "../../../../common/enums";
import ChangeLogs from "../../../changeLogs/containers/ChangeLogs";
import Reporting from "../../../reporting/containers/reporting";
import ScheduleReports from "../../../scheduleReports/containers/list/ScheduleReportsList";
import CreateScheduleReport from "../../../scheduleReports/containers/item/create/CreateScheduleReport";
import EditScheduleReport from "../../../scheduleReports/containers/item/edit/EditScheduleReport";
import {NotificationCenter, NotificationSettings} from "../../../../common/userNotifications";

export interface IAppProps {
	userName?: string;
	userEmail?: string;
	userAvatarUrl?: string;
	onSignOut?: () => void;
	notificationsStore: BaseNotificationsStore;
	uiStore?: UIStore;
	userScopes?: string[];
	isVidazooNetwork?: () => boolean;
	setTimePreset: (name: string) => void;
	timePreset: TimePresets;
	userId?: string;
	cloudDashboardsStars: string[];
}

export interface IAppState {

}

export default class App extends React.Component<IAppProps, IAppState> {

	public static defaultProps: Partial<IAppProps> = {};

	constructor(props, context) {
		super(props, context);
	}

	public componentDidMount() {
		if (!dashboardsStore.charts || dashboardsStore.charts.length < 1) {
			dashboardsStore.getItems();
		}
	}

	public render(): JSX.Element {
		const {
			userScopes,
			userName,
			userEmail,
			userAvatarUrl,
			onSignOut,
			uiStore,
			notificationsStore,
			setTimePreset,
			timePreset,
			userId
		} = this.props;
		const lastChart = window.localStorage.getItem("lastChart")

		return (
			<div className={theme.app}>
				<AppHeader
					{...{
						userName,
						userEmail,
						userAvatarUrl,
						onSignOut,
						openMyProfile: uiStore.toggleProfileModal,
						userScopes,
						userId
					}}
					cloudDashboardsStars={this.props.cloudDashboardsStars}
					dashboardsById={dashboardsStore.dashboardsById}
					setTimePreset={setTimePreset}
					timePreset={timePreset}
					isInRoles={currentUserStore.isInRoles}
					isFeatureAllowed={currentUserStore.isFeatureAllowed}
				/>

				{(!["/change-logs", "/schedule-reports"].includes(window.location.pathname) && !window.location.pathname.startsWith("/reporting") && dashboardsStore.defaultCharts.length > 0) ? (
					<DefaultCharts/>
				) : null}
				<NotificationStack
					verticalGap={6}
					horizontalGap={0}
					notifications={notificationsStore.notifications}
					onClose={notificationsStore.removeNotification}
				/>
				<Profile
					notificationStore={notificationsStore}
					isOpen={uiStore.isProfileModalOpen}
					onClose={uiStore.toggleProfileModal}
				/>
				<div className={theme.pageContainer}>
					<React.Suspense fallback={<SpreadPageLoader blue/>}>
						<Switch>
							<AuthRoute path={appUrlsService.changeLogs()} component={ChangeLogs}
									   scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]}/>
							<AuthRoute path={appUrlsService.charts()} component={Charts}
									   scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]}/>
							<AuthRoute path={appUrlsService.chart()} component={Chart}
									   scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]}/>
							<AuthRoute path={appUrlsService.dashboards()} component={Dashboards}
									   scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]}/>
							<AuthRoute path={appUrlsService.dashboard()} component={Dashboard}
									   scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]}/>
							<AuthRoute path={appUrlsService.reporting()} component={Reporting}
									   scope={[UserRole.ROOT_ADMIN, UserRole.SUPER_ADMIN, UserRole.USER]}/>

							<AuthRoute path={appUrlsService.createScheduleReport()} scope={[UserRole.ROOT_ADMIN]}
									   component={CreateScheduleReport}/>
							<AuthRoute path={appUrlsService.editScheduleReport()} scope={[UserRole.ROOT_ADMIN]}
									   component={EditScheduleReport}/>
							<AuthRoute path={appUrlsService.scheduleReports()} component={ScheduleReports}
									   scope={[UserRole.ROOT_ADMIN]}/>
							<AuthRoute path={appUrlsService.notificationCenter()} component={NotificationCenter}
									   scope={[UserRole.USER, UserRole.ADMIN, UserRole.SUPER_ADMIN]}/>,
							<AuthRoute path={appUrlsService.notificationSettings()} component={NotificationSettings}
									   scope={[UserRole.USER, UserRole.ADMIN, UserRole.SUPER_ADMIN]}/>,
							<Redirect to={appUrlsService.viewChart(lastChart || "6329c84244c9a00e627fb270")}/>
						</Switch>
					</React.Suspense>
				</div>
			</div>
		);
	}
}
