import * as React from "react";
import * as selfTheme from "./theme.scss";
import {observer} from "mobx-react";
import {Label} from "@vidazoo/ui";
import {ChartType, ChartTypesWithImg} from "../../../../../common/enums";
import clsx from "clsx";
import {FormMode} from "@vidazoo/ui-framework";

export interface IChartTypesSelectProps {
	onSelectType: (type: ChartType) => void;
	selectedType: ChartType;
	disabled?: boolean;
}

export interface IChartTypesSelectState {

}

@observer
export default class ChartTypesSelect extends React.Component<IChartTypesSelectProps, IChartTypesSelectState> {

	public render(): JSX.Element {

		const {onSelectType, selectedType, disabled} = this.props;

		const types = disabled ? ChartTypesWithImg.filter((c) => c.value === selectedType) : ChartTypesWithImg;


		return (
			<div className={selfTheme.container}>
				{types.map((type) => {
					const classNames = clsx(selfTheme.type, {
						[selfTheme.selected]: type.value === selectedType
					});

					return (
						<div key={type.value} className={classNames} onClick={disabled ? null : () => onSelectType(type.value)}>
							<Label weight={500} spacing={1} size={14}>{type.label}</Label>
							<div className={selfTheme.image} style={{backgroundImage: `url(${type.image})`}}/>
						</div>
					);
				})}
			</div>
		);
	}
}
