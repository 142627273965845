import BaseNeededGroup from "../BaseNeededGroup";
import {REPORT_VERTICAL_TYPE} from "../../../../../common/enums";
import {PlatformNeededGroups} from "../NeededGroups";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import {action} from "mobx";

export default class PartnerTagIdFilter extends BaseNeededGroup {

	public static type = PlatformReportingFilterType.PartnerName;

	constructor() {
		super(REPORT_VERTICAL_TYPE.PLATFORM, PlatformNeededGroups.DemandPartners);
	}

	@action
	public setData(res) {
		console.log("PartnerTagIdFilter", res);
		this.items = [...res.data, {_id: "Vidazoo Marketplace", name: "Vidazoo Marketplace"}];
		this.isLoading = false;
	}
}
