import * as React from "react";
import {observer} from "mobx-react";
import {Layout} from "react-grid-layout";
import {CircleLoader, PageMessage, Surface} from "@vidazoo/ui";
import * as theme from "../theme.scss";
import {ChartType} from "../../../../../common/enums";
import DiffTable from "../diffTable/DiffTable";
import Summary from "../summary";
import ChartHeader from "../chartHeader/ChartHeader";
import EditDashboardStore from "../../../stores/EditDashboardStore";
import CreateDashboardStore from "../../../stores/CreateDashboardStore";
import PerformanceChart from "../performanceChart";
import {DayField} from "@vidazoo/ui-framework";

export interface ILayoutChartProps {
	layout: Layout;
	editMode: boolean;
	store: EditDashboardStore | CreateDashboardStore;
}

export interface ILayoutChartState {
}

function LayoutChart({layout, editMode, store}: ILayoutChartProps): JSX.Element {

	const handlePress = (e) => {
		if (!editMode || (window as any).__device === "mobile" || window.innerWidth < 500) {
			e.stopPropagation();
		}
	};

	const renderNeedFilter = () => {
		return (
			<div>
				<PageMessage
					small
					title="This Chart Must Filter!"
				/>
			</div>
		);
	};

	const setRef = (el) => {
		this.ref = el;
		this.ref && this.ref.addEventListener("touchstart", handlePress, {passive: false});
	};

	if (store.chartsById[layout.i] && store.chartsById[layout.i].mustFilter && store.reportParams.filters.length === 0) {
		return renderNeedFilter();
	}

	if (store.chartsById[layout.i] && (store.chartsById[layout.i] as any).isLoadingReport) {
		return (
			<Surface className={theme.center}>
				<CircleLoader size={8} gray/>
			</Surface>
		);
	}

	if (store.chartsById[layout.i]) {
		let component;
		let classname;
		const reportByChart = store.reportByChart(layout.i);

		if (!reportByChart || reportByChart.isLoading) {
			return (
				<div className={theme.center}>
					<CircleLoader size={8} gray/>
				</div>
			);
		}

		switch (store.chartsById[layout.i].type) {
			case ChartType.DATA_TABLE:
				component =
					<DiffTable chart={store.chartsById[this.props.layout.i]} report={reportByChart.results}/>;
				classname = theme.table;
				break;
			case ChartType.SUMMARY:
				component = <Summary fields={reportByChart.results}/>;
				classname = theme.summary;
				break;
			case ChartType.PERFORMANCE:
				component = <PerformanceChart isLoading={reportByChart.isLoading}
											  series={reportByChart.results.series}
											  dayField={DayField.DATE}
											  height="100%"
											  labels={reportByChart.results.labels}/>;
				classname = theme.summary;
				break;
		}

		return (
			<div onMouseDown={handlePress} ref={setRef} className={classname}>
				<ChartHeader store={store} layout={layout} editMode={editMode}/>
				{component}
			</div>
		);
	}

	return (
		<div>
			{store.chartsById[layout.i] && store.chartsById[layout.i].name}
		</div>
	);
}

export default observer(LayoutChart);
