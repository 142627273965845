import BaseNeededGroup from "../BaseNeededGroup";
import {REPORT_VERTICAL_TYPE} from "../../../../../common/enums";
import {PlatformNeededGroups} from "../NeededGroups";
import {PlatformReportingFilterType} from "../ReportingFilterType";

export default class WidgetFilter extends BaseNeededGroup {

	public static type = PlatformReportingFilterType.Widget;

	constructor() {
		super(REPORT_VERTICAL_TYPE.PLATFORM, PlatformNeededGroups.Widgets);
	}
}
