import ServerAppFilter from "./platformHandlers/ServerAppFilter";

export enum PlatformReportingFilterType {
	Device = "device",
	PublisherName = "publisherName",
	PublisherId = "publisherId",
	ScenarioName = "scenarioName",
	TagName = "tagName",
	ABTestName = "abTestName",
	Domain = "domain",
	Country = "country",
	Stream = "stream",
	PlayerName = "playerName",
	PlayerId = "playerId",
	PartnerTagId = "partnerTagId",
	PartnerName = "partnerName",
	PartnerTagName = "partnerTagName",
	videoName = "videoName",
	crawlerName = "crawlerName",
	TagType = "tagType",
	TagTypePrebid = "tagTypePrebid",
	CountryLists = "countryLists",
	DomainLists = "domainLists",
	Widget = "widgetOriginal",
	Advertiser = "advertiserName",
	WidgetType = "widgetType",
	User = "userName",
	PublisherAccount = "publisherAccountName",
	CountryGroup = "countryGroup",
	RemovedDemandPartnerName = "removedDemandPartnerName",
	ScenarioMarketPlace = "marketScenario",
	OS = "os",
	Connection = "connectionName",
	ServerApp = "serverApp",
	ServerHost = "serverHost",
	AdvertiserName = "advertiserName",
	AccountManager = "accountManagerName",
	EnterpriseAccount = "enterpriseAccountName"
}

export enum OpenRtbReportingFilterType {
	Device = "device",
	Domain = "domain",
	Country = "country",
	crawlerName = "crawlerName",
	Connection = "bid",
	Exchange = "exchangeName"
}
