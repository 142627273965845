import { BaseSocketService } from "@vidazoo/ui-framework";

class SocketService extends BaseSocketService {

	protected onSocketError(err) {
		if (err === "unauthorized") {
			window.__sdk__.auth.logout();
		}
	}

	public getReporting(data) {
		return this.request("getReporting", data, 10 * 60 * 1000);
	}

	public getReportByChart(data) {
		return this.request("getReportByChart", data, 10 * 60 * 1000);
	}

	public getReportByChartCreate(data) {
		return this.request("getReportByChartCreate", data, 10 * 60 * 1000);
	}


	public getCompareReporting(data) {
		return this.request("getCompareReporting", data, 10 * 60 * 1000);
	}

	public getAdvancedReport(data) {
		return this.request("getAdvancedReport", data);
	}

	public getAdStartByDate(data) {
		return this.request("getAdStartByDate", data);
	}

	public getFillRateByDate(data) {
		return this.request("getFillRateByDate", data);
	}

	public getAdStartByTag(data) {
		return this.request("getAdStartByTag", data);
	}

	public getAdStartByConnection(data) {
		return this.request("getAdStartByConnection", data);
	}

	public getAdStartByBrowser(data) {
		return this.request("getAdStartByBrowser", data);
	}

	public getAdStartByDomain(data) {
		return this.request("getAdStartByDomain", data);
	}

	public getAdStartByOs(data) {
		return this.request("getAdStartByOs", data);
	}

	public getRevenue(data) {
		return this.request("getRevenue", data);
	}

	public getRevenueByCountry(data) {
		return this.request("getRevenueByCountry", data);
	}

	public startListenToReportByChart(userId: string, chartId: string, timePreset: string, fn: (data: any) => void) {
		this.socket.on(`reportByChart${userId}${chartId}${timePreset}`, fn);
	}

	public removeListenToReportByChart(userId: string, chartId: string, timePreset: string) {
		this.socket.off(`reportByChart${userId}${chartId}${timePreset}`);
	}
}

export default new SocketService();
