import BaseNeededGroup from "../BaseNeededGroup";
import {REPORT_VERTICAL_TYPE} from "../../../../../common/enums";
import {PlatformNeededGroups} from "../NeededGroups";
import {PlatformReportingFilterType} from "../ReportingFilterType";

export default class ServerAppFilter extends BaseNeededGroup {

	public static type = PlatformReportingFilterType.ServerApp;

	constructor() {
		super(REPORT_VERTICAL_TYPE.PLATFORM, PlatformNeededGroups.Apps);
	}
}
