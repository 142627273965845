import {observable, action, transaction, computed} from "mobx";
import accountsAPI from "modules/accounts/api/accountsAPI";
import {historyReportsSocketService, socketService} from "common/services";
import IUser from "common/interfaces/IUser";
import {
	cloudStore,
	currentUserStore,
	dashboardsStore,
	editChartsManagerStore,
	metaDataStore,
	reportingStore
} from "common/stores";
import INetwork from "common/interfaces/INetwork";
import IAccount from "modules/accounts/interfaces/IAccount";
import * as _ from "lodash";
import {UserNotificationsSocketService} from "@vidazoo/ui-framework";
import EditChartsManagerStore from "../../modules/chart/stores/EditChartsManagerStore";
import moment from "moment";

export default class SessionStore {
	@observable public isAuthenticated: boolean;
	@observable public isAuthenticating: boolean;
	@observable public isAuthError: boolean;
	@observable public accessToken: string;
	@observable public selectedAccounts: string[];
	@observable public historyReportsIsOffline: boolean;
	public userScopes: any[];
	public isAuthTimeout: boolean;
	public accounts: IAccount[];
	public indexAccounts: any;

	constructor() {
		window.__sdk__.auth.on("session:change", (err, res) => this.onSessionChange(err, res));

		this.isAuthenticated = false;
		this.isAuthenticating = true;
		this.isAuthError = false;
		this.isAuthTimeout = false;
		this.accessToken = null;
		this.accounts = [];
		this.selectedAccounts = window.__sdk__.auth.selectedAccounts;
		this.indexAccounts = {};
	}

	@action public setSelectedAccounts = (accounts: string[], reload?: boolean) => {
		window.__sdk__.auth.setSelectedAccounts(accounts);
		this.selectedAccounts = accounts;
		currentUserStore.setNetwork(this.indexAccounts[accounts[0]].network);

		if (reload) {
			window.location.reload();
		}
	};

	@computed
	public get userAccounts(): IAccount[] {
		return this.accounts;
	}

	@computed
	public get isMultiAccounts(): boolean {
		return this.accounts.length > 1;
	}

	@computed
	public get isMultiSelectedAccounts(): boolean {
		return this.selectedAccounts.length > 1;
	}

	@action
	private async onSessionChange(err, response) {
		if (response) {
			this.accessToken = response.accessToken;

			try {
				const account = await accountsAPI.me(10 * 1000);
				metaDataStore.setMetaDataByVertical(account.data.metaDataByVertical);
				metaDataStore.setAccountsByVertical(account.data.accountsByVertical);
				metaDataStore.setUsers(account.data.users);

				this.userScopes = response.user.scopes;

				this.onLogin(response.accessToken, account.data);
			} catch (err) {
				this.onLoginFailed(err);
			}
		}
	}

	@action
	public initialize() {
		window.__sdk__.auth.authorize();
	}

	@action public logout = () => {
		window.__sdk__.auth.logout();
	};

	@action
	private async onLogin(accessToken: string, data: {
		auth: IUser,
		network: INetwork,
		accounts: { results: IAccount[] }
	}) {

		// const key = "stayOldByDay";
		//
		// const paramDay = `old${moment().format("DDMM")}=true`;
		// if (window.location.href.includes(paramDay)) {
		// 	this.setItemWithExpiry(key, "true", moment().add(1, "day").valueOf());
		//
		// } else {
		// 	const stayOld = this.getItemWithExpiry(key);
		// 	if (!stayOld || stayOld !== "true") {
		// 		window.location.href = "https://bd.vidazoo.com";
		// 	}
		// }

		this.selectedAccounts = window.__sdk__.auth.selectedAccounts;
		this.accounts = data.accounts.results;
		this.indexAccounts = _.keyBy(this.accounts, "_id");

		if (!this.selectedAccounts.length) {
			window.__sdk__.auth.setSelectedAccounts(data.accounts[0].toString());
			this.selectedAccounts = window.__sdk__.auth.selectedAccounts;
		}

		currentUserStore.initialize({...data.auth, network: this.indexAccounts[this.selectedAccounts[0]].network});
		cloudStore.initialize();
		metaDataStore.initTimePreset();

		await socketService.initialize(window.__BASE_API_URL__, this.accessToken);
		try {
			await historyReportsSocketService.initialize(window.__REPORTING_HISTORY_URL__, this.accessToken);
		} catch (err) {
			this.historyReportsSocketFailed();
		}

		try {
			await UserNotificationsSocketService.initialize((window.__USER_NOTIFICATIONS_API_URL__), this.accessToken);

		} catch (err) {
			this.onLoginFailed(err);
		}

		this.onSocketConnected();
		reportingStore.initAfterAllInitialize();
	}

	@action
	private historyReportsSocketFailed() {
		this.historyReportsIsOffline = true;
	}

	@action
	private onSocketConnected() {
		transaction(() => {
			this.isAuthenticated = true;
			this.isAuthenticating = false;
			this.isAuthError = false;
			this.isAuthTimeout = false;
		});
	}

	@action
	private onLoginFailed(err) {
		transaction(() => {
			this.accessToken = null;
			this.isAuthenticated = false;
			this.isAuthenticating = false;
			this.isAuthError = true;
			this.isAuthTimeout = err && _.includes(err.toString().toLowerCase(), "timeout");
		});
	}

	private setItemWithExpiry(key: string, value: any, expiryTime: number) {
		const item = {
			value,
			expiry: expiryTime,
		};
		localStorage.setItem(key, JSON.stringify(item));
	}

	private getItemWithExpiry(key: string): any {
		const itemString = localStorage.getItem(key);
		if (!itemString) {
			return null;
		}
		const item = JSON.parse(itemString);
		const now = new Date();
		const timeNow = now.getTime();
		if (timeNow > item.expiry) {
			localStorage.removeItem(key);
			return null;
		}
		return item.value;
	}

}
