import * as React from "react";
import * as theme from "styles/_baseForm.scss";
import {FormMode, IReportingConstraint, IReportingFilter} from "@vidazoo/ui-framework";
import {Button, Chips, FormChips, HLine, Label, LinkButton} from "@vidazoo/ui";
import {ReportingConstraint, ReportingFilter} from "@vidazoo/ui-framework/lib/containers/reporting/components";
import {observer} from "mobx-react";
import ChartContext from "./ChartContext";
import {ChartType} from "../../../../common/enums";
import reportingService from "../../../reporting/services/reportingService";
import StyleForm from "./StyleForm";

export interface IReportFormProps {

	mode: FormMode;
}

export interface IReportFormState {

}

@observer
export default class ReportForm extends React.Component<IReportFormProps, IReportFormState> {

	public static defaultProps: Partial<IReportFormProps> = {};
	public static contextType = ChartContext;

	constructor(props, context) {
		super(props, context);
		reportingService.initialize()
	}

	private onAddFilter = () => {
		const {store} = this.context;

		store.addFilter();
	}

	private onRemoveFilter = (filter: IReportingFilter) => {
		const {store} = this.context;

		store.removeFromArrayInObjByKey("reportParams", "filters", filter.id, "id");
	}

	private onAddConstraint = () => {
		const {store} = this.context;

		store.addConstraint();
	}

	private onRemoveConstraint = (constraint: IReportingConstraint) => {
		const {store} = this.context;

		store.removeFromArrayInObjByKey("reportParams", "constraints", constraint.id, "id");
	}

	private onDeleteDimension = (value: string) => {
		const {store} = this.context;

		store.removeFromArrayInObj("reportParams", "groups", value);
	}

	private onOrderDimensions = (dimensions: any[]) => {
		const {store} = this.context;

		store.updateDeepParam("reportParams", "groups", dimensions);
	}

	private onAddDimension = (value: string, label: string) => {
		const {store} = this.context;

		store.addToArrayInObj("reportParams", "groups", value);
	}

	private onChangeFilter = (filter: IReportingFilter, key: string, value: string) => {
		const {store} = this.context;

		store.setFilterParam(filter, key, value);
	}

	private onDeleteMetric = (value: string, label: string) => {
		const {store} = this.context;

		store.removeFromArrayInObj("reportParams", "fields", value);
	}

	private onOrderMetrics = (metrics: any[]) => {
		const {store} = this.context;

		store.updateDeepParam("reportParams", "fields", metrics);
	}

	private onAddMetric = (value: string, label: string) => {
		const {store} = this.context;

		store.addToArrayInObj("reportParams", "fields", value);
	}

	private onAddCustomStyle = () => {
		const {store} = this.context;
		store.addCustomStyle();
	}

	public render(): JSX.Element {

		const {store, metaDataStore} = this.context;
		const {reportParams, type} = store.item;

		return (
			<div className={theme.form}>
				<div className={`flex ${type !== ChartType.SUMMARY ? "justify-space-between" : "justify-flex-end"} align-center`} style={{marginBottom: 30}}>
					{type !== ChartType.SUMMARY ? (
						<Label spacing={1.25} size={13} monserrat weight={600}>Dimensions *</Label>) : null}
					<Button type="button" iconName="icon-plus-small" deepPurple small uppercase
							onClick={this.onAddFilter}>Add Filter</Button>
				</div>
				{type !== ChartType.SUMMARY ? (
					<FormChips
						fill
						deepPurple
						allowOrder
						required
						chips={reportParams.groups}
						maxItems={5}
						leaveOpenAfterSelect
						onDelete={this.onDeleteDimension}
						onOrder={this.onOrderDimensions}
						onSelect={this.onAddDimension}
						dataSource={metaDataStore.metaDataByVertical[reportParams.verticalType].groups}
						searchPlaceholder="Add Dimension"
						emptyPlaceholder="Add Dimension"
					/>
				) : null}
				{reportParams.filters.map((filter, idx) => <ReportingFilter key={filter.id} index={idx}
																			groups={metaDataStore.metaDataByVertical[reportParams.verticalType].filters}
																			filter={filter}
																			onChange={this.onChangeFilter}
																			onDelete={this.onRemoveFilter}
																			onAddValue={store.pushFilterValue}
																			onDeleteValue={store.removeFilterValue}/>)}
				<HLine space={3.2}/>
				<div className="flex justify-space-between align-center" style={{marginBottom: 30}}>
					<Label spacing={1.25} size={13} monserrat weight={600}>Metrics *</Label>
					<Button type="button" iconName="icon-plus-small" deepPurple small uppercase
							onClick={this.onAddConstraint}>Add Constraint</Button>
				</div>
				<FormChips
					fill
					deepPurple
					allowOrder
					required
					name="metrics"
					chips={reportParams.fields}
					maxItems={5}
					leaveOpenAfterSelect
					onDelete={this.onDeleteMetric}
					onOrder={this.onOrderMetrics}
					onSelect={this.onAddMetric}
					dataSource={metaDataStore.metaDataByVertical[reportParams.verticalType].fields}
					searchPlaceholder="Add Metric"
					emptyPlaceholder="Add Metric"
				/>
				{reportParams.constraints.map((constraint, idx) => <ReportingConstraint key={constraint.id} index={idx}
																						fields={metaDataStore.metaDataByVertical[reportParams.verticalType].fields}
																						constraint={constraint}
																						onChange={store.setConstraintParam}
																						onDelete={this.onRemoveConstraint}/>)}
				{type === ChartType.DATA_TABLE &&
                    <div className="flex justify-flex-end align-center" style={{marginBottom: 30, marginTop: 30}}>
                        <Button type="button" iconName="icon-plus-small" deepPurple small uppercase
                                disabled={!(reportParams.fields && reportParams.fields.length > 0)}
                                onClick={this.onAddCustomStyle}>Add Custom Style</Button>
                    </div>}
				{store.item.customStyles && store.item.customStyles.map((style, index) => <StyleForm style={style}
																									 index={index}
																									 key={style.id}/>)}
			</div>
		);
	}
}
