import * as React from "react";
import * as PropTypes from "prop-types";
import * as moment from "moment";
import * as theme from "styles/_baseList.scss";
import * as selfTheme from "./theme.scss";
import { observer } from "mobx-react";
import {Switch, LinkButton, DataGridColumn, DataGridRow, Checkbox} from "@vidazoo/ui";
import { Link } from "react-router-dom";
import { appUrlsService } from "common/services";
import IChart from "../../interfaces/IChart";

export interface IChartRowProps {

	item: IChart;

	sortBy: string;

	onActiveChange: (item: IChart) => void;

	onEdit: (item: IChart) => void;

	onDelete: (item: IChart) => void;

	onToggle: (id: string) => void;

	isSelected: boolean;

	columnsWidth: any;

	index: number;
}

export interface IChartRowState {

}

@observer
export default class ChartRow extends React.Component<IChartRowProps, IChartRowState> {

	public static defaultProps: Partial<IChartRowProps> = {

	};

	constructor(props, context) {
		super(props, context);
	}

	private onActiveChange = () => {
		const { onActiveChange, item } = this.props;

		onActiveChange(item);
	}

	private onDelete = () => {
		const { onDelete, item } = this.props;

		onDelete(item);
	}

	private onEdit = () => {
		const { item, onEdit } = this.props;

		onEdit(item);
	}

	private onToggle = () => {
		const { item, onToggle } = this.props;

		onToggle(item._id);
	}

	public render(): JSX.Element {

		const { item, sortBy, isSelected, columnsWidth, index } = this.props;

		return (
			<DataGridRow className={selfTheme.row} index={index} columnsWidth={columnsWidth} key={index}>
				<DataGridColumn className={selfTheme.column}><Checkbox checked={isSelected} onChange={this.onToggle}/></DataGridColumn>
				<DataGridColumn className={selfTheme.column}><Link to={appUrlsService.editChart(item._id)}>{item.name}</Link></DataGridColumn>
				<DataGridColumn className={selfTheme.column}>{item.type}</DataGridColumn>
				<DataGridColumn className={selfTheme.column}>{moment(item.created).format("MMM DD, YYYY")}</DataGridColumn>
				<DataGridColumn className={selfTheme.column}>{moment(item.updated).format("MMM DD, YYYY")}</DataGridColumn>
				<DataGridColumn className={selfTheme.column}>
					<Switch small checked={item.isActive} onChange={this.onActiveChange}/>
				</DataGridColumn>
				<DataGridColumn className={selfTheme.column}>
					<div className={theme.links}>
						<LinkButton onClick={this.onEdit} iconName="icon-edit" iconSize={1.8}/>
						<LinkButton onClick={this.onDelete} iconName="icon-delete" iconSize={1.8}/>
					</div>
				</DataGridColumn>
			</DataGridRow>
		);
	}
}
