import {action, computed, observable, transaction} from "mobx";
import {metaDataStore, notificationsStore} from "common/stores";
import {IChangeLog} from "../interfaces/IChangeLog";
import {IChangeLogsParams} from "../interfaces/IChangeLogsParams";
import moment from "moment";
import {IChangeLogFilter} from "../interfaces/IChangeLogFilter";
import changeLogsAPI from "../api/changeLogsAPI";
import {FilterOperators, FilterTypes, REPORT_VERTICAL_TYPE} from "../../../common/enums";
import {
	BaseListStore,
	getCurrentHour,
	getCurrentMonth,
	getCurrentWeek,
	getLastMonth,
	getPastDays, MemoryClipboard, ReportingTimePreset, URLQueryParams
} from "@vidazoo/ui-framework";
import IUser from "../../../common/interfaces/IUser";
import INetwork from "../../../common/interfaces/INetwork";
import {storageService} from "../../../common/services";
import reportingFiltersManager from "../../reporting/stores/filters/reportingFiltersManager";
import {PlatformReportingFilterType} from "../../reporting/stores/filters/ReportingFilterType";
import * as _ from "lodash";

const FIELDS = ["id", "scope", "account", "auth", "action", "model_name", "model_id", "date", "diff"];
const GAL_ACCOUNT = "551424a21b1b1f08006fb6f9";
const PLATFORM_BASE_URL = "https://bo.vidazoo.com";
const OPEN_RTB_BASE_URL = "https://openrtb.vidazoo.com";

export default class ChangeLogsStore extends BaseListStore<IChangeLog, IUser, INetwork> {

	@observable public count: number;
	@observable public params: IChangeLogsParams;
	@observable public account: string;
	@observable public tagsByAccount: { [index: string]: any };
	@observable public nameByIdAndScope: { [index: string]: any };
	@observable public activePreset: string;

	constructor(search) {
		super(notificationsStore, FIELDS, "change logs", "date");
		this.paramsFromSearch(search);
	}

	@action
	public reset() {
		super.reset();
		this.count = 0;
		this.params = this.initialParams;
		this.account = GAL_ACCOUNT;
		this.tagsByAccount = {};
		this.nameByIdAndScope = {};
		this.activePreset = "now";
	}

	@action public getItems = () => {
		if (this.isLoading) {
			return;
		}

		transaction(() => {
			this.resetSearchQueries();
			this.isLoading = true;
		});

		changeLogsAPI.getAll({filter: this.prepareFilters(), fields: FIELDS})
			.then((res) => this.onLoadItemsSuccess(res))
			.catch((res) => this.onLoadItemsError(res));
	};

	@action
	protected onLoadItemsSuccess(res: any) {
		transaction(() => {
			this.isLoading = false;
			this.items = res.data.results.map((item) => this._prepareItem(item));
			this.count = res.data.count;
		});
	}

	private _prepareItem(item: IChangeLog) {
		if (metaDataStore.accountsById[item.account] && metaDataStore.accountsById[item.account].username) {
			item.account = metaDataStore.accountsById[item.account].username;
		}
		item.model_link = this._getLink(item);
		return item;
	}

	@action public setParam = (key: string, value: any) => {
		this.params[key] = value;
	};

	private get initialParams(): IChangeLogsParams {
		return {
			from: moment().subtract(1, "hour"),
			to: moment(),
			filters: [],
		};
	}

	@action
	private paramsFromSearch(search: string): void {
		if (!search) {
			return
		};
		const parsedSearch = URLQueryParams.parse(search);
		this.setChangelogTimeFrameFromQueryString(parsedSearch);
		this.setChangelogsFiltersFromQueryString(parsedSearch);
	}

	public generateChangelogLink = () => {
		const raw: any = {
			t: this.activePreset,
			from: this.params.from.unix(),
			to: this.params.to.unix(),
			f: this.params.filters,
		};
		const params = URLQueryParams.stringify(raw);

		MemoryClipboard.copy(`${window.location.origin}/change-logs?${params}`);

		notificationsStore.pushNotification({
			title: "Change Logs Link",
			text: "Copied to your clipboard",
			success: true,
			timeout: 5000
		});
	};

	@action
	private setChangelogTimeFrameFromQueryString(queryParams: any): void {
		if (queryParams.t) {
			const validPresetValues = ReportingTimePreset.map((preset) => preset.value);

			if (validPresetValues.indexOf(queryParams.t) > -1) {

				this.applyTimePreset(queryParams.t)

				const from = moment(queryParams.from, "X").utc(false);
				const to = moment(queryParams.to, "X").utc(false);

				if (from.isValid() && to.isValid() && from < to) {
					this.setParam("from", from);
					this.setParam("to", to);
				} else {
					this.applyTimePreset("now");
				}
			}
		}
	}

	@action
	private setChangelogsFiltersFromQueryString(queryParams: any): void {
		const filterTypes = Object.values(FilterTypes);
		const operators = Object.values(FilterOperators);
		if (queryParams.f && queryParams.f.length > 0) {
			queryParams.f.forEach((filter) => {
				if (filterTypes.includes(filter.type)) {
					if (!operators.includes(filter.operator)) {
						filter.operator = FilterOperators.isAnyOf;
					}
					this.params.filters.push(filter)
				}
			})
		}
	}

	@action public addFilter = () => {
		this.params.filters.push({
			type: ""
		});
	};

	@action public addFilterParamArray = (filter: IChangeLogFilter, key: string, value: string) => {
		if (!filter[key]) {
			filter[key] = observable.array([], {deep: false});
		}
		filter[key] = [...filter[key], value];
	};

	@action public removeFilterParamArray = (filter: IChangeLogFilter, key: string, value: string) => {
		filter[key] = filter[key].filter((row) => row !== value);
	};

	@action public setFilterParam = (filter: IChangeLogFilter, key: string, value: string) => {
		filter[key] = value;
	};

	@action public removeFilter = (idx) => {
		this.params.filters.splice(idx, 1);
	};

	private prepareFilters = () => {
		const filters = [];
		this.params.filters.forEach((filter) => {
			switch (filter.type) {
				case FilterTypes.Scope:
					filters.push({
						path: `scope ${filter.operator === FilterOperators.isNotAnyOf ? "!=" : "="}:value`,
						value: {value: filter.value}
					});
					break;
				case FilterTypes.User:
					filters.push({
						path: `auth->>'id' ${filter.operator === FilterOperators.isNotAnyOf ? "NOT IN" : "IN"} (:...value)`,
						value: {value: filter.value}
					});
					break;
				case FilterTypes.Account:
					filters.push({
						path: `account ${filter.operator === FilterOperators.isNotAnyOf ? "NOT IN" : "IN"} (:...value)`,
						value: {value: filter.value}
					});
					break;
				case FilterTypes.Action:
					filters.push({
						path: `action ${filter.operator === FilterOperators.isNotAnyOf ? "!=" : "="}:value`,
						value: {value: filter.value}
					});
					break;
				case FilterTypes.PlatformTags:
					filters.push({
						path: `model_id ${filter.operator === FilterOperators.isNotAnyOf ? "NOT IN" : "IN"} (:...value)`,
						value: {value: filter.value}
					});
					break;
				case FilterTypes.ModelId:
					filters.push({
						path: `model_id ${filter.operator === FilterOperators.isNotAnyOf ? "NOT IN" : "IN"} (:...value)`,
						value: {value: filter.value}
					});
					break;
				case FilterTypes.ModelName:
					filters.push({
						path: `model_name ${filter.operator === FilterOperators.isNotAnyOf ? "NOT IN" : "IN"} (:...value)`,
						value: {value: filter.value}
					});
					break;
			}
		});

		this.params.from && (filters.push({path: "date>=:from", value: {from: this.params.from.unix() * 1000}}));
		this.params.to && (filters.push({path: "date<=:to", value: {to: this.params.to.unix() * 1000}}));

		return filters;
	};

	@action public getPlatformTags = async () => {
		const filterHandler = reportingFiltersManager.getFilter(PlatformReportingFilterType.TagName, REPORT_VERTICAL_TYPE.PLATFORM);
		await filterHandler.initialize();
		this.setPlatformTags(filterHandler.items);
	};

	@action public setPlatformTags = (items) => {
		this.tagsByAccount = _.groupBy(items, "user");
		this.nameByIdAndScope = _.keyBy(items, (item) => "platform" + item._id);
	};

	@action public setAccount = (account: string) => {
		this.account = account;
	};

	@computed get platformTagsByAccount() {
		return this.tagsByAccount[this.account];
	}

	@action public clearAllFilters = () => {
		this.params.filters = [];
	};

	public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
		storageService.setColumnsWidth(`change_logs`, columnsWidth);
	};

	public getColumnsWidth = (): { [index: string]: number } => {
		return storageService.getColumnsWidth(`change_logs`);
	};

	public _getLink(item: IChangeLog): string {
		switch (`${item.scope}_${item.model_name}`) {
			case "platform_players":
				return `${PLATFORM_BASE_URL}/player/${item.model_id}/edit`;
			case "platform_scenarios":
				return `${PLATFORM_BASE_URL}/scenario/${item.model_id}/edit`;
			case "platform_tags":
				return `${PLATFORM_BASE_URL}/tag/${item.model_id}/edit`;
			case "platform_publishers":
				return `${PLATFORM_BASE_URL}/supply-partner/${item.model_id}/edit`;
			case "platform_demandpartners":
				return `${PLATFORM_BASE_URL}/demand-partner/${item.model_id}/edit`;
			case "widgets_widgets":
				return `${PLATFORM_BASE_URL}/widget_new/${item.model_id}/edit`;
			case "platform_users":
				return `${PLATFORM_BASE_URL}/account/${item.model_id}/edit`;
			case "platform_videos":
				return `${PLATFORM_BASE_URL}/cms-video/${item.model_id}/edit`;
			case "platform_crawlers":
				return `${PLATFORM_BASE_URL}/crawler/${item.model_id}/edit`;
			case "platform_domainlists":
				return `${PLATFORM_BASE_URL}/list/${item.model_id}/edit`;
			case "platform_widgetsettings":
				return `${PLATFORM_BASE_URL}/widget-settings/${item.model_id}/edit`;
			case "platform_networks":
				return `${PLATFORM_BASE_URL}/network/${item.model_id}/edit`;
			case "platform_contextualplaylists":
				return `${PLATFORM_BASE_URL}/contextual-playlist/${item.model_id}/edit`;
			case "platform_countrylists":
				return `${PLATFORM_BASE_URL}/country-list/${item.model_id}/edit`;
			case "platform_networksettings":
				return `${PLATFORM_BASE_URL}/network-settings`;
			case "platform_abtestpolicies":
				return `${PLATFORM_BASE_URL}/ab-test-policy/${item.model_id}/edit`;

			case "open_rtb_exchanges":
				return `${OPEN_RTB_BASE_URL}/exchange/${item.model_id}/edit`;
			case "open_rtb_connections":
				return `${OPEN_RTB_BASE_URL}/connection/${item.model_id}/edit`;
			default:
				return "";
		}
	}

	@action public applyTimePreset = (preset?: string) => {
		if (preset && preset !== this.activePreset) {
			this.activePreset = preset;
		}

		switch (this.activePreset) {
			case "now":
				this.setDate(1, 1);
				break;
			case "today":
				this.setDate(getCurrentHour(), -getCurrentHour() + 24);
				break;
			case "yesterday":
				this.setDate(getCurrentHour() + 24, -getCurrentHour());
				break;
			case "last7days":
				this.setDate(getPastDays(7), -getCurrentHour() + 24);
				break;
			case "weektodate":
				this.setDate(getCurrentWeek(), -getCurrentHour() + 24);
				break;
			case "lastmonth":
				this.setDate(getLastMonth(), -getCurrentMonth());
				break;
			case "monthtodate":
				this.setDate(getCurrentMonth(), -getCurrentHour() + 24);
				break;
		}
	};

	@action
	public setDate(from: number, to: number) {
		transaction(() => {
			this.params.from = moment().utc().minutes(0).second(0).subtract(from, "hour");
			this.params.to = moment().utc().minutes(0).second(0).add(to, "hour");
		});
	}
}
