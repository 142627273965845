import * as React from "react";
import * as PropTypes from "prop-types";
import * as theme from "styles/_baseForm.scss";
import {FormMode, normalizeEventValue, IReportingConstraint, IReportingFilter} from "@vidazoo/ui-framework";
import {Chips, LinkButton, HLine, Label} from "@vidazoo/ui";
import {ReportingFilter, ReportingConstraint} from "@vidazoo/ui-framework/lib/containers/reporting/components";
import {metaDataStore} from "../../../../../common/stores";
import {IScheduleReportStore} from "../../../stores/EditScheduleReportStore";
import {ICreateScheduleReportStore} from "../../../stores/CreateScheduleReportStore";

export interface IReportFormProps {
	mode: FormMode;
	store: IScheduleReportStore | ICreateScheduleReportStore;
}

export interface IReportFormState {

}

export default class ReportForm extends React.Component<IReportFormProps, IReportFormState> {

	public static defaultProps: Partial<IReportFormProps> = {};

	constructor(props, context) {
		super(props, context);
	}

	private onAddFilter = () => {
		const {store} = this.props;
		store.addFilter();
	};

	private onRemoveFilter = (filter: IReportingFilter) => {
		const {store} = this.props;
		store.removeFromArray("filters", filter.id, "id");
	};

	private onAddConstraint = () => {
		const {store} = this.props;
		store.addConstraint();
	};

	private onRemoveConstraint = (constraint: IReportingConstraint) => {
		const {store} = this.props;
		store.removeFromArray("constraints", constraint.id, "id");
	};

	private onDeleteDimension = (value: string) => {
		const {store} = this.props;
		store.removeFromArray("groups", value);
	};

	private onOrderDimensions = (dimensions: any[]) => {
		const {store} = this.props;
		store.updateParam("groups", dimensions);
	};

	private onAddDimension = (value: string, label: string) => {
		const {store} = this.props;
		store.addToArray("groups", {value, label});
	};

	private onChangeFilter = (filter: IReportingFilter, key: string, value: string) => {
		const {store} = this.props;
		store.setFilterParam(filter, key, value);
	};

	private onDeleteMetric = (value: string) => {
		const {store} = this.props;
		store.removeFromArray("fields", value);
	};

	private onOrderMetrics = (metrics: any[]) => {
		const {store} = this.props;
		store.updateParam("fields", metrics);
	};

	private onAddMetric = (value: string, label: string) => {
		const {store} = this.props;
		store.addToArray("fields", {value, label});
	};

	public render(): JSX.Element {
		const {store} = this.props;
		const {item} = store;

		return (
			<div className={theme.form}>
				<div className="flex justify-space-between align-center" style={{marginBottom: 30}}>
					<Label spacing={1.25} size={13} monserrat weight={600}>Dimensions</Label>
					<LinkButton type="button" iconName="icon-plus-small" deepPurple small uppercase spaced bold
								onClick={this.onAddFilter}>Add Filter</LinkButton>
				</div>
				<Chips
					fill
					deepPurple
					allowOrder
					required
					chips={item.groups}
					maxItems={5}
					leaveOpenAfterSelect
					onDelete={this.onDeleteDimension}
					onOrder={this.onOrderDimensions}
					onSelect={this.onAddDimension}
					dataSource={metaDataStore.metaDataByVertical[item.verticalType].groups}
					searchPlaceholder="Add Dimension"
					emptyPlaceholder="Add Dimension"
				/>
				{item.filters.map((filter, idx) => <ReportingFilter key={filter.id} index={idx} groups={metaDataStore.metaDataByVertical[item.verticalType].filters}
																	filter={filter} onChange={this.onChangeFilter}
																	onDelete={this.onRemoveFilter}
																	onAddValue={store.pushFilterValue}
																	onDeleteValue={store.removeFilterValue}/>)}
				<HLine space={3.2}/>
				<div className="flex justify-space-between align-center" style={{marginBottom: 30}}>
					<Label spacing={1.25} size={13} monserrat weight={600}>Metrics</Label>
					<LinkButton type="button" iconName="icon-plus-small" deepPurple small uppercase spaced bold
								onClick={this.onAddConstraint}>Add Constraint</LinkButton>
				</div>
				<Chips
					fill
					deepPurple
					allowOrder
					required
					chips={item.fields}
					maxItems={5}
					leaveOpenAfterSelect
					onDelete={this.onDeleteMetric}
					onOrder={this.onOrderMetrics}
					onSelect={this.onAddMetric}
					dataSource={metaDataStore.metaDataByVertical[item.verticalType].fields}
					searchPlaceholder="Add Metric"
					emptyPlaceholder="Add Metric"
				/>
				{item.constraints.map((constraint, idx) => <ReportingConstraint key={constraint.id} index={idx}
																				fields={metaDataStore.metaDataByVertical[item.verticalType].fields}
																				constraint={constraint}
																				onChange={store.setConstraintParam}
																				onDelete={this.onRemoveConstraint}/>)}
			</div>
		);
	}
}
