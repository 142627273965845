import BaseFilter from "./BaseFilter";
import reportingAPI from "../../api/reportingAPI";
import {REPORT_VERTICAL_TYPE} from "../../../../common/enums";
import {action} from "mobx";

export default class BaseNeededGroup extends BaseFilter {

	private promise: Promise<any>;
	private vertical: REPORT_VERTICAL_TYPE;
	private neededGroup: string;

	constructor(vertical: REPORT_VERTICAL_TYPE, neededGroup: string) {
		super();

		this.allowNew = false;
		this.labelKey = "name";
		this.valueKey = "_id";
		this.vertical = vertical;
		this.neededGroup = neededGroup;
	}

	@action public initialize(): Promise<any> {
		if (this.items && this.items.length > 0 ) {
			return this.promise;
		}

		if (this.isLoading) {
			return this.promise;
		}
		this.isLoading = true;
		return this.promise = reportingAPI.getNeededData(this.vertical, this.neededGroup)
			.then((res) => this.setData(res));
	}

	@action public setData(res) {
		this.items = res.data;
		this.isLoading = false;
	}
}
