import * as React from "react";
import * as theme from "./theme.scss";
import {observer} from "mobx-react";
import {FormMode, normalizeEventValue} from "@vidazoo/ui-framework";
import {Button, FormInput, FormSelect, Modal} from "@vidazoo/ui";
import EditDashboardStore from "../../stores/EditDashboardStore";
import CreateDashboardStore from "../../stores/CreateDashboardStore";
import ChartTypesSelect from "../../../chart/containers/chart/chartTypes/ChartTypesSelect";
import AddChartModal from "./addChartModal/AddChartModal";

export interface IDetailsFormProps {
	mode: FormMode;
	store: EditDashboardStore | CreateDashboardStore;
}

export interface IDetailsFormState {
	showSelectChartModal: boolean;
}

@observer
export default class DetailsForm extends React.Component<IDetailsFormProps, IDetailsFormState> {

	public static defaultProps: Partial<IDetailsFormProps> = {};

	constructor(props: IDetailsFormProps) {
		super(props);
		this.state = {showSelectChartModal: false};
	}

	private onChangeDetail = (e) => {
		const {store} = this.props;

		const value = normalizeEventValue(e);

		store.updateParam(e.target.name, value);
	};

	private onSelectChart = (value, label) => {
		const {store} = this.props;

		store.onAddLayout(value);
		this.toggleSelectChartModal();
	};

	private toggleSelectChartModal = () => {
		this.setState({showSelectChartModal: !this.state.showSelectChartModal});
	};

	public render(): JSX.Element {

		const {store} = this.props;
		const {name} = store.item;

		return (
			<>
				<div className={theme.header}>
					<FormInput onChange={this.onChangeDetail} className={theme.input} value={name} autoFocus name="name"
							   label="Name" required fill
							   hideValidationMessage/>
					<Button type="button" iconName="icon-plus-small" medium blue uppercase
							onClick={this.toggleSelectChartModal}>Add Chart</Button>
				</div>
				<AddChartModal store={store} isOpen={this.state.showSelectChartModal} toggleModal={this.toggleSelectChartModal}/>
			</>
		);
	}
}
