import {storageService} from "common/services";
import BaseUserStore from "./base/BaseUserStore";
import IUser from "common/interfaces/IUser";
import * as _ from "lodash";
import {NetworkFeatures} from "common/enums";
import {UserRole} from "@vidazoo/ui-framework";
import INetwork from "common/interfaces/INetwork";

export interface ICurrentUserStore extends IUser {
	initialize: (user: IUser) => void;
	isInRoles: (scope: string[]) => boolean;
	isAnyAdmin: () => boolean;
	isAdmin: () => boolean;
	isRootAdmin: () => boolean;
}

export default class CurrentUserStore extends BaseUserStore implements ICurrentUserStore {

	constructor() {
		super();
	}

	public initialize(user: IUser) {

		super.initialize(user);

		storageService.initialize(this._id);
	}

	public isBelongToUser(userId: string): boolean {
		return this._id === userId;
	}

	public setNetwork = (network: INetwork) => {
		this.network = network;
	};

	public isInRoles = (scope: string[]): boolean => {
		return (scope && scope.length > 0)
			? _.some(scope, (role) => _.includes(this.roles, role))
			: true;
	};

	public isDeveloper(): boolean {
		return this.isInRoles(["DEVELOPER"]);
	}

	public isAnyAdmin(): boolean {
		return this.isInRoles([UserRole.ADMIN, UserRole.ROOT_ADMIN]);
	}

	public isAdmin(): boolean {
		return this.isInRoles([UserRole.ADMIN]);
	}

	public isRootAdmin(): boolean {
		return this.isInRoles([UserRole.ROOT_ADMIN]);
	}

	public isSuperAdmin(): boolean {
		return this.isInRoles([UserRole.SUPER_ADMIN]);
	}

	public isPublisher(): boolean {
		return this.isInRoles([UserRole.PUBLISHER]);
	}

	public isFeatureAllowed = (features: NetworkFeatures[] | NetworkFeatures): boolean => {
		if (this.isRootAdmin() || !features) {
			return true;
		}
		if (this.network) {
			if (!(features instanceof Array)) {
				features = [features];
			}

			const allFeatures = this.network.features || [];
			return _.some(features, (feature) => _.includes(allFeatures, feature));
		}

		return false;
	};

	public isVidazooNetwork = () => {
		return this.isFeatureAllowed([NetworkFeatures.VIDAZOO]);
	};

	public isGalOrMaxim = () => {
		return ["5c975c1a5806dd0004525dd9", "5a8027834ece770004ecb671", "5aa018feb1c11c000453cec3", "5a564e9432017a5bfd44eb5e"].includes(this._id);
	};
}
