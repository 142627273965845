import * as React from "react";
import ChangeLogsStore from "../stores/ChangeLogsStore";

export interface IChangeLogsContext {

	store: ChangeLogsStore;

}

export default React.createContext<IChangeLogsContext>({
	store: null,
});
