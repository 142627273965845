import {BaseAPI} from "@vidazoo/ui-framework";

class ScheduleReportsAPI extends BaseAPI {
	public getAll(page: number = 1, page_size: number = 50, filter: any = {}, fields: any = {}, sort: any = []) {
		return this.request({
			method: "get",
			url: "/api/schedule_reports/",
			params: {page, page_size, filter, fields, sort}
		});
	}

	public getOne(id: string) {
		return this.request({
			method: "get",
			url: `/api/schedule_reports/${id}/`
		});
	}

	public create(data: any) {
		return this.request({
			method: "post",
			url: "/api/schedule_reports/",
			data
		});
	}

	public delete(id: string) {
		return this.request({
			method: "delete",
			url: `/api/schedule_reports/${id}/`
		});
	}

	public update(id: string, data: any) {
		return this.request({
			method: "patch",
			url: `/api/schedule_reports/${id}/`,
			data
		});
	}

	public active(id: string, isActive: boolean) {
		return this.request({
			method: "patch",
			url: `/api/schedule_reports/${id}/active/`,
			data: {isActive}
		});
	}

	public runNow(id: string, data: any) {
		return this.request({
			method: "post",
			url: `/api/schedule_reports/${id}/run_now`,
			data
		});
	}

	public getOneLog(id: string, path: any) {
		return this.request({
			method: "get",
			url: `/api/schedule_reports/${id}/log`,
			params: {path}
		});
	}

	public getAdminLogs() {
		return this.request({
			method: "get",
			url: `/api/schedule_reports/admin_logs`
		});
	}

	public getAdminAllReports() {
		return this.request({
			method: "get",
			url: `/api/schedule_reports/admin_all_reports`
		});
	}
}

export default new ScheduleReportsAPI(window.__BASE_API_URL__);
