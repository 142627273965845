import * as React from "react";
import {observer} from "mobx-react";
import {Layout} from "react-grid-layout";
import {Label, Icon, ProgressBar, IconButton} from "@vidazoo/ui";
import * as theme from "./theme.scss";
import {appUrlsService} from "../../../../../common/services";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {ChartType} from "../../../../../common/enums";
import EditDashboardStore from "../../../stores/EditDashboardStore";
import CreateDashboardStore from "../../../stores/CreateDashboardStore";

export interface IDataTableProps extends RouteComponentProps<any> {
	layout: Layout;
	editMode: boolean;
	store: EditDashboardStore | CreateDashboardStore;
}

export interface IDataTableState {
}

// @ts-ignore
@withRouter
@observer
export default class ChartHeader extends React.Component<IDataTableProps, IDataTableState> {

	public static defaultProps: Partial<IDataTableProps> = {};

	constructor(props: IDataTableProps) {
		super(props);
	}

	private onDeleteClick = () => {
		const {layout} = this.props;
		this.props.store.removeChart(layout.i);
	};

	private onEditClick = () => {
		const {history, layout} = this.props;
		history.push(appUrlsService.editChart(layout.i));
	};

	private renderDownloadCsvButton = () => {
		const chart = this.props.store.chartsById[this.props.layout.i];
		const report = this.props.store.reportByChart(this.props.layout.i);
		if (!report) {
			return null;
		}
		const {editMode} = this.props;
		return (chart.type === ChartType.DATA_TABLE && !editMode) ? (
			<IconButton iconName="icon-download" blue onClick={this.downloadCsvHandler}/>) : null;
	};

	private downloadCsvHandler = () => {
		this.props.store.downloadCSV(this.props.layout.i);
	};

	public render(): JSX.Element {
		const chart = this.props.store.chartsById[this.props.layout.i];
		if (!chart) {
			return null;
		}

		return (
			<div className={theme.container}>
				<Label size={13} spacing={1.25}>{chart.name}</Label>
				<div className={theme.actions}>
					{this.props.editMode ? (
						<>
							<div className={theme.action} onClick={this.onDeleteClick}>
								<Icon size={2} iconName="icon-delete"/>
							</div>
							<div className={theme.action} onClick={this.onEditClick}>
								<Icon size={2} iconName="icon-edit"/>
							</div>
						</>
					) : null}
				</div>
				{this.renderDownloadCsvButton()}
			</div>
		);
	}
}
