import BaseFilter from "../BaseFilter";
import countries from "@vidazoo/ui-framework/lib/common/countries";
import {PlatformReportingFilterType} from "../ReportingFilterType";

export default class CountryFilter extends BaseFilter {

	public static type = PlatformReportingFilterType.Country;

	constructor() {
		super();

		this.items = countries;
		this.labelKey = "name";
		this.valueKey = "code";
		this.allowNew = false;
	}
}
