import {action, observable, transaction} from "mobx";
import EditChartStore from "./EditChartStore";
import {dashboardsStore} from "../../../common/stores";
import * as Bluebird from "bluebird";

export default class EditChartsManagerStore {

	@observable public chartStores: { [index: string]: EditChartStore };

	@action
	public async initChartStores() {
		if (!this.chartStores) {
			this.chartStores = {};
		}
		await Bluebird.map(dashboardsStore.defaultCharts.slice(0, 2), async (chart) => {
			await this.initChartStore(chart._id);
		}, {concurrency: 1});
	}

	@action
	public async initChartStore(chartId: string): Promise<EditChartStore> {
		if (!this.chartStores) {
			this.chartStores = {};
		}
		if (!this.chartStores[chartId]) {
			const store = new EditChartStore();
			await store.getItem(chartId);
			this.setStore(store, chartId);
		}
		return this.chartStores[chartId];
	}

	@action
	public setStore(store: EditChartStore, chartId: string) {
		if (!this.chartStores) {
			this.chartStores = {};
		}
		this.chartStores[chartId] = store;
	}

	public getStore(chartId: string): EditChartStore {
		return this.chartStores[chartId];
	}
}
