import { BaseAPI } from "@vidazoo/ui-framework";

class AccountsAPI extends BaseAPI {
	public getAll({
		page = null,
		pageSize = null,
		filter = {},
		fields = {},
		sort = {}
	}) {
		return this.request({
			method: "get",
			url: "/api/accounts/",
			params: { page, pageSize, filter, fields, sort }
		});
	}

	public getOne(id: string) {
		return this.request({
			method: "get",
			url: `/api/accounts/${id}/`
		});
	}

	public create(data: any) {
		return this.request({
			method: "post",
			url: "/api/accounts/",
			data
		});
	}

	public delete(id: string) {
		return this.request({
			method: "delete",
			url: `/api/accounts/${id}/`
		});
	}

	public update(id: string, data: any) {
		return this.request({
			method: "patch",
			url: `/api/accounts/${id}/`,
			data
		});
	}

	public active(id: string, isActive: boolean) {
		return this.request({
			method: "patch",
			url: `/api/accounts/${id}/active/`,
			data: { isActive }
		});
	}

	public me(timeout?: number) {
		return this.request({
			method: "get",
			url: `/api/accounts/me`,
			timeout
		});
	}

	public getEnterpriseAccountFilter() {
		return this.request({
			method: "get",
			url: "/api/accounts/enterprise_reporting_filter/",
		});
	}
}

export default new AccountsAPI(window.__BASE_API_URL__);
