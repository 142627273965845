import * as React from "react";
import {observer} from "mobx-react";
import IDashboard from "../../interfaces/IDashboard";
import * as theme from "./theme.scss";
import DashboardRow from "./DashboardRow";

export interface IDashboardsGridProps {
	items: IDashboard[];
}

export interface IDashboardsGridState {
}

@observer
export default class DashboardsGrid extends React.Component<IDashboardsGridProps, IDashboardsGridState> {

	public static defaultProps: Partial<IDashboardsGridProps> = {};

	constructor(props, context) {
		super(props, context);
	}

	public render(): JSX.Element {
		return (
			<div className={theme.grid}>
				{this.props.items.map((item) => <DashboardRow item={item}/>)}
			</div>
		);
	}
}
