import * as React from "react";
import * as theme from "styles/_baseForm.scss";
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {RouteComponentProps} from "react-router";
import {BaseContainer, FormMode} from "@vidazoo/ui-framework";
import {
	Label,
	Form,
	Button,
	Loader,
	PageMessage,
	Modal,
	ConfirmPopMessage,
	SliderPanel,
} from "@vidazoo/ui";
import {appUrlsService} from "common/services";
import {AddItemButton} from "common/components";
import EditDashboardStore from "../../stores/EditDashboardStore";
import DetailsForm from "./DetailsForm";
import ChartsForm from "./ChartsForm";
import {cloudStore, currentUserStore, dashboardsStore, metaDataStore} from "../../../../common/stores";
import DashboardsList from "../dashboards/DashboardsList";
import * as selfTheme from "./theme.scss";
import {IViewChartProps} from "../../../chart/containers/chart/ViewChart";

export interface IEditDashboardProps extends RouteComponentProps<{ id: string }> {

}

export interface IEditDashboardState {
	showDeleteMessage: boolean;
	editMode: boolean;
}

class EditDashboard extends BaseContainer<IEditDashboardProps, IEditDashboardState> {

	public static defaultProps: Partial<IEditDashboardProps> = {};

	private store: EditDashboardStore;

	constructor(props, context) {
		super(props, context);

		this.store = new EditDashboardStore();

		this.setInitialState({showDeleteMessage: false, editMode: false});
	}

	public componentWillMount() {
		const {match} = this.props;
		this.store.getItem(match.params.id);
	}

	public componentDidUpdate(prevProps: IViewChartProps) {
		const {match} = this.props;

		if (match.params.id !== prevProps.match.params.id) {
			this.store.getItem(match.params.id);
		}

		this.store.getReportsForCharts();
	}

	private onSubmit = () => {
		this.store.submit();
	};

	private renderLoader() {
		return (
			<div className={theme.message}>
				<Loader size={1} gray className={theme.loader}/>
				<PageMessage
					small
					title="Loading Dashboard"
					description="Please wait..."
				/>
			</div>
		);
	}

	private onCancelClick = () => {
		const {history} = this.props;
		history.push(appUrlsService.dashboards());
	};

	private onDuplicateClick = () => {
		this.store.duplicate();
	};

	private hideDeleteMessage = () => {
		this.setState({
			showDeleteMessage: false
		});
	};

	private showDeleteMessage = () => {
		this.setState({
			showDeleteMessage: true
		});
	};

	private delete = () => {
		this.hideDeleteMessage();

		this.store.delete();
	};

	private onAddItem = () => {
		const {history} = this.props;
		history.push(appUrlsService.createDashboard());
	};

	private toggleEditMode = () => {
		this.setState({editMode: !this.state.editMode});
	};

	private renderDeleteMessage(): JSX.Element {
		const {showDeleteMessage} = this.state;

		return (
			<Modal external exitOnClickOutside onClose={this.hideDeleteMessage} isOpen={showDeleteMessage}>
				<ConfirmPopMessage
					red
					title="Delete Dashboard?"
					text={<span>Are you sure you want to delete <Label weight={600}
																	   block={false}>{this.store.item.name}</Label>?</span>}
					cancelText="No, Cancel"
					confirmText="Yes, Delete"
					onCancelClick={this.hideDeleteMessage}
					onConfirmClick={this.delete}
				/>
			</Modal>
		);
	}

	private renderForm() {
		const {name} = this.store.item;
		const isBelongToUser = currentUserStore.isBelongToUser(this.store.item.user as any);

		return (
			<Form className={theme.form} onSubmit={this.onSubmit}>
				{(window as any).__device === "desktop" ? (
					<div className={theme.header}>
						{isBelongToUser ? (
							<Button className={selfTheme.btnEditMode} type="button" iconName="icon-edit" medium blue
									uppercase onClick={this.toggleEditMode}>
								{`${this.state.editMode ? "Close Edit" : "Edit"} Dashboard ${name}`}
							</Button>
						) : null}
					</div>
				) : null}
				{this.state.editMode ? (<DetailsForm store={this.store} mode={FormMode.Edit}/>) : null}
				{(!this.store.item?.layouts?.lg || this.store.item?.layouts?.lg.length === 0) ? (
					<Label className={theme.label} uppercase={false} size={13}>No charts yet..</Label>
				) : (
					<ChartsForm store={this.store} editMode={this.state.editMode}/>
				)}

				{(window as any).__device === "desktop" && this.state.editMode ? (
					<SliderPanel>
						<div className={theme.actions}>
							<Button type="button" medium gray uppercase onClick={this.onCancelClick}>Cancel</Button>
							<Button type="button" medium red uppercase onClick={this.showDeleteMessage}>Delete</Button>
							<AddItemButton label="Duplicate" medium gray60 uppercase onClick={this.onDuplicateClick}/>
							<Button type="submit" medium blue uppercase>Update</Button>
						</div>
					</SliderPanel>
				) : null}
			</Form>
		);
	}

	private renderError() {
		return (
			<div className={theme.message}>
				<PageMessage
					small
					title="Failed to load dashboard"
					description="We had an error loading the requested dashboard."
					iconName="icon-rejected-status"
				/>
			</div>
		);
	}

	public render(): JSX.Element {
		return (
			<div>
				{!dashboardsStore.isLoading && !this.state.editMode ? (
					<DashboardsList onAddItem={this.onAddItem}/>
				) : null}
				{this.store.isLoading ? this.renderLoader() : null}
				{this.store.loadError ? this.renderError() : null}
				{!this.store.isLoading && !this.store.loadError ? this.renderForm() : null}
				{this.renderDeleteMessage()}
			</div>
		);
	}
}

export default withRouter<any, any>(observer(EditDashboard));
