import {action, transaction} from "mobx";
import {BaseListStore} from "@vidazoo/ui-framework";
import {currentUserStore, notificationsStore} from "common/stores";
import INetwork from "common/interfaces/INetwork";
import IUser from "common/interfaces/IUser";
import IChart from "../interfaces/IChart";
import chartsAPI from "../api/chartsAPI";

const LIST_SEARCHABLE_FIELDS = ["name", "type"];
const LIST_FIELDS = {name: 1, type: 1, created: 1, isActive: 1};

export default class ChartsStore extends BaseListStore<IChart, IUser, INetwork> {

	constructor() {
		super(notificationsStore, LIST_SEARCHABLE_FIELDS, "charts", "name");
	}

	@action
	public reset() {
		transaction(() => {
			super.reset();
		});
	}

	@action public getItems = () => {
		if (this.isLoading) {
			return;
		}

		transaction(() => {
			this.isLoading = true;
			this.selectedValues = [];
			this.resetSearchQueries();
			this.setPromptDeleteItem(null);
		});

		const sort = {
			[this.sortBy]: this.sortDir
		};

		chartsAPI.getAll({fields: LIST_FIELDS, filter: {user: currentUserStore._id}})
			.then((res) => this.onLoadItemsSuccess(res))
			.catch((res) => this.onLoadItemsError(res));
	}

	@action public toggleActiveState = (item: IChart) => {
		item.isActive = !item.isActive;

		chartsAPI.active(item._id, item.isActive)
			.then(() => this.onUpdateStateSuccess())
			.catch(() => this.onUpdateStateError(item));
	}

	@action public deleteItem = () => {
		chartsAPI.delete(this.promptDeleteItem._id)
			.then(() => this.onDeleteItemSuccess(this.promptDeleteItem._id))
			.catch(() => this.onDeleteItemError());
	}

	protected deleteSelectedValues() {
		return Promise.all(this.selectedValues.map((x) => chartsAPI.delete(x)));
	}

	protected setActiveSelectedValues(state: boolean) {
		return Promise.all(this.selectedValues.map((x) => chartsAPI.active(x, state)));
	}
}
