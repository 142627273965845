import { BaseAPI } from "@vidazoo/ui-framework";
import { IGetAll } from "../../../common/interfaces/IGetAll";

class ChartsAPI extends BaseAPI {
	public getAll({ page = 1, pageSize = 100, filter = {}, fields = {}, sort = {} }: IGetAll) {
		return this.request({
			method: "get",
			url: "/api/charts/",
			params: { page, pageSize, filter, fields, sort }
		});
	}

	public getOne(id: string) {
		return this.request({
			method: "get",
			url: `/api/charts/${id}/`
		});
	}

	public create(data: any) {
		return this.request({
			method: "post",
			url: "/api/charts/",
			data
		});
	}

	public delete(id: string) {
		return this.request({
			method: "delete",
			url: `/api/charts/${id}/`
		});
	}

	public update(id: string, data: any) {
		return this.request({
			method: "patch",
			url: `/api/charts/${id}/`,
			data
		});
	}

	public active(id: string, isActive: boolean) {
		return this.request({
			method: "patch",
			url: `/api/charts/${id}/active/`,
			data: { isActive }
		});
	}
}

export default new ChartsAPI(window.__BASE_API_URL__);
