import * as React from "react";
import * as PropTypes from "prop-types";
import {IDictionary} from "@vidazoo/ui-framework";
import {
	SortDirection,
	Density,
	IDataGridHeader,
	DataGridRow,
	DataGridHeaderColumn,
	DataGrid
} from "@vidazoo/ui";
import * as theme from "../../../styles/theme.scss";
import {IScheduleReport} from "../../../interfaces/IScheduleReport";
import {ScheduleReportRow} from "..";

const headers: IDataGridHeader[] = [{
	label: "Name",
	sortBy: "name",
	searchKey: "name"
}, {
	label: "Schedule",
	sortBy: "schedule",
	searchKey: "schedule"
}, {
	label: "Date Range",
	sortBy: "dateRange",
	searchKey: "dateRange",
}, {
	label: "Last Schedule",
	sortBy: "lastSchedule",
	searchKey: "lastSchedule",
}, {
	label: "Next Schedule",
	sortBy: "nextSchedule",
	searchKey: "nextSchedule"
}, {
	label: "Created",
	sortBy: "created",
	searchKey: "created"
}, {
	label: "Active",
	sortBy: "isActive",
	searchKey: "isActive"
}, {
	label: "Actions",
}];

export interface IListProps {
	items?: IScheduleReport[];
	sortBy: string;
	onSort: (sortBy: string) => void;
	sortDirection: SortDirection;
	searchQueries: IDictionary<string>;
	onSearch: (searchKey: string, value: string) => void;
	onDelete: (item: IScheduleReport) => void;
	onActiveChange: (item: IScheduleReport) => void;
	onEdit: (item: IScheduleReport) => void;
	density: Density;
	setColumnsWidth: (columnsWidth: { [index: string]: number }) => void;
	getColumnsWidth: () => { [index: string]: number };
}

export interface IListState {

}

export default class List extends React.Component<IListProps, IListState> {

	constructor(props, context) {
		super(props, context);
	}

	private renderHeaderAndFooter: any = (scrollTop, scrollLeft, columnsWidth, setFreezeColumn, freezeColumnIndex) => {
		const {
			sortDirection,
			onSearch,
			sortBy,
			onSort,
			searchQueries,
		} = this.props;

		return (
			<DataGridRow columnsWidth={columnsWidth}>
				{headers.map((header: IDataGridHeader) => {
					const props = {
						blue: true,
						label: header.label,
						sortable: !!header.sortBy,
						sortBy: header.sortBy,
						sortActive: sortBy === header.sortBy,
						searchKey: header.searchKey,
						searchable: !!header.searchKey,
						searchQuery: searchQueries[header.searchKey || ""],
						freezeColumnIndex,
						columnsWidth,
						setFreezeColumn,
						sortDirection,
						onSort,
						onSearch,
					};
					return (
						<DataGridHeaderColumn {...props}/>
					);
				})}
			</DataGridRow>
		);
	};

	private onColumnResize = (columnsWidth) => {
		this.props.setColumnsWidth(columnsWidth);
	};

	private renderRow: any = (index, viewportIndex, columnsWidth, freezeColumnIndex) => {
		const {
			items,
			onActiveChange,
			onEdit,
			onDelete,
			sortBy
		} = this.props;

		const item = items[index];
		return (
			<ScheduleReportRow
				key={item._id}
				item={item}
				sortBy={sortBy}
				onActiveChange={onActiveChange}
				onDelete={onDelete}
				columnsWidth={columnsWidth}
				index={index}
				freezeColumnIndex={freezeColumnIndex}
				onEdit={onEdit}
			/>
		);
	};

	public render() {
		const {items, getColumnsWidth} = this.props;
		const columnsWidth = getColumnsWidth();

		return (
			<DataGrid
				onColumnResize={this.onColumnResize}
				className={theme.list}
				extraData={items}
				headersCount={headers.length}
				itemsCount={items.length}
				renderRow={this.renderRow}
				rowHeight={40}
				columnsWidth={columnsWidth}
				renderHeader={this.renderHeaderAndFooter}/>
		);
	}
}
