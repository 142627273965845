import * as React from "react";
import clsx from "clsx";
import * as theme from "./theme.scss";
import {Tooltip, Label} from "@vidazoo/ui";
import {isDefined} from "@vidazoo/ui/lib/components/utils";
import {extractReportingFieldValue, IReportSection, ReportFieldValueType} from "@vidazoo/ui-framework";
import {QuickReportAction} from "@vidazoo/ui-framework/lib/containers/reporting/components";

export interface IReportFieldValueCellProps {
	field: IReportSection;
	enableQuickConstraint: boolean;
	onQuickConstraint?: (item: IReportSection) => void;
}

export interface IReportFieldValueCellState {

}

export default React.memo(({field, enableQuickConstraint, onQuickConstraint}: IReportFieldValueCellProps): JSX.Element => {

	const displayValue = extractReportingFieldValue(field, "displayValue", ReportFieldValueType.ORIGINAL);

	let deltaPct, tooltip;

	if (field && field.value && field.value.length) {

		const deltaPctValue = parseInt(extractReportingFieldValue(field, "value", ReportFieldValueType.DELTA_PCT) * 100 as any, 10)
			, deltaValue = extractReportingFieldValue(field, "displayValue", ReportFieldValueType.DELTA)
			, value = extractReportingFieldValue(field, "displayValue", ReportFieldValueType.ORIGINAL)
			, compared = extractReportingFieldValue(field, "displayValue", ReportFieldValueType.COMPARE)
			, sign = deltaPctValue > 0 ? "+" : "";

		deltaPct = <span className={clsx(theme.trend, {
			[theme.up]: deltaPctValue > 0,
			[theme.down]: deltaPctValue < 0
		})}>({sign}{deltaPctValue}%)</span>;

		tooltip = (
			<div className={theme.popover}>
				<div className={theme.grid}>
					<div className={theme.item}>
						<Label weight="bold" className={theme.title} size={10} uppercase>Current</Label>
						<div className={theme.value}>{value}</div>
					</div>
					<div className={theme.item}>
						<Label weight="bold" className={theme.title} size={10} uppercase>Compared To</Label>
						<div className={theme.value}>{compared}</div>
					</div>
					<div className={theme.item}>
						<Label weight="bold" className={theme.title} size={10} uppercase>Change</Label>
						<div className={theme.value}>{deltaValue}</div>
					</div>
					<div className={theme.item}>
						<Label weight="bold" className={theme.title} size={10} uppercase>Change (%)</Label>
						<div className={theme.value}>{deltaPct}</div>
					</div>
				</div>
			</div>
		);
	}

	const cellToRender = (enableQuickConstraint && onQuickConstraint)
		? <QuickReportAction iconName="icon-filter" tooltipTitle="Add Constraint" item={field}
							 onClick={onQuickConstraint}>{displayValue} {deltaPct}</QuickReportAction>
		: `${displayValue} ${isDefined(deltaPct) ? deltaPct : ""}`;

	return tooltip
		? <Tooltip title={tooltip}><span>{cellToRender}</span></Tooltip>
		: <span>{cellToRender}</span>;
});
