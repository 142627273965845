import {action, observable, runInAction, transaction} from "mobx";
import * as _ from "lodash";
import {currentUserStore, metaDataStore, navigationStore} from "common/stores";
import {appUrlsService, socketService, storageService} from "common/services";
import {fastClone} from "@vidazoo/ui-framework";
import duplicateEntityManager from "common/managers/duplicateEntityManager";
import {ChartType, Entity} from "common/enums";
import BaseChartStore from "./BaseChartStore";
import chartsAPI from "../api/chartsAPI";
import reportingService from "../../reporting/services/reportingService";
import columnsWidthAPI from "../../../common/apis/columnsWidthAPI";

export default class EditChartStore extends BaseChartStore {

	@action
	public async getItem(id: string) {
		if (this.isLoading || (this.item && this.item._id)) {
			return;
		}
		runInAction(() => {
			this.isLoading = true;
			this.loadError = false;
		});

		try {
			const item = await chartsAPI.getOne(id);
			this.setItem(item.data);

		} catch (err) {
			this.onSubmitFailed(err);
		}
	}

	@action
	public setItem(item: any): void {
		super.setItem(item);
		this.initializeColumnsWidth();
		this._setFilters();
		this.getReport();

		if (this.item.type === ChartType.DATA_TABLE) {
			this.setColumnsWidthDebounce = _.debounce(() => {
				columnsWidthAPI.setColumnsWidth(`chart_${this.item._id}`, this.columnsWidth);
			}, 1000);
		}
	}

	@action
	private _setFilters() {
		this.item.reportParams.filters.forEach((filter) => {
			this.setFilterKey(filter, filter.key, filter.values);
		});
	}

	@action
	public submit() {
		const item = fastClone(this.item);
		item.preparedReportParams = this.reportingService.getReportParamsForChart(item.reportParams);

		chartsAPI.update(this.item._id, item)
			.then(() => this.onSubmitSuccess())
			.catch((err) => this.onSubmitFailed(err));
	}

	@action
	public delete() {
		chartsAPI.delete(this.item._id)
			.then(() => this.onSubmitSuccess())
			.then(() => navigationStore.push(appUrlsService.charts()))
			.catch((err) => this.onSubmitFailed(err));
	}

	@action
	public duplicate() {
		const item = fastClone(this.item);

		_.unset(item, "_id");

		item.name = "";

		duplicateEntityManager.setEntityDuplication(Entity.Chart, item);

		navigationStore.push(appUrlsService.createChart());
	}

	@action public getReport = () => {

		if (this.chartReportByTimePreset[metaDataStore.timePreset] && (this.chartReportByTimePreset[metaDataStore.timePreset].isLoading || this.chartReportByTimePreset[metaDataStore.timePreset].results)) {
			return;
		}

		if (!this.item || !this.item._id) {
			return;
		}

		const reportingParams = {
			filters: [],
			constraints: []
		};

		socketService.startListenToReportByChart(currentUserStore._id, this.item._id, metaDataStore.timePreset, (data) => this.setReportOnChart(data, this.item._id));
		this.chartReportByTimePreset[metaDataStore.timePreset] = {results: [], isLoading: true};
		reportingService.getReportByChart(this.item._id, metaDataStore.timePreset, this.timezone, reportingParams)
			.then((res) => this.setReportOnChart(res, this.item._id));
	};

	@action private setReportOnChart = (res, chartId) => {
		if (res && res.results && res.timePreset && this.chartReportByTimePreset[res.timePreset]) {

			this.chartReportByTimePreset[res.timePreset].results = res.results;
			this.chartReportByTimePreset[res.timePreset].isLoading = false;

			socketService.removeListenToReportByChart(currentUserStore._id, chartId, res.timePreset);
		}
	};

	@action
	public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
		this.columnsWidth = columnsWidth;
		this.setColumnsWidthDebounce();
	};

	@action
	public async initializeColumnsWidth() {
		if (this.item.type === ChartType.DATA_TABLE) {
			const columnsWidth = await columnsWidthAPI.getColumnsWidth(`chart_${this.item._id}`);
			if (!columnsWidth || !columnsWidth.data || _.isEmpty(columnsWidth.data)) {
				columnsWidth.data = {
					0: 550,
					1: 150,
					2: 150,
					3: 150,
					4: 150,
					5: 150,
					6: 150,
					7: 150,
					8: 150,
					9: 150,
					10: 150,
					11: 150,
					12: 150,
					13: 150,
					14: 150,
					15: 150,
					16: 150,
					17: 150,
					18: 150
				};
			}
			this.setColumnsWidthInitialize(columnsWidth.data);
		}
	}

	@action
	private setColumnsWidthInitialize(columnsWidth) {
		this.columnsWidth = columnsWidth;
	}

	@action
	public refresh() {
		this.chartReportByTimePreset[metaDataStore.timePreset].isLoading = true;
		setTimeout(() => {
			runInAction(() => {
				this.chartReportByTimePreset[metaDataStore.timePreset].isLoading = false;
			});
		}, 10);
	}
}
