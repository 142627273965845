import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";

export default class PublisherIdFilter extends BaseFilter {

	public static type = PlatformReportingFilterType.PublisherId;

	constructor() {
		super();
	}
}
