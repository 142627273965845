import {observable, action, transaction, toJS} from "mobx";
import BaseItemStore from "@vidazoo/ui-framework/lib/stores/BaseItemStore";
import {currentUserStore, editChartsManagerStore, metaDataStore, notificationsStore} from "common/stores";
import IDashboard from "../interfaces/IDashboard";
import {Layout} from "react-grid-layout";
import {DayField, guid, IDictionary, URLQueryParams} from "@vidazoo/ui-framework";
import IChart from "../../chart/interfaces/IChart";
import chartsAPI from "../../chart/api/chartsAPI";
import * as _ from "lodash";
import reportingService from "../../reporting/services/reportingService";
import * as moment from "moment";
import {exportToCsvService, socketService, storageService} from "../../../common/services";
import reportingFiltersManager from "../../reporting/stores/filters/reportingFiltersManager";
import {ChartHeights, ChartTypesLabels, REPORT_VERTICAL_TYPE} from "../../../common/enums";
import {IReportingFilterByVertical} from "../interfaces/IReportingFilterByVertical";

export default abstract class BaseDashboardStore extends BaseItemStore<IDashboard> {

	@observable public charts: IChart[];
	@observable public chartsById: IDictionary<IChart>;
	@observable public isLoadingCharts: boolean;
	@observable public dayField: DayField;
	@observable public timezone: string;
	@observable public from: number;
	@observable public to: number;
	@observable public reportParams: { filters: any[], constraints: any[] };

	constructor(fromDuplicate?: IDashboard) {
		super(notificationsStore, "dashboard");

		this.reset();

		if (fromDuplicate) {
			this.setItem(fromDuplicate);
		}
	}

	@action
	public reset() {
		transaction(() => {
			super.reset();
			this.dayField = DayField.DATE;
			this.timezone = storageService.getGlobalTimezone() || "Etc/GMT+0";

			this.isLoadingCharts = false;
			this.charts = [];
			this.chartsById = {};
			this.reportParams = {
				filters: [],
				constraints: [],
			};
			this.item = {
				...this.item,
				name: "",
				description: "",
				layouts: {lg: []},
			};
		});

		this.setInitialParams();

	}

	@action
	private setInitialParams() {

		const query = location.hash;

		if (query) {
			try {
				const queryParams = URLQueryParams.parse(query);
				this.setReportFiltersFromQueryString(queryParams);

			} catch (e) {
				notificationsStore.pushErrorNotification({
					title: "Oops!",
					text: "Failed to parse query params",
					timeout: 5000
				});
			}
		}
	}

	@action public removeChart = (chartId: string) => {
		const idx = this.item.layouts.lg.findIndex((layout) => layout.i === chartId);
		if (idx > -1) {
			this.item.layouts.lg.splice(idx, 1);
		}
	};

	@action public onAddLayout = async (chartId: string) => {
		const chartType = this.chartsById[chartId].type;
		this.item.layouts.lg.push({i: chartId, x: 0, y: Infinity, w: 12, h: ChartHeights[chartType]});
		const store = await editChartsManagerStore.initChartStore(chartId);
		store.getReport();
	};

	@action public onLayoutChange = (layouts: Layout) => {
		this.item.layouts.lg = layouts;
	};

	@action
	public getAllCharts = () => {
		this.isLoadingCharts = true;
		chartsAPI.getAll({pageSize: 5000})
			.then((res) => this.setCharts(res))
			.catch((err) => this.onLoadFailed(err));
	};

	@action
	private setCharts(res) {
		this.isLoadingCharts = false;
		this.charts = res.data.results;
		this.chartsById = _.keyBy(res.data.results, "_id");
		this.charts = this.charts.map((chart) => {
			chart.label = `${chart.name} (${ChartTypesLabels[chart.type]})`;
			return chart;
		});
		this.getReportsForCharts();
	}

	public getReportsForCharts = async () => {
		for (const layout of this.item.layouts.lg) {
			const storeByChart = await editChartsManagerStore.initChartStore(layout.i);
			storeByChart.getReport();
		}
	};

	public reportByChart = (chartId: string) => {
		const storeByChart = editChartsManagerStore.getStore(chartId);
		if (storeByChart) {
			return storeByChart.chartReportByTimePreset[metaDataStore.timePreset];
		}
	};

	@action public addFilter = (): IReportingFilterByVertical => {
		const filter = observable<IReportingFilterByVertical>({
			id: guid(),
			verticalType: REPORT_VERTICAL_TYPE.PLATFORM,
			key: "",
			values: [],
			filterList: [],
			isLoading: false,
			filterValueKey: "",
			filterLabelKey: "",
			allowNew: false,
			exclude: false,
			operator: "",
		});

		this.reportParams.filters.push(filter);

		this.addReportParamsToUrl();

		return filter;
	};

	@action public pushFilterValue = (filter: IReportingFilterByVertical, value: string, label: string) => {
		let item: any = value;

		if (filter.filterLabelKey && filter.filterValueKey) {
			item = {
				[filter.filterLabelKey]: label,
				[filter.filterValueKey]: value
			};
		}

		filter.values = filter.values.concat(item);

		this.addReportParamsToUrl();
	};

	@action public removeFilterValue = (filter: IReportingFilterByVertical, value: string) => {
		filter.values = filter.filterValueKey
			? _.filter(filter.values, (item) => item[filter.filterValueKey] !== value)
			: _.filter(filter.values, (item) => item !== value);

		this.addReportParamsToUrl();
	};

	@action public setFilterParam = (filter: IReportingFilterByVertical, key: string, value: any) => {
		if (key === "key") {
			this.setFilterKey(filter, value);
			return;
		}
		if (key === "verticalType" && filter.verticalType !== value) {
			this.resetFilter(filter);
		}

		filter[key] = value;

		this.addReportParamsToUrl();
	};

	@action public resetFilter = (filter: IReportingFilterByVertical) => {
		filter.key = "";
		filter.values = [];
		filter.key = "";
		filter.operator = "";
	};

	@action public setFilterKey = async (filter: IReportingFilterByVertical, key: string): Promise<any> => {
		const filterHandler = reportingFiltersManager.getFilter(key, filter.verticalType);

		filter.key = key;
		filter.filterLabelKey = filterHandler.labelKey;
		filter.filterValueKey = filterHandler.valueKey;
		filter.allowNew = filterHandler.allowNew;
		filter.isLoading = filterHandler.isLoading;
		filter.values = [];

		const filterAfterInit = filterHandler.initialize().then(action(() => {
			filter.filterList = filterHandler.items;
			filter.isLoading = filterHandler.isLoading;
		}));

		this.addReportParamsToUrl();

		return filterAfterInit;
	};

	@action public removeFromReportParams = (key: string, value: any, prop: string) => {
		this.reportParams[key] = this.reportParams[key].filter((item) => (prop ? item[prop] : item) !== value);
	};

	@action public removeAllReportParams = (key: string) => {
		this.reportParams[key] = [];
	};

	private addReportParamsToUrl() {
		const raw = {
			f: this.flattenFiltersForQuery()
		};
		location.hash = `&${URLQueryParams.stringify(raw)}`;
	}

	private flattenFiltersForQuery(): any {
		return _(this.reportParams.filters).map((filter) => {

			return {
				key: filter.key,
				verticalType: filter.verticalType,
				operator: filter.operator,
				values: _.map<any, any>(filter.values, (value) => value[filter.filterLabelKey] || value)
			};
		}).compact().value();
	}

	@action
	private async setReportFiltersFromQueryString(queryParams: any): Promise<boolean> {
		let isSet = false;

		if (queryParams.f && queryParams.f.length > 0) {

			isSet = _.some(await Promise.all(queryParams.f.map(async (filter) => {

				let isSetFilter = false;
				if (filter.key && filter.values && filter.values.length > 0) {

					const newFilter = this.addFilter();

					await this.setFilterKey(newFilter, filter.key);

					this.setFilterParam(newFilter, "operator", filter.operator);

					const stringList = newFilter.allowNew;

					_.forEach(filter.values, (label) => {
						const filterValue = !stringList
							? reportingFiltersManager.getFilterListValueByLabel(newFilter, label, filter.verticalType)
							: typeof (label) === "string"
								? label
								: null;

						if (filterValue) {
							isSetFilter = true;

							this.pushFilterValue(newFilter, filterValue, label);
						}
					});
				}

				return isSetFilter;
			})));
		}

		return isSet;
	}

	@action public downloadCSV = (chartId: string) => {
		notificationsStore.pushSuccessNotification({
			title: "Generating CSV",
			text: "Please wait...",
			timeout: 5000
		});
		const store = editChartsManagerStore.getStore(chartId);
		const report = toJS(store.chartReportByTimePreset[metaDataStore.timePreset].results);
		exportToCsvService.exportDashboardReport(report);
	};

}
