import {PrebidAdapter} from "@vidazoo/ui-framework/lib/common/enums";

export default [
	PrebidAdapter.FREEWHEEL_SSP,
	PrebidAdapter.RUBICON,
	PrebidAdapter.SPOTX,
	PrebidAdapter.SPOTX_ADOS,
	PrebidAdapter.OPENX,
	PrebidAdapter.APP_NEXUS,
	PrebidAdapter.ONE_VIDEO,
	PrebidAdapter.LKQD,
	PrebidAdapter.INDEX,
	PrebidAdapter.COLOSSUS,
	PrebidAdapter.TELARIA,
	PrebidAdapter.PUBMATIC,
	PrebidAdapter.RHYTHM_ONE,
	PrebidAdapter.BEACH_FRONT,
	PrebidAdapter.SMART_AD_SERVER,
	PrebidAdapter.PULSE_POINT,
	PrebidAdapter.IMPROVE_DIGITAL,
	PrebidAdapter.SMAATO
];
