import * as React from "react";
import EditChartStore from "modules/chart/stores/EditChartStore";
import CreateChartStore from "modules/chart/stores/CreateChartStore";
import MetaDataStore from "../../../reporting/stores/MetaDataStore";
import ReportingStore from "../../../reporting/stores/ReportingStore";

export interface IChartContext {

	store: EditChartStore | CreateChartStore;

	metaDataStore?: MetaDataStore;

}

export default React.createContext<IChartContext>({
	store: null,
	metaDataStore: null,
});
