import * as React from "react";
import {FormInput, FormSelect, Icon} from "@vidazoo/ui";
import * as selfTheme from "./theme.scss";
import * as theme from "styles/_baseForm.scss";
import reportingService from "../../../reporting/services/reportingService";
import ChartContext from "./ChartContext";
import {normalizeEventValue} from "@vidazoo/ui-framework";
import {observer} from "mobx-react";
import {ColorPicker} from "../../../../common/components";

interface IStyleFormProps {
	style?: any;
	index?: number;
}

interface IStyleFormState {
}

@observer
class StyleForm extends React.Component<IStyleFormProps, IStyleFormState> {

	public static contextType = ChartContext;

	constructor(props, context) {
		super(props, context);
		this.state = {}
	}

	private onChange = (e) => {
		const {store} = this.context;
		const {name} = e.target;
		const value = normalizeEventValue(e);
		store.onCustomStyleChange(value, name, this.props.index);
	}

	private onColorChange = (color, name) => {
		const {store} = this.context;
		store.onCustomStyleChange(color.hex, name, this.props.index)
	}

	private onDelete = () => {
		const {store} = this.context;
		store.removeCustomStyle(this.props.index);
	}

	private getSelectItems = () => {
		const {store} = this.context;
		const alreadySelected = store.item.customStyles.map((style) => style.metric);
		const fieldsToGet = store.item.reportParams.fields.filter((field) => !alreadySelected.includes(field));
		return fieldsToGet.map((field) => reportingService.getFieldByValue(field, store.item.reportParams.verticalType))
	}

	public render() {
		const {store} = this.context;
		const {index} = this.props;
		const current = store.item.customStyles[index];
		return (
			<div className={theme.row}>
				<FormSelect
					fill
					className={theme.input}
					label="Metric"
					name="metric"
					maxItems={5}
					onChange={this.onChange}
					value={current.metric}
					items={this.getSelectItems()}/>
				<FormInput className={theme.input} name="threshold" onChange={this.onChange} type="number"
						   value={current.threshold} label="Threshold"/>
				<ColorPicker color={current.highColor}  label="high color" name="highColor" onChange={this.onColorChange}/>
				<ColorPicker color={current.lowColor} label="low color" name="lowColor" onChange={this.onColorChange}/>
				<div className={selfTheme.delete} onClick={this.onDelete}>
					<Icon iconName="icon-delete" size={1.8}/>
				</div>
			</div>
		);
	}
}

export default StyleForm;
