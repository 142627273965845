import {observer} from "mobx-react";
import {FormSelect} from "@vidazoo/ui/lib/components/select";
import * as theme from "../theme.scss";
import {FilterOperators} from "../../../../../common/enums";
import * as React from "react";
import {IChangeLogFilter} from "../../../interfaces/IChangeLogFilter";
import {IChangeLogsContext} from "../../../containers/ChangeLogsContext";
import {Chips} from "@vidazoo/ui";
import {metaDataStore} from "../../../../../common/stores";

export interface IAccountFilterProps extends React.InputHTMLAttributes<HTMLInputElement> {
	filter: IChangeLogFilter;
	context: IChangeLogsContext;
}

function AccountFilter({filter, context}: IAccountFilterProps): JSX.Element {

	function onChangeFilterParam(e) {
		const {setFilterParam} = context.store;
		const {name, value} = e.target;
		setFilterParam(filter, name, value);
	}

	function addFilterAccount(value: string) {
		const {addFilterParamArray} = context.store;
		addFilterParamArray(filter, "value", value);
	}

	function onDeleteValue(value: string, label: string) {
		const {removeFilterParamArray} = context.store;
		removeFilterParamArray(filter, "value", value);
	}

	return (
		<>
			<FormSelect
				className={theme.select}
				items={[FilterOperators.isAnyOf, FilterOperators.isNotAnyOf]}
				maxItems={5}
				itemValueKey="value"
				itemLabelKey="label"
				value={filter.operator || FilterOperators.isAnyOf}
				name="operator"
				required
				hideValidationMessage
				onChange={onChangeFilterParam}
				searchable
			/>
			<Chips
				deepPurple
				allowOrder={false}
				leaveOpenAfterSelect
				allowCreate={false}
				selectValueOnBlur={false}
				dataSource={metaDataStore.accounts}
				itemLabelKey="username"
				itemValueKey="_id"
				chips={filter.value}
				maxItems={5}
				onSelect={addFilterAccount}
				onDelete={onDeleteValue}
				isLoading={(!metaDataStore.accounts || metaDataStore.accounts.length === 0)}
			/>
		</>
	);
}

export default observer(AccountFilter);
