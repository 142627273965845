import * as React from "react";
import * as PropTypes from "prop-types";
import * as theme from "styles/_baseForm.scss";
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {RouteComponentProps} from "react-router";
import {BaseContainer, FormMode} from "@vidazoo/ui-framework";
import {
	Surface,
	Loader,
	PageMessage, CircleLoader, ProgressBar, PageContainer, Label
} from "@vidazoo/ui";
import EditChartStore from "../../stores/EditChartStore";
import ChartContext from "./ChartContext";
import {cloudStore, editChartsManagerStore, metaDataStore} from "../../../../common/stores";
import * as chartTheme from "./theme.scss";
import DiffTable from "../../../dashboard/containers/dashboard/diffTable/DiffTable";

export interface IViewChartProps extends RouteComponentProps<{ id: string }> {
}

export interface IViewChartState {
	showDeleteMessage: boolean;
}

class ViewChart extends BaseContainer<IViewChartProps, IViewChartState> {

	public static defaultProps: Partial<IViewChartProps> = {};

	private store: EditChartStore;

	constructor(props, context) {
		super(props, context);

		this.store = new EditChartStore();
	}

	public componentWillMount() {
		const {match} = this.props;
		cloudStore.addTopCharts(match.params.id);
		window.localStorage.setItem("lastChart", match.params.id);

		this.store.getItem(match.params.id);
	}

	public componentDidUpdate(prevProps: IViewChartProps) {
		const {match} = this.props;

		if (match.params.id !== prevProps.match.params.id) {
			this.initStore();
			window.localStorage.setItem("lastChart", match.params.id);
			cloudStore.addTopCharts(match.params.id);
		}

		if (!this.store.chartReportByTimePreset[metaDataStore.timePreset] || !this.store.chartReportByTimePreset[metaDataStore.timePreset].results) {
			this.store.getReport();
		}
	}

	private initStore() {
		const {match} = this.props;

		const store = match.params.id ? editChartsManagerStore.chartStores[match.params.id] : null;

		if (store) {
			this.store.refresh();
			this.store = store;
			this.store.refresh();

		} else {
			this.store = new EditChartStore();
			this.store.getItem(match.params.id);
			editChartsManagerStore.setStore(this.store, match.params.id);
		}
	}

	private renderLoader() {
		return (
			<div className={theme.message}>
				<Loader size={1} gray className={theme.loader}/>
				<PageMessage
					small
					title="Loading Chart"
					description="Please wait..."
				/>
			</div>
		);
	}

	private renderForm() {
		if (!this.store.chartReportByTimePreset[metaDataStore.timePreset] || this.store.chartReportByTimePreset[metaDataStore.timePreset].isLoading) {
			return this.renderLoader();
		}
		return (
			<div>
				<div className={chartTheme.chartHeader}>
					<Label spacing={1.25} size={23} monserrat weight={600}>{this.store.item.name}</Label>
				</div>
				<DiffTable
					listClassName={chartTheme.listView}
					chart={this.store.item}
					columnsWidth={this.store.columnsWidth}
					setColumnsWidth={this.store.setColumnsWidth}
					report={this.store.chartReportByTimePreset[metaDataStore.timePreset].results}/>
			</div>
		);
	}

	private renderError() {
		return (
			<div className={theme.message}>
				<PageMessage
					small
					title="Failed to load chart"
					description="We had an error loading the requested chart."
					iconName="icon-rejected-status"
				/>
			</div>
		);
	}

	private getTitle(): string {
		return this.store.isLoading
			? "Loading..."
			: this.store.loadError
				? "Error"
				: this.store.item.name;
	}

	public render(): JSX.Element {
		const {match} = this.props;

		return (
			<PageContainer pageTitle={this.getTitle()} inner top={1.2} style={{maxWidth: "unset"}}>
				{this.store.isLoading ? this.renderLoader() : null}
				{this.store.loadError ? this.renderError() : null}
				{!this.store.isLoading && !this.store.loadError ? this.renderForm() : null}
			</PageContainer>
		);
	}
}

export default withRouter<any, any>(observer(ViewChart));
