import { BaseAPI } from "@vidazoo/ui-framework";

class PresetReportsAPI extends BaseAPI {
	public getAll() {
		return this.request({
			method: "get",
			url: "/api/preset_reports"
		});
	}

	public create(data: any) {
		return this.request({
			method: "post",
			url: "/api/preset_reports/",
			data
		});
	}

	public update(id: string, data: any) {
		return this.request({
			method: "patch",
			url: `/api/preset_reports/${id}/`,
			data
		});
	}

	public delete(id: string) {
		return this.request({
			method: "delete",
			url: `/api/preset_reports/${id}/`
		});
	}

	public updateLastUsed(id: string) {
		return this.request({
			method: "patch",
			url: `/api/preset_reports/${id}/last-used`,
		});
	}
}

export default new PresetReportsAPI(window.__BASE_API_URL__);
