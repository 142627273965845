import { BaseAPI } from "@vidazoo/ui-framework";
import {REPORT_VERTICAL_TYPE} from "../../../common/enums";

class ReportingAPI extends BaseAPI {

	public getNeededData(vertical: REPORT_VERTICAL_TYPE, neededGroup: string) {
		return this.request({
			method: "get",
			url: `/api/reporting/needed_data/${vertical}/${neededGroup}`
		});
	}

	public getSubGroupsEntities(vertical: REPORT_VERTICAL_TYPE, group: string) {
		return this.request({
			method: "get",
			url: `/api/reporting/sub_groups_entities/${vertical}/${group}`
		});
	}

	public getAllReportsHistory() {
		return this.request({
			method: "get",
			url: `/api/reporting/get_all_reports_history`
		});
	}
}

export default new ReportingAPI(window.__BASE_API_URL__);
