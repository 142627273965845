import * as React from "react";
import * as PropTypes from "prop-types";
import clsx from "clsx";
import * as Highcharts from "highcharts";
import * as theme from "./theme.scss";
import {PageMessage} from "@vidazoo/ui";

function tooltipFormatter() {
	let title = this.x;
	if (this.points[0].point.series.chart.options.tooltip.xDateFormat) {
		title = Highcharts.dateFormat(this.points[0].point.series.chart.options.tooltip.xDateFormat, this.x);
	}

	let s = `<span class="${theme["tooltip-title"]}">${title}</span>`;

	this.points.forEach((point) => {
		s += `<span class="${theme["tooltip-series"]}"><span class="${theme["tooltip-label"]}">${point.series.name}:</span> <span class="${theme["tooltip-value"]}" style="color: ${point.color};">${point.y.toLocaleString()}</span></span>`;
	});

	return s;
}

export interface IChartProps {
	className?: string;
	style?: React.CSSProperties;
	config: Highcharts.Options;
	displayNoData?: boolean;
}

export interface IChartState {

}

export default class Chart extends React.Component<IChartProps, IChartState> {

	public static defaultProps: Partial<IChartProps> = {
		config: {
			credits: {enabled: false},
			chart: {}
		},
		displayNoData: false
	};

	private $el: HTMLDivElement;
	private chart: Highcharts.ChartObject;

	constructor(props, context) {
		super(props, context);

		this.$el = null;
		this.chart = null;
	}

	public componentDidUpdate() {
		const {config} = this.props;

		this.setTooltipFormatter(config);

		this.chart && this.chart.update(config, true);
	}

	public componentDidMount() {
		const {config} = this.props;

		config.chart.renderTo = this.$el;

		this.setTooltipFormatter(config);

		this.chart = Highcharts.chart(config);
	}

	private setTooltipFormatter(config: Highcharts.Options) {
		if (config.tooltip && !config.tooltip.formatter) {
			config.tooltip.formatter = tooltipFormatter;
		}
	}

	public render(): JSX.Element {

		const {className, style, displayNoData} = this.props;

		const classes = clsx(theme.chart, className);

		return (
			<div className={classes} style={style}>
				<div className={theme.inner} ref={(el) => this.$el = el}></div>
			</div>
		);
	}
}
