import { BaseAPI } from "@vidazoo/ui-framework";
import { IGetAll } from "../../../common/interfaces/IGetAll";

class DashboardsAPI extends BaseAPI {
	public getAll({ page = 1, pageSize = null, filter = {}, fields = {}, sort = {} }: IGetAll) {
		return this.request({
			method: "get",
			url: "/api/dashboards/",
			params: { filter, fields, sort }
		});
	}

	public getOne(id: string) {
		return this.request({
			method: "get",
			url: `/api/dashboards/${id}/`
		});
	}

	public create(data: any) {
		return this.request({
			method: "post",
			url: "/api/dashboards/",
			data
		});
	}

	public delete(id: string) {
		return this.request({
			method: "delete",
			url: `/api/dashboards/${id}/`
		});
	}

	public update(id: string, data: any) {
		return this.request({
			method: "patch",
			url: `/api/dashboards/${id}/`,
			data
		});
	}

	public active(id: string, isActive: boolean) {
		return this.request({
			method: "patch",
			url: `/api/dashboards/${id}/active/`,
			data: { isActive }
		});
	}
}

export default new DashboardsAPI(window.__BASE_API_URL__);
