import {REPORT_VERTICAL_TYPE} from "../../../common/enums";
import {IDictionary} from "@vidazoo/ui-framework";
import reportingAPI from "../api/reportingAPI";
import {action, computed, observable, runInAction} from "mobx";

class ReportingSubGroupsManager {

	@observable public subGroupsEntities: IDictionary<any>;

	constructor() {
		this.reset();
	}

	@action public reset = () => {
		this.subGroupsEntities = {};
	};

	@action public getSubGroupsEntities(group: string, vertical: REPORT_VERTICAL_TYPE) {
		if (!group) {
			return {isLoading: true, data: []};
		}
		if (this.subGroupsEntities[vertical + group]) {
			return this.subGroupsEntities[vertical + group];
		} else {
			this.subGroupsEntities[vertical + group] = {isLoading: true, data: []};

			this._getSubGroupsEntities(group, vertical);
			return this.subGroupsEntities[vertical + group];
		}
	}

	private _getSubGroupsEntities(group: string, vertical: REPORT_VERTICAL_TYPE) {
		reportingAPI.getSubGroupsEntities(vertical, group)
			.then((res) => {
				runInAction(() => {
					this.subGroupsEntities[vertical + group] = {
						isLoading: false,
						data: res.data
					};
				});
			});
	}
}

export default new ReportingSubGroupsManager();

export const PlatformSubGroupsKeys = [
	{value: "widget", label: "Widget Id"},
	{value: "tagId", label: "Tag Id"},
	{value: "playerId", label: "Player Id"},
	{value: "scenarioId", label: "Scenario Id"},
	{value: "partnerTagId", label: "Demand Partner"},
	{value: "publisherId", label: "Publisher"},
	{value: "connectionId", label: "Connection"},
];

export enum RequestedGroupValue {
	widget = "widgetOriginal",
	tagId = "tagName",
	playerId = "playerName",
	scenarioId = "scenarioName",
	partnerTagId = "partnerTagId",
	publisherId = "publisherName",
	connectionId = "connectionName"
}

export enum RequestedGroupLabel {
	widget = "Widget Original",
	tagId = "Tag",
	playerId = "Player",
	scenarioId = "Scenario",
	partnerTagId = "Demand Partner",
	publisherId = "Publisher",
	connectionId = "Connection"
}

export enum TargetingType {
	Domain = "domain",
	XRequestedWith = "x_requested_with",
	XRequestedWithList = "x_requested_with_list",
	DomainExact = "domain_exact",
	Platform = "platform",
	DomainList = "list",
	DomainListExact = "list_exact",
	Os = "os",
	Browser = "browser",
	ReferrerDomain = "referrerDomain",
	ReferrerDomainExact = "referrerDomain_exact",
	ReferrerList = "referrerList",
	ReferrerListExact = "referrerList_exact",
	Player = "player",
	Country = "country",
	CountryList = "countryList",
	Campaign = "campaign",
	CampaignList = "campaignList",
	Publisher = "publisher",
	AppDomain = "appDomain",
	AppName = "appName",
	AppNameExact = "appName_exact",
	AppNameList = "appNameList",
	AppNameListExact = "appNameList_exact",
	AppBundle = "appBundle",
	AppBundleList = "appBundleList",
	AppIosBundle = "appIosBundle",
	AppIosBundleList = "appIosBundleList",
	AppDomainList = "appDomainList",
	Param1 = "param1",
	Param2 = "param2",
	Param3 = "param3",
	WidthRange = "width_range",
	HeightRange = "height_range",
	SizeClient = "size_client",
	DeviceIFA = "ifa",
	PartnerRank = "partner_rank",
	OsVersioned = "os_versioned",
	BrowserVersioned = "browser_versioned",
	ScenarioTagPrice = "scenario_tag_price",
	DynamicMacro = "dynamic_macro",
	WebView = "web_view",
	Network = "network",
	DetectedReferrerIncludes = "detected_referrer_includes",
	DetectedReferrerListIncludes = "detected_referrer_list_includes",
	DetectedReferrerExact = "detected_referrer_exact",
	DetectedReferrerListExact = "detected_referrer_list_exact",
	IsSecure = "is_secure",
	ViewabilityRate = "viewabilityRate",
	BrowserLanguage = "browser_language",
	SiteLanguage = "site_language",
	Url = "url",
	Account = "account",
	UrlList = "urlList",
	City = "city",
	Region = "region",
	Metro = "metro",
	Postal = "postal",
	PostalList = "postalList",
	RollPosition = "roll_position",
	PlayerVisible = "player_visible",
	ScrollHeight = "scrollHeight",
	PlayerStream = "playerStream",
	VideoTime = "video_time",
	AdNoStartTime = "ad_no_start_time",
	Viewability = "viewability",
	WidgetType = "widgetType",
	Widget = "widget",
	ActiveTime = "active_time",
	TimeOnSite = "time_on_site",
	AvgTimePercent = "avgTimePercent",
	MaxAdNoStartTime = "max_ad_no_start_time",
	MaxAdNoStartTimeOnSite = "max_ad_no_start_time_on_site",
	Tcf = "tcf",
	Source = "source",
	MaxTouchPoint = "maxTouchPoint",
	UrlParams = "urlParams",
	Pixelate = "pixelate",
	VideoPlacementTypeScenario = "videoPlacementTypeScenario",
	FillRate = "fillRate",
	FluidCpm = "fluidCpm",
	FluidFloorCpmExists = "fluidFloorCpmExists",
	WindowWidthRange = "window_width_range",
	WindowHeightRange = "window_height_range",
	DemandPartner = "demand_partner",
	SchainSellerId = "schain_seller_id",
	BatchError = "batch_error",
	Connection = "connection",
	ExactSize = "exact_size",
	PlayerAverageCpm = "player_average_cpm",
	Context = "context",
	PlayerVersion = "player_version",
	PublisherType = "publisher_type",
	OpenRtbMediaType = "open_rtb_media_type",
	AdUnitCode = "ad_unit_code",
}

export const TargetingOperators = [
	{label: "IsAnyOf", value: "isAnyOf"},
	{label: "IsNotAnyOf", value: "isNotAnyOf"}
];

export const TargetingTypes = [
	{label: "Domain", value: TargetingType.Domain},
	{label: "X-Requested-With", value: TargetingType.XRequestedWith},
	{label: "X-Requested-With List", value: TargetingType.XRequestedWithList},
	{label: "Domain Exact Match", value: TargetingType.DomainExact},
	{label: "Device", value: TargetingType.Platform},
	{label: "Domain List", value: TargetingType.DomainList},
	{label: "Domain List Exact Match", value: TargetingType.DomainListExact},
	{label: "OS", value: TargetingType.Os},
	{label: "OS Version", value: TargetingType.OsVersioned},
	{label: "Browser", value: TargetingType.Browser},
	{label: "Browser Version", value: TargetingType.BrowserVersioned},
	{label: "Referrer Domain", value: TargetingType.ReferrerDomain},
	{label: "Referrer Domain Exact Match", value: TargetingType.ReferrerDomainExact},
	{label: "Referrer Domain List", value: TargetingType.ReferrerList},
	{label: "Referrer Domain List Exact Match", value: TargetingType.ReferrerListExact},
	{label: "Player", value: TargetingType.Player},
	{label: "Campaign", value: TargetingType.Campaign},
	{label: "Campaign List", value: TargetingType.CampaignList},
	{label: "Country", value: TargetingType.Country},
	{label: "Country List", value: TargetingType.CountryList},
	{label: "Supply Partner", value: TargetingType.Publisher},
	{label: "App Domain", value: TargetingType.AppDomain},
	{label: "App Domain List", value: TargetingType.AppDomainList},
	{label: "App Bundle", value: TargetingType.AppBundle},
	{label: "App Bundle List", value: TargetingType.AppBundleList},
	{label: "App Name", value: TargetingType.AppName},
	{label: "App Name Exact Match", value: TargetingType.AppNameExact},
	{label: "App Name List", value: TargetingType.AppNameList},
	{label: "App Name List Exact Match", value: TargetingType.AppNameListExact},
	{label: "Demand Tag CPM", value: TargetingType.ScenarioTagPrice},
	{label: "Size Detected", value: TargetingType.SizeClient},
	{label: "Width Range", value: TargetingType.WidthRange},
	{label: "Height Range", value: TargetingType.HeightRange},
	{label: "Device IFA", value: TargetingType.DeviceIFA},
	{label: "Partner Rank", value: TargetingType.PartnerRank},
	{label: "Custom Param 1", value: TargetingType.Param1},
	{label: "Custom Param 2", value: TargetingType.Param2},
	{label: "Dynamic Macro", value: TargetingType.DynamicMacro},
	{label: "Web View", value: TargetingType.WebView, isVidazoo: true},
	{label: "Network", value: TargetingType.Network, isVidazoo: true},
	{label: "Detected Referrer Includes", value: TargetingType.DetectedReferrerIncludes},
	{label: "Detected Referrer Exact", value: TargetingType.DetectedReferrerExact},
	{label: "Detected Referrer List Includes", value: TargetingType.DetectedReferrerListIncludes},
	{label: "Detected Referrer List Exact", value: TargetingType.DetectedReferrerListExact},
	{label: "Secure Traffic (https)", value: TargetingType.IsSecure},
	{label: "Viewability Rate", value: TargetingType.ViewabilityRate, isVidazoo: true},
	{label: "Viewability-Rate", value: TargetingType.ViewabilityRate},
	{label: "Browser Language", value: TargetingType.BrowserLanguage},
	{label: "Site Language", value: TargetingType.SiteLanguage},
	{label: "Url", value: TargetingType.Url},
	{label: "Account", value: TargetingType.Account},
	{label: "Url List", value: TargetingType.UrlList},
	{label: "City", value: TargetingType.City},
	{label: "Region", value: TargetingType.Region},
	{label: "Metro Code (DMA)", value: TargetingType.Metro},
	{label: "Postal Code", value: TargetingType.Postal},
	{label: "Postal Code List", value: TargetingType.PostalList},
	{label: "Roll Position", value: TargetingType.RollPosition},
	{label: "Player Visible", value: TargetingType.PlayerVisible},
	{label: "Player Stream", value: TargetingType.PlayerStream},
	{label: "Video Content Time", value: TargetingType.VideoTime},
	{label: "Time gap between ad impressions", value: TargetingType.AdNoStartTime},
	{label: "Real time Viewability", value: TargetingType.Viewability},
	{label: "Widget Type", value: TargetingType.WidgetType},
	{label: "Widget", value: TargetingType.Widget},
	{label: "Real Time Targeting", value: TargetingType.ActiveTime},
	{label: "Average Historic Time on Site", value: TargetingType.TimeOnSite},
	{label: "Average Time Percent", value: TargetingType.AvgTimePercent},
	{label: "Max time between ad impressions", value: TargetingType.MaxAdNoStartTime},
	{label: "Max time between ad impressions by average time", value: TargetingType.MaxAdNoStartTimeOnSite},
	{label: "TCF String Defined", value: TargetingType.Tcf},
	{label: "Url Params", value: TargetingType.UrlParams},
	{label: "Video Placement Type", value: TargetingType.VideoPlacementTypeScenario},
	{label: "Fill Rate", value: TargetingType.FillRate},
	{label: "Fluid Cpm", value: TargetingType.FluidCpm},
	{label: "Fluid Floor Cpm Exists", value: TargetingType.FluidFloorCpmExists},
	{label: "Demand Partner", value: TargetingType.DemandPartner},
	{label: "Schain Seller Id", value: TargetingType.SchainSellerId, isVidazoo: true},
	{label: "Batch Error", value: TargetingType.BatchError, isVidazoo: true},
	{label: "Connection", value: TargetingType.Connection},
	{label: "Exact Size", value: TargetingType.ExactSize},
	{label: "Player Average Cpm", value: TargetingType.PlayerAverageCpm},
	{label: "Context", value: TargetingType.Context},
	{label: "Player Version", value: TargetingType.PlayerVersion},
	{label: "Publisher Type", value: TargetingType.PublisherType, isVidazoo: true},
	{label: "OpenRtb Media Type", value: TargetingType.OpenRtbMediaType},
	{label: "Ad Unit Code", value: TargetingType.AdUnitCode},
];
