import BaseNeededGroup from "../BaseNeededGroup";
import {REPORT_VERTICAL_TYPE} from "../../../../../common/enums";
import {PlatformNeededGroups} from "../NeededGroups";
import {PlatformReportingFilterType} from "../ReportingFilterType";

export default class CountryListsFilter extends BaseNeededGroup {

	public static type = PlatformReportingFilterType.CountryLists;

	constructor() {
		super(REPORT_VERTICAL_TYPE.PLATFORM, PlatformNeededGroups.CountryList);
	}

}
