import {action} from "mobx";
import BaseScheduleReportStore, {IBaseScheduleReportStore} from "./BaseScheduleReportStore";
import {currentUserStore, navigationStore, sessionStore} from "../../../common/stores";
import scheduleReportsAPI from "../api/scheduleReportsAPI";
import {appUrlsService} from "../../../common/services";

export interface ICreateScheduleReportStore extends IBaseScheduleReportStore {

}

export default class CreateScheduleReportStore extends BaseScheduleReportStore implements ICreateScheduleReportStore {

	constructor() {
		super();
	}

	@action
	public submit = () => {
		const item = this.setAccounts();

		item.dataSourceIds = sessionStore.selectedAccounts;
		item.authUser = currentUserStore._id;

		scheduleReportsAPI.create(this.submitify(item))
			.then((res) => navigationStore.push(appUrlsService.scheduleReports()))
			.then(() => this.onSubmitSuccess())
			.catch((err) => this.onSubmitFailed(err));
	}
}
