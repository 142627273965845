import * as _ from "lodash";
import * as moment from "moment";
import {action, computed, intercept, observable, runInAction, toJS, transaction} from "mobx";
import {
	FieldType,
	formatNumber,
	getCurrentHour,
	getCurrentMonth,
	getCurrentWeek,
	getLastMonth,
	getPastDays,
	guid,
	IDictionary,
	IReportingConstraint,
	IReportingEntry,
	IReportingFilter,
	IReportingParams,
	IReportingUI,
	MemoryClipboard,
	Operators,
	ReportingTimePreset,
	URLQueryParams,
	ValidTimeZones
} from "@vidazoo/ui-framework";
import {extractReportingFieldValue, filterReportingResults} from "@vidazoo/ui-framework/lib/utils";
import {Density, SortDirection} from "@vidazoo/ui";
import {PeriodsList} from "@vidazoo/ui/lib/components/periodPicker";
import reportingFiltersManager from "./filters/reportingFiltersManager";
import IReportingResults from "../interfaces/IReportingResults";
import {
	currentUserStore,
	historyReportsStore,
	metaDataStore, navigationStore,
	notificationsStore,
	presetReportsStore, reportingStore,
	sessionStore
} from "../../../common/stores";
import reportingService, {ReportingService} from "../services/reportingService";
import {
	appUrlsService,
	exportToCsvService,
	historyReportsSocketService,
	storageService
} from "../../../common/services";
import {GetUrlAction, REPORT_VERTICAL_TYPE, ReportingDbType, ReportStatus} from "../../../common/enums";
import IReportingPreset from "../interfaces/IReportingPreset";
import presetReportsAPI from "../api/presetReportsAPI";
import columnsWidthAPI from "../../../common/apis/columnsWidthAPI";
import reportingSubGroupsManager, {RequestedGroupLabel, RequestedGroupValue} from "./reportingSubGroupsManager";
import {IReportTable, IReportTableParams} from "../interfaces/IReportTableParams";
import {IBiReportingParams, ISubGroup} from "../interfaces/IBiReportingParams";
import {IReportHistory} from "../interfaces/IReportHistory";
import reportingHistoryAPI from "../api/reportingHistoryAPI";
import axios from "axios";
import {IDynamicFormula} from "../interfaces/IDynamicFormula";
import {compileFieldFormula} from "../../../common/utils";

const CUSTOM_DATE_KEYS = ["from", "to"];
const CUSTOM_COMPARE_DATE_KEYS = ["compareFrom", "compareTo"];
const defaultFieldFormat = (v) => formatNumber(v, 2);
const DATE_DIMENSIONS_FORMATS = {
	Month: "MM/YYYY",
	Day: "DD/MM/YYYY",
	Hour: "DD/MM/YYYY HH:mm",
	Time: "HH:mm",
	DayUSFormat: "MM/DD/YYYY",
};
const VALID_PERIODS = PeriodsList.map(({value}) => value);

export default class ReportingStore {

	@observable public ui: IReportingUI;
	@observable public historyParams: IBiReportingParams[];
	@observable public params: IBiReportingParams;
	@observable public searchQueries: IDictionary<string>;
	@observable public isLoading: boolean;
	@observable public loadedOnce: boolean;
	@observable public isSearched: boolean;
	@observable public error: any;
	@observable public verticalType: REPORT_VERTICAL_TYPE;
	@observable public reportingSumOnTop: boolean;
	@observable public columnsWidthByEntity: { [index: number]: number };
	@observable public results: IReportingResults;
	@observable public reportTableParams: IReportTableParams;
	@observable public isTransposed: boolean;
	@observable public dbType: ReportingDbType;
	@observable public executionTime: number;
	@observable public startTime: number;
	@observable public isLoadingReportFromUrls: boolean;
	@observable public useHistoryReports: boolean;
	@observable public usePublishBus: boolean;
	@observable public editDynamicFormula: IDynamicFormula;

	private reportingService: ReportingService;
	private setColumnsWidthDebounce: () => void;

	constructor() {
		this.reportingService = reportingService;
		this.reset();
	}

	public setLoading() {
		this.isLoading = true;
	}

	public startListenToReportHistoryIsReady() {
		historyReportsSocketService.startListenToReportHistoryIsReady(currentUserStore._id, (data) => this.onReportReady(data));
		historyReportsSocketService.startListenToReportHistoryUploadToS3(currentUserStore._id, (data) => this.onReportUploadToS3(data));

	}

	@action
	private onReportUploadToS3(data: { item: IReportHistory }) {
		historyReportsStore.setReportStatus(data.item, ReportStatus.Finished);
		this.executionTime = data.item.executionTime;
	}

	@action
	private async onReportReady(data: { item: IReportHistory, report: any }, setParams: boolean = false) {
		this.isLoadingReportFromUrls = false;

		if (historyReportsStore.selectedReport && historyReportsStore.selectedReport._id === data.item._id) {
			setParams && await this.setParamsFromHistoryReport(historyReportsStore.selectedReport);
			const groups = this.params.groups.map((paramGroup) => {
				if (!paramGroup.operators) {
					return metaDataStore.metaDataByVertical[this.verticalType].groups.find((group) => group.value === paramGroup.value);
				} else {
					return paramGroup;
				}
			});
			if (this.params.subGroups && this.params.subGroups.length) {
				this.params.subGroups.forEach((subGroup) => {

					subGroup.entities.forEach((entity) => {
						if (entity === "targeting") {
							subGroup.targeting.types.forEach((targetingType) => {
								subGroup.targeting.operators.forEach((targetingOperator) => {
									const entityTargeting = `targeting:${targetingType}:${targetingOperator}`;
									groups.push({label: entityTargeting, value: entityTargeting});
								});
							});
						} else if (entity === "optionsWidget" && subGroup.freeField) {
							groups.push({
								label: `optionsWidget:${subGroup.freeField}`,
								value: `optionsWidget:${subGroup.freeField}`
							});

						} else {
							groups.push({label: entity, value: entity});
						}
					});
				});
			}

			const isCompareReport = data.item.isCompareReport || (!!data.item.params.compareFrom);
			const prepared = await this.reportingService.buildResults(groups, this.extractedParamsFields, data.report, isCompareReport, this.verticalType);
			this.setResults(prepared, data.item.executionTime);
		}
	}

	@action
	public async setParamsFromHistoryReport(item: IReportHistory) {
		this.params = this.initialParams;
		const fields = (item.originFields && item.originFields.length > 0) ? item.originFields : item.params.fields;
		await Promise.all([
			this.setReportVerticalTypeFromFlatten(item.params.verticalType),
			this.setReportFiltersFromFlatten(item.params.filters),
			this.setReportGroupsFromFlatten(item.params.groups),
			this.setReportSubGroupsFromReportHistory(item.params.subGroups),
			this.setReportFieldsFromFlatten(fields),
			this.setReportConstraintsFromFlatten(item.params.constraints),
			this.setReportTimeZoneFromFlatten(item.params.timeZone),
			this.setReportTimeFrameFromReportHistory(item.params.from, item.params.to),
			this.setReportCompareTimeFrameFromReportHistory(item.params.compareFrom, item.params.compareTo),
		]);
		this.extractParamsFields(true);
	}

	@computed
	public get reportTable(): IReportTable {

		const reportTable = {
			columns: [],
			rows: []
		};

		if (this.results && this.results.result.length > 0) {

			if (this.isTransposed) {
				this.paramsHeaders.forEach((columnKey) => {
					const row = [
						{
							...columnKey,
							valueDisplay: columnKey.name,
							displayValue: columnKey.name,
							value: columnKey.name,
							isGroup: true,
							hasFilterType: false,
							link: null
						}
					];
					let sum = this.filteredTotal.find((item) => item.name === columnKey.name);
					if (!sum) {
						sum = {
							guid: guid(),
							valueDisplay: "",
							displayValue: "",
							value: "",
							isGroup: true,
							hasFilterType: false,
							link: null
						};
					}
					row.push(sum);

					this.results.result.forEach((item) => {
						if (item.indexed[columnKey.name]) {
							row.push(item.indexed[columnKey.name]);
						}
					});

					reportTable.rows.push(row);
				});
				reportTable.columns = reportTable.rows[0].map((row, index) => {
					let name = `${index - 1}`;
					if (index === 0) {
						name = `TOTAL ROWS: ${reportTable.rows[0].length - 1}`;
					} else if (index === 1) {
						name = `SUM`;
					}
					return {
						...row,
						name
					};
				});

			} else {
				reportTable.columns = this.paramsHeaders;
				reportTable.rows = this.paramsFilteredResults.map((row) => ([
					...row.groups,
					...row.fields
				]));
			}
		}
		return reportTable;
	}

	@action public getReport = (resetLoad: boolean = false) => {
		if (!this.canGetReport) {
			return;
		}

		transaction(() => {
			this.error = null;
			this.isLoading = true;
			this.ui.page = 1;
			this.startTime = Date.now();

			if (resetLoad) {
				this.loadedOnce = false;
			}
		});

		this.setStorage();

		this.params.fields = this.params.fields.filter((currFilter) => currFilter.type !== "formula");
		const params = toJS(this.params);

		this.extractParamsFields(true);

		const isCompareReport = this.params.isCompare && this.allowCompare();

		this.dbType && (params.dbType = this.dbType);

		if (this.useHistoryReports && !sessionStore.historyReportsIsOffline) {
			this.reportingService.getReportingHistory(params, isCompareReport, this.verticalType, this.usePublishBus)
				.then((res) => this.onCreateReportSuccess(res))
				.catch((err) => this.setError(err));
		} else {
			const groups = this.params.groups.map((paramGroup) => {
				if (!paramGroup.operators) {
					return metaDataStore.metaDataByVertical[this.verticalType].groups.find((group) => group.value === paramGroup.value);
				} else {
					return paramGroup;
				}
			});
			if (this.params.subGroups && this.params.subGroups.length) {
				this.params.subGroups.forEach((subGroup) => {

					subGroup.entities.forEach((entity) => {
						if (entity === "targeting") {
							subGroup.targeting.types.forEach((targetingType) => {
								subGroup.targeting.operators.forEach((targetingOperator) => {
									const entityTargeting = `targeting:${targetingType}:${targetingOperator}`;
									groups.push({label: entityTargeting, value: entityTargeting});
								});
							});
						} else if (entity === "optionsWidget" && subGroup.freeField) {
							groups.push({
								label: `optionsWidget:${subGroup.freeField}`,
								value: `optionsWidget:${subGroup.freeField}`
							});

						} else {
							groups.push({label: entity, value: entity});
						}
					});
				});
			}
			this.reportingService.getReport(params, isCompareReport, this.verticalType)
				.then((data) => this.reportingService.buildResults(groups, this.extractedParamsFields, data, isCompareReport, this.verticalType)
					.then((results) => this.setResults(results))
					.catch((err) => this.setError(err)));
		}
	};

	private onCreateReportSuccess(item: IReportHistory) {
		if (item.fileIds && item.fileIds.length > 0) {
			this.getReportFromReportingHistoryUrls(item, GetUrlAction.Generate);
		} else {
			historyReportsStore.onCreateReportSuccess(item);
		}
	}

	@action
	public clearResults = () => {
		this.results = this.initialResults;
		this.isSearched = false;
		this.isLoading = false;
	};

	@action
	private setResults(results: IReportingResults, executionTime?: number) {
		transaction(() => {
			this.executionTime = executionTime || (Date.now() - this.startTime);
			this.startTime = 0;
			this.isSearched = true;
			this.isLoading = false;
			this.results = {
				result: observable.array(results.result, {deep: false}),
				groups: observable.array(results.groups, {deep: false}),
				groupsByName: _.keyBy(results.groups, "name"),
				fields: observable.array(results.fields, {deep: false}),
				fieldsByName: _.keyBy(results.fields, "name"),
				totalFields: observable.map(results.totalFields, {deep: false}),
				originResults: results.originResults
			};

			this.reportTableParams.columnKeys = this.paramsHeaders;
			const searchQueries: IDictionary<string> = {};

			_.forEach(results.groups, (item) => {
				if (item.name) {
					searchQueries[item.name] = this.searchQueries[item.name] || "";
				}
			});

			this.searchQueries = searchQueries;

			if (this.results.result.length === 0) {
				notificationsStore.pushWarningNotification({
					title: "Oops!",
					text: "We couldn't find any results",
					timeout: 5000
				});
			}

			if (this.hasActiveSort()) {
				this.sort(this.ui.sortBy, false);
			} else {

				const firstFieldLabel = this.extractedParamsFields[0] && this.extractedParamsFields[0].label;

				if (firstFieldLabel) {
					this.ui.sortDirection = SortDirection.DESC;
					this.sort(firstFieldLabel);
				}
			}
		});
	}

	@action
	private setError(err: Error) {
		transaction(() => {
			this.isLoading = false;
			this.results = this.initialResults;
			this.error = err;

			notificationsStore.pushErrorNotification({
				title: "Oops!",
				text: "Something went wrong. try again.",
				timeout: 5000
			});
		});
	}

	protected setStorage() {
		storageService.setGlobalTimezone(this.params.timezone);
		storageService.setReportingEntries("groups", this.params.groups, this.verticalType);
		storageService.setReportingEntries("fields", this.params.fields, this.verticalType);
		storageService.setReportingFilters(this.params.filters, this.verticalType);
		storageService.setReportingConstraints(this.params.constraints, this.verticalType);
		storageService.setReportingSubGroups(this.params.subGroups, this.verticalType);
	}

	@action public reset = () => {
		transaction(() => {
			this.ui = this.initialUIParams;
			this.params = this.initialParams;
			this.historyParams = observable.array([]);
			this.results = this.initialResults;
			this.isLoading = false;
			this.isLoadingReportFromUrls = false;
			this.loadedOnce = false;
			this.isSearched = false;
			this.isTransposed = false;
			this.searchQueries = {};
			this.error = null;
			this.columnsWidthByEntity = {};
			this.verticalType = REPORT_VERTICAL_TYPE.PLATFORM;
			this.dbType = null;
			this.reportTableParams = {
				rowKeys: observable.array([]),
				columnKeys: observable.array([])
			};
			this.useHistoryReports = true;
			this.applyTimePreset();

			this.setColumnsWidthDebounce = _.debounce(() => {
				if (!this.isTransposed) {
					columnsWidthAPI.setColumnsWidth("reporting", this.columnsWidthByEntity);
				}
			}, 1000);

			this.reportingSumOnTop = true;

			this.editDynamicFormula = {
				name: "",
				formula: "",
			};
		});
		intercept(this.params, this.paramsChange);
	};

	public initReportingService = () => {
		this.reportingService.initialize();
	};

	@action
	public initAfterAllInitialize() {
		this.reportingSumOnTop = storageService.getReportingSumOnTop();
	}

	@action public initialize = (query: string, reloadData?: boolean) => {
		transaction(async () => {
			this.initReportingService();

			await Promise.all([this.setInitialParams(query), this.initializeColumnsWidth()]);

			this.applyTimePreset();

			// if a filter was saved in local storage, the filter values list might be outdated, so we must refresh it
			this.setItemsOnFilterParams();

			if (reloadData) {
				this.getReport();
			}
		});
	};

	@action
	private async setInitialParams(query: string) {
		let setParamsFromQuery = false;

		if (query) {
			try {
				const queryParams = URLQueryParams.parse(query);

				setParamsFromQuery = _.some(await Promise.all([
					this.setReportVerticalTypeFromFlatten(queryParams.v),
					this.setReportGroupsFromFlatten(queryParams.gs),
					this.setReportFieldsFromFlatten(queryParams.fs),
					this.setReportFiltersFromFlatten(queryParams.f),
					this.setReportConstraintsFromFlatten(queryParams.c),
					this.setReportTimeZoneFromFlatten(queryParams.tz),
					this.setReportTimeFrameFromQueryString(queryParams),
					this.setReportCompareFromQueryString(queryParams),
					this.setReportCompareTimeFrameFromQueryString(queryParams)
				]));
			} catch (e) {
				notificationsStore.pushErrorNotification({
					title: "Oops!",
					text: "Failed to parse query params",
					timeout: 5000
				});
			}
		}

		if (!setParamsFromQuery) {
			this.params.fields = this.reportingService.getPreselectedFields(this.verticalType);
			this.params.groups = this.reportingService.getPreselectedGroups(this.verticalType);
			this.params.filters = this.reportingService.getPreselectedFilters(this.verticalType);
			this.params.constraints = this.reportingService.getPreselectedConstraints(this.verticalType);
			this.params.subGroups = this.reportingService.getPreselectedSubGroups(this.verticalType);
		}
	}

	@action
	private setReportVerticalTypeFromFlatten(verticalType: REPORT_VERTICAL_TYPE): boolean {
		let isSet = false;

		if (verticalType) {
			isSet = true;
			this.verticalType = verticalType;
		}

		return isSet;
	}

	@action
	private setReportFieldsFromFlatten(fields: any[]): boolean {
		let isSet = false;

		if (fields && fields.length > 0) {
			fields.forEach((label) => {
				let field = this.reportingService.getFieldByLabel(label, this.verticalType);
				if (!field) {
					field = this.reportingService.getFieldByValue(label, this.verticalType);
				}

				if (!field) {
					field = this.reportingService.getCapsuleById(label, this.verticalType);
				}

				if (field) {
					isSet = true;
					this.params.fields = this.params.fields.concat(field);
				}
			});
		}
		return isSet;
	}

	@action
	public toggleUseHistoryReports = () => {
		if (this.useHistoryReports) {
			navigationStore.push({pathname: appUrlsService.reporting()});
		}

		this.useHistoryReports = !this.useHistoryReports;
	};

	@action
	public toggleUsePublishBus = () => {
		this.usePublishBus = !this.usePublishBus;
	};

	@action
	private setReportGroupsFromFlatten(groups: any[]): boolean {
		let isSet = false;

		if (groups && groups.length > 0) {
			groups.forEach((label) => {
				let group = this.reportingService.getGroupByLabel(label, this.verticalType);
				if (!group) {
					group = this.reportingService.getGroupByValue(label, this.verticalType);
				}

				if (group) {
					isSet = true;

					this.pushReportParam("groups", group.value, group.label);
				}
			});
		}

		return isSet;
	}

	@action
	private async setReportFiltersFromFlatten(filters: any[]): Promise<boolean> {
		let isSet = false;

		if (filters && filters.length > 0) {

			isSet = _.some(await Promise.all(filters.map(async (filter) => {

				let isSetFilter = false;
				if (filter.key && filter.values && filter.values.length > 0) {
					const entry = this.reportingService.getFilterByValue(filter.key, this.verticalType);

					if (entry) {
						const newFilter = this.addFilter();

						this.setFilterParam(newFilter, "operator", filter.operator);

						await this.setFilterKey(newFilter, entry.value);

						const stringList = newFilter.allowNew;

						_.forEach(filter.values, (label) => {
							const filterValue = !stringList
								? reportingFiltersManager.getFilterListValueByLabel(newFilter, label, this.verticalType)
								: typeof (label) === "string"
									? label
									: null;

							if (filterValue) {
								isSetFilter = true;

								this.pushFilterValue(newFilter, filterValue, label);
							} else {
								const filterListLabelByValue = reportingFiltersManager.getFilterListLabelByValue(newFilter, label, this.verticalType);
								if (filterListLabelByValue) {
									this.pushFilterValue(newFilter, label, filterListLabelByValue);
								}
							}
						});
					}
				}

				return isSetFilter;
			})));
		}

		return isSet;
	}

	@action
	private setReportConstraintsFromFlatten(constraints: any[]): boolean {
		let isSet = false;

		if (constraints && constraints.length > 0) {
			constraints.forEach((constraint) => {

				if (constraint.name && constraint.op && Operators.indexOf(constraint.op) > -1 && !isNaN(constraint.value)) {

					const entry = this.reportingService.getFieldByLabel(constraint.name, this.verticalType);

					if (entry) {
						isSet = true;

						this.addConstraint({
							name: entry.value,
							op: constraint.op,
							value: constraint.value
						});
					}
				}
			});
		}

		return isSet;
	}

	@action
	private setReportTimeZoneFromFlatten(timezone: string): boolean {
		let isSet = false;

		if (timezone && ValidTimeZones.indexOf(timezone) > -1) {
			isSet = true;
			this.params.timezone = timezone;
		}

		return isSet;
	}

	@action
	private setReportCompareFromQueryString(queryParams: any): boolean {
		let isSet = false;

		if ("compare" in queryParams) {
			isSet = true;
			this.params.isCompare = queryParams.compare === "true";
		}

		return isSet;
	}

	@action
	private setReportSubGroupsFromReportHistory(subGroups: ISubGroup[]): boolean {
		this.params.subGroups = subGroups;
		return true;
	}

	@action
	private setReportTimeFrameFromReportHistory(from: number, to: number): boolean {
		let isSet = false;

		if (from && to) {
			const fromMoment = moment(from * 1000).utc(false);
			const toMoment = moment(to * 1000).utc(false);

			if (fromMoment.isValid() && toMoment.isValid() && fromMoment < toMoment) {
				this.setParam("from", fromMoment);
				this.setParam("to", toMoment);
				isSet = true;
			}
		}

		return isSet;
	}

	@action
	private setReportCompareTimeFrameFromReportHistory(from: number, to: number): boolean {
		let isSet = false;

		if (from && to) {
			const fromMoment = moment(from * 1000).utc(false);
			const toMoment = moment(to * 1000).utc(false);
			this.setParam("isCompare", true);

			if (fromMoment.isValid() && toMoment.isValid() && fromMoment < toMoment) {
				this.setParam("compareFrom", fromMoment);
				this.setParam("compareTo", toMoment);
				isSet = true;
			}
		}

		return isSet;
	}

	@action
	private setReportTimeFrameFromQueryString(queryParams: any): boolean {
		let isSet = false;

		if (queryParams.t) {
			const validPresetValues = ReportingTimePreset.map((preset) => preset.value);

			if (validPresetValues.indexOf(queryParams.t) > -1) {
				isSet = true;

				this.setParam("time", queryParams.t);

				if (queryParams.t === "custom" && queryParams.from && queryParams.to) {
					const from = moment(queryParams.from, "X").utc(false);
					const to = moment(queryParams.to, "X").utc(false);

					if (from.isValid() && to.isValid() && from < to) {
						this.setParam("from", from);
						this.setParam("to", to);
					} else {
						this.params.time = "now";
					}
				}
			}
		}

		return isSet;
	}

	@action
	private setReportCompareTimeFrameFromQueryString(queryParams: any): boolean {
		let isSet = false;

		if (queryParams.p) {
			if (_.includes(VALID_PERIODS, queryParams.p)) {
				isSet = true;

				this.setParam("period", queryParams.p);

				if (queryParams.p === "custom" && queryParams.cfrom && queryParams.cto) {
					const from = moment(queryParams.cfrom, "X").utc(false);
					const to = moment(queryParams.cto, "X").utc(false);

					if (from.isValid() && to.isValid() && from < to) {
						this.setParam("compareFrom", from);
						this.setParam("compareTo", to);
					} else {
						this.params.time = VALID_PERIODS[0];
					}
				}
			}
		}

		return isSet;
	}

	@action public applyTimePreset = (preset?: string) => {
		if (preset && preset !== this.params.time) {
			this.params.time = preset;
		}

		switch (this.params.time) {
			case "now":
				this.setDate(1, 1, true);
				break;
			case "today":
				this.setDate(getCurrentHour(), -getCurrentHour() + 24);
				break;
			case "yesterday":
				this.setDate(getCurrentHour() + 24, -getCurrentHour());
				break;
			case "last7days":
				this.setDate(getPastDays(7), -getCurrentHour() + 24);
				break;
			case "weektodate":
				this.setDate(getCurrentWeek(), -getCurrentHour() + 24);
				break;
			case "lastmonth":
				this.setDate(getLastMonth(), -getCurrentMonth());
				break;
			case "monthtodate":
				this.setDate(getCurrentMonth(), -getCurrentHour() + 24);
				break;
		}

		if (this.params.isCompare) {
			this.applyComparePeriod();
		}
	};

	@action public applyComparePeriod = (period?: string) => {
		if (period && period !== this.params.period) {
			this.setParam("period", period);
		}

		if (this.params.period === "custom") {
			return;
		}

		this.params.compareFrom = this.params.from.clone();
		this.params.compareTo = this.params.to.clone();

		const numberOfDiffDays = Math.max(1, this.params.compareTo.diff(this.params.compareFrom, "days"));

		switch (this.params.period) {
			case "previous_period":
				this.params.compareFrom.subtract(numberOfDiffDays, "days");
				this.params.compareTo.subtract(numberOfDiffDays, "days");
				break;
			case "previous_month":
				this.params.compareFrom.subtract(1, "month");
				this.params.compareTo.subtract(1, "month");
				break;
			case "previous_year":
				this.params.compareFrom.subtract(1, "year");
				this.params.compareTo.subtract(1, "year");
				break;
		}
	};

	@action
	public setDate(from: number, to: number, withTimezone?: boolean) {
		if (withTimezone) {
			const offset = this.getTimezoneOffset() / 60;

			from += offset;
			to -= offset;
		}

		transaction(() => {
			this.params.from = moment().utc().minutes(0).second(0).subtract(from, "hour");
			this.params.to = moment().utc().minutes(0).second(0).add(to, "hour");
		});
	}

	private getTimezoneOffset(): number {
		return (moment.tz.zone(this.params.timezone) as any).utcOffset(moment().utc().minutes(0).second(0));
	}

	@action public setParam = (key: keyof IReportingParams, value: any) => {
		if (CUSTOM_DATE_KEYS.indexOf(key) > -1) {
			this.applyTimePreset("custom");
		}

		if (CUSTOM_COMPARE_DATE_KEYS.indexOf(key) > -1) {
			this.applyComparePeriod("custom");
		}

		this.params[key as any] = value;

		if (key === "timezone" && this.params.time === "now") {
			this.applyTimePreset();
		}

		if (key === "isCompare" && this.params.isCompare) {
			this.applyComparePeriod();
		}
	};

	@action public setUIParam = (key: string, value: any) => {
		this.ui[key] = value;
	};

	@action public resetDimensions = () => {
		transaction(() => {
			this.setParam("groups", []);
			this.setParam("filters", []);
		});
	};

	@action public resetMetrics = () => {
		transaction(() => {
			this.setParam("fields", []);
			this.setParam("constraints", []);
		});
	};

	@action public pushReportParam = (key: string, value: string, label: string) => {
		this.params[key] = this.params[key].concat({value, label});
	};

	@action public removeReportParam = (key: string, value: string) => {
		this.params[key] = this.params[key].filter((param) => param.value !== value);
	};

	@action public resetParams = () => {
		this.params = this.initialParams;

		this.applyTimePreset(this.params.time);
	};

	@action public addConstraint = (values?: Partial<IReportingConstraint>) => {
		this.params.constraints = this.params.constraints.concat({
			id: guid(),
			name: "",
			op: "",
			value: "",
			...values
		});

		return this.params.constraints[this.params.constraints.length - 1];
	};

	@action public setConstraintParam = (constraint: IReportingConstraint, key: string, value: any) => {
		constraint[key] = value;
	};

	@action public removeConstraint = (constraint: IReportingConstraint) => {
		this.params.constraints = _.filter<IReportingConstraint>(this.params.constraints, (c) => c.id !== constraint.id);
	};

	@action public addFilter = (): IReportingFilter => {
		const filter = observable<IReportingFilter>({
			id: guid(),
			key: "",
			values: [],
			filterList: [],
			isLoading: false,
			filterValueKey: "",
			filterLabelKey: "",
			allowNew: false,
			exclude: false,
			operator: "",
		});

		this.params.filters = this.params.filters.concat([filter]);

		return filter;
	};

	@action public addSubDimension = () => {
		const subDimension = {
			group: "",
			entities: [],
			id: guid(),
			targeting: {
				types: [],
				operators: []
			}
		};
		this.params.subGroups.push(subDimension);

		return subDimension;
	};

	@action public setFilterParam = (filter: IReportingFilter, key: string, value: any) => {
		if (key === "key") {
			this.setFilterKey(filter, value);
			return;
		}
		filter[key] = value;
	};

	@action public setSubGroupParam = (subGroup: ISubGroup, key: string, value: any) => {
		if (key === "group") {
			this.setSubGroup(subGroup, value);
			return;
		}
		subGroup[key] = value;
	};

	@action public setSubGroup = (subGroup: ISubGroup, group: string) => {
		subGroup.group = group;
		reportingSubGroupsManager.getSubGroupsEntities(group, this.verticalType);

		if (this.params.groups.findIndex((g) => g.value === RequestedGroupValue[group]) === -1) {
			this.pushReportParam("groups", RequestedGroupValue[group], RequestedGroupLabel[group]);
		}
	};

	@action public setFilterKey = (filter: IReportingFilter, key: string): Promise<any> => {
		const filterHandler = reportingFiltersManager.getFilter(key, this.verticalType);

		filter.key = key;
		filter.filterLabelKey = filterHandler.labelKey;
		filter.filterValueKey = filterHandler.valueKey;
		filter.allowNew = filterHandler.allowNew;
		filter.isLoading = filterHandler.isLoading;
		filter.values = [];

		return filterHandler.initialize().then(action(() => {
			filter.filterList = observable.array(filterHandler.items, {deep: false});
			filter.isLoading = filterHandler.isLoading;
		}));
	};

	@action
	public setItemsOnFilterParams() {
		this.params.filters.forEach((filter) => {
			const filterHandler = reportingFiltersManager.getFilter(filter.key, this.verticalType);
			return filterHandler.initialize().then(action(() => {
				filter.filterList = observable.array(filterHandler.items, {deep: false});
				filter.isLoading = filterHandler.isLoading;
			}));
		});
	}

	@action public pushFilterValue = (filter: IReportingFilter, value: string, label: string) => {
		let item: any = value;

		if (filter.filterLabelKey && filter.filterValueKey) {
			item = {
				[filter.filterLabelKey]: label,
				[filter.filterValueKey]: value
			};
		}
		filter.values = filter.values.concat(item);
	};

	@action public pushSubGroupValue = (subGroup: ISubGroup, value: string, label: string) => {
		subGroup.entities = subGroup.entities.concat(value);
	};

	@action public pushSubGroupTargetingType = (subGroup: ISubGroup, value: string, label: string) => {
		subGroup.targeting.types = subGroup.targeting.types.concat(value);
	};

	@action public pushSubGroupTargetingOperator = (subGroup: ISubGroup, value: string, label: string) => {
		subGroup.targeting.operators = subGroup.targeting.operators.concat(value);
	};

	@action public removeFilterValue = (filter: IReportingFilter, value: string) => {
		filter.values = filter.filterValueKey
			? _.filter(filter.values, (item) => item[filter.filterValueKey] !== value)
			: _.filter(filter.values, (item) => item !== value);
	};

	@action public removeSubGroupValue = (subGroup: ISubGroup, value: string) => {
		subGroup.entities = subGroup.entities.filter((item) => item !== value);
	};

	@action public removeSubGroupTargetingType = (subGroup: ISubGroup, value: string) => {
		subGroup.targeting.types = subGroup.targeting.types.filter((item) => item !== value);
	};

	@action public removeSubGroupTargetingOperator = (subGroup: ISubGroup, value: string) => {
		subGroup.targeting.operators = subGroup.targeting.operators.filter((item) => item !== value);
	};

	@action public removeFilter = (filter: IReportingFilter) => {
		this.params.filters = _.filter<IReportingFilter>(this.params.filters, (f) => f.id !== filter.id);
	};

	@action public removeSubGroup = (subGroup: ISubGroup) => {
		this.params.subGroups = this.params.subGroups.filter((sub) => sub.id !== subGroup.id);
	};

	@action public downloadCSV = () => {
		notificationsStore.pushSuccessNotification({
			title: "Generating CSV",
			text: "Please wait...",
			timeout: 5000
		});

		exportToCsvService.exportReport(this.results.result, this.extractedParamsFields);
	};

	@action public exportHistoryReport = (report, params) => {
		notificationsStore.pushSuccessNotification({
			title: "Generating CSV",
			text: "Please wait...",
			timeout: 5000
		});

		exportToCsvService.exportHistoryReport(report, params);
		this.isLoadingReportFromUrls = false;
	};

	private flattenFiltersForQuery(filters: IReportingFilter[]): any {
		return _(filters).map((filter) => {
			if (filter.key && filter.values.length) {
				const group = this.reportingService.getFilterByValue(filter.key, this.verticalType);

				if (group) {
					return {
						key: group.value,
						exclude: filter.exclude,
						operator: filter.operator,
						values: _.map<any, any>(filter.values, (value) => value[filter.filterValueKey] || value)
					};
				}
			}
		}).compact().value();
	}

	private flattenConstraintsForQuery(constraints: IReportingConstraint[]): any {
		return _(constraints).map((constraint) => {

			if (constraint.name && constraint.op && constraint.value) {
				const field = this.reportingService.getFieldByValue(constraint.name, this.verticalType);

				if (field) {
					const {value, label} = field;
					return {
						name: {label, value},
						op: constraint.op,
						value: constraint.value
					};
				}
			}
		}).compact().value();
	}

	public quickConstraint = (item) => {
		this.addConstraint({
			name: this.reportingService.getFieldByLabel(item.name, this.verticalType).value,
			op: ">=",
			value: extractReportingFieldValue(item, "value")
		});
	};

	public quickFilter = (item) => {
		const {originalId, name} = item;
		const dimension = this.reportingService.getGroupByLabel(name === "Browser Name" ? "Browser" : name, this.verticalType);
		let filter = this.params.filters.find((currFilter) => currFilter.key === dimension.value);
		if (!filter) {
			filter = this.addFilter();
			if (name === "Browser Name") {
				this.setFilterParam(filter, "key", "browser");
				this.setFilterParam(filter, "operator", "containAnyOf");
			} else {
				this.setFilterParam(filter, "key", dimension.value);
				this.setFilterParam(filter, "operator", dimension.operators[0]);
			}
		}
		let value = originalId || item.value;
		switch (name) {
			case "Top Domain":
			case "Tag Partner Name":
			case "SSP":
				value = item.value;
				break;
			case "Widget":
			case "Publisher Account":
				value = item.valueDisplay;
				break;
		}
		if (filter.values.some((val) => val === value || val[filter.filterValueKey] === value)) {
			return;
		}

		this.pushFilterValue(filter, value, item.value);
	};

	@action
	public setPage(page) {
		this.ui.page = page;
	}

	@action
	public resetSort(sortDirection?: SortDirection, sortBy?: string) {
		transaction(() => {
			this.ui.sortDirection = sortDirection || SortDirection.ASC;
			this.ui.sortBy = sortBy || "";
		});
	}

	@action public loadMore = () => {
		if (this.filteredResults.length > this.ui.page * this.ui.pageSize) {
			this.setPage(this.ui.page + 1);
		}
	};

	@action public setSearchQuery = (key: string, query: string) => {
		transaction(() => {
			this.setPage(1);
			this.searchQueries[key] = query;
		});
	};

	@action public sort = (sortBy: string, withReverseDir: boolean = true) => {
		if (this.isTransposed) {
			return;
		}
		transaction(() => {

			this.setPage(1);

			if (sortBy === this.ui.sortBy && withReverseDir) {
				this.ui.sortDirection = this.ui.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
			}
			this.ui.sortBy = sortBy;

			let dateFormat = DATE_DIMENSIONS_FORMATS[this.ui.sortBy];
			(this.ui.sortBy === "Day US Format") && (dateFormat = DATE_DIMENSIONS_FORMATS.DayUSFormat)

			const getValue = dateFormat
				? (item: any) => moment(extractReportingFieldValue(item.indexed[this.ui.sortBy], "value"), dateFormat)
				: (item: any) => extractReportingFieldValue(item.indexed[this.ui.sortBy], "value");

			this.results.result.replace(_.orderBy(this.results.result, (item) => getValue(item), this.ui.sortDirection.toString() as any));
		});
	};

	public allowCompare = (): boolean => {
		return true;
	};

	private hasActiveSort() {
		if (!this.ui.sortBy) {
			return false;
		}

		if (this.results.result && this.results.result.length > 0) {
			return typeof this.results.result[0].indexed[this.ui.sortBy] !== "undefined";
		}

		return false;
	}

	@computed
	private get canGetReport(): boolean {
		return (this.params.fields.length > 0 || this.params.groups.length > 0);
	}

	@computed
	public get sortDir(): SortDirection {
		return this.ui.sortDirection;
	}

	@computed get filteredResults() {
		return filterReportingResults(this.results.result, this.searchQueries);
	}

	@computed get paramsFilteredResults(): any {
		const paramsResults = this.paramsResults;

		return filterReportingResults(paramsResults.result, this.searchQueries);
	}

	@computed get dynamicFormulaFields() {
		return (this.paramsResults.result[0] && this.paramsResults.result[0].fields) ? this.paramsResults.result[0].fields.filter((field) => field.type === "formula") : [];
	}

	@computed
	public get paramsResults() {
		const results: any = this.initialResults;
		if (this.results && this.results.groupsByName) {
			const resultsGroups = [];
			this.params.groups.forEach((group) => {
				if (this.results.groupsByName[group.label]) {
					const groupFromRes = JSON.parse(JSON.stringify(group));
					groupFromRes.isGroup = true;
					groupFromRes.name = groupFromRes.label;
					resultsGroups.push(groupFromRes);
				}
			});
			this.params.subGroups.forEach((subGroup) => {
				subGroup.entities.forEach((entity) => {

					let groupFromRes = this.results.groupsByName[entity];

					if (entity === "optionsWidget" && subGroup.freeField) {
						groupFromRes = this.results.groupsByName[`optionsWidget:${subGroup.freeField}`];
					}
					if (groupFromRes) {
						groupFromRes = JSON.parse(JSON.stringify(groupFromRes));
						groupFromRes.isGroup = true;
						groupFromRes.name = groupFromRes.label;
						resultsGroups.push(groupFromRes);
					}
					if (entity === "targeting") {
						subGroup.targeting.types.forEach((targetingType) => {
							subGroup.targeting.operators.forEach((targetingOperator) => {
								const entityTargeting = `targeting:${targetingType}:${targetingOperator}`;
								if (!this.results.groupsByName[entityTargeting]) {
									return;
								}
								const groupFromResTargeting = JSON.parse(JSON.stringify(this.results.groupsByName[entityTargeting]));
								groupFromResTargeting.isGroup = true;
								groupFromResTargeting.name = groupFromResTargeting.label;
								resultsGroups.push(groupFromResTargeting);
							});
						});
					}
				});
			});

			results.groups = resultsGroups;
			results.result = this.results.result.map((row) => {
				const fields = [], groups = [];
				this.extractedParamsFields.forEach((field) => {
					if (field.type === "formula") {
						const dto = row.fields.reduce((acc, currField: any) => {
							acc[currField.name.replaceAll(" ", "")] = currField.value;
							return acc;
						}, {});
						const value = field.formulaFunction ? field.formulaFunction(dto) : compileFieldFormula((field.formula as any).replaceAll(" ", ""))(dto);
						const fieldRes = {...field, value, displayValue: value.toLocaleString()};
						fields.push(fieldRes);
						row.indexed[field.label] = fieldRes;
					} else if (this.results.fieldsByName[field.label]) {
						const fieldRes = row.fields.find((rowField) => rowField.name === field.label);
						fields.push(fieldRes);
					}
				});
				this.params.groups.forEach((group) => {
					if (this.results.groupsByName[group.label]) {
						const groupRes = row.groups.find((rowGroup) => rowGroup.name === group.label);
						groups.push(groupRes);
					}
				});
				this.params.subGroups.forEach((subGroup) => {
					subGroup.entities.forEach((entity) => {
						const groupRes = row.groups.find((rowGroup) => rowGroup.name === entity);
						groupRes && groups.push(groupRes);

						if (entity === "optionsWidget" && subGroup.freeField) {
							const optionsWidgetGroupRes = row.groups.find((rowGroup) => rowGroup.name === `optionsWidget:${subGroup.freeField}`);
							optionsWidgetGroupRes && groups.push(optionsWidgetGroupRes);
						}

						if (entity === "targeting") {
							subGroup.targeting.types.forEach((targetingType) => {
								subGroup.targeting.operators.forEach((targetingOperator) => {
									const entityTargeting = `targeting:${targetingType}:${targetingOperator}`;
									const targetingGroupRes = row.groups.find((rowGroup) => rowGroup.name === entityTargeting);
									targetingGroupRes && groups.push(targetingGroupRes);
								});
							});
						}
					});
				});

				return {
					...row,
					fields,
					groups
				};
			});
		}
		return {
			...this.results,
			...results
		};
	}

	@computed
	public get paramsHeaders() {
		if (!this.results || !this.results.groupsByName || !this.results.fieldsByName) {
			return [];
		}

		const headerByParams = [];
		this.params.groups.forEach((group) => {
			let groupFromRes = this.results.groupsByName[group.label];
			if (groupFromRes) {
				groupFromRes = JSON.parse(JSON.stringify(groupFromRes));
				groupFromRes.isGroup = true;
				headerByParams.push(groupFromRes);
			}
		});

		this.params.subGroups.forEach((subGroup) => {
			subGroup.entities.forEach((entity) => {
				let groupFromRes = this.results.groupsByName[entity];

				if (entity === "optionsWidget" && subGroup.freeField) {
					groupFromRes = this.results.groupsByName[`optionsWidget:${subGroup.freeField}`];
				}

				if (groupFromRes) {
					groupFromRes = JSON.parse(JSON.stringify(groupFromRes));
					groupFromRes.isGroup = true;
					headerByParams.push(groupFromRes);
				}

				if (entity === "targeting") {
					subGroup.targeting.types.forEach((targetingType) => {
						subGroup.targeting.operators.forEach((targetingOperator) => {
							const entityTargeting = `targeting:${targetingType}:${targetingOperator}`;
							let groupFromResTargeting = this.results.groupsByName[entityTargeting];
							if (groupFromResTargeting) {
								groupFromResTargeting = JSON.parse(JSON.stringify(groupFromResTargeting));
								groupFromResTargeting.isGroup = true;
								headerByParams.push(groupFromResTargeting);
							}
						});
					});
				}
			});
		});

		this.extractedParamsFields.forEach((field) => {
			if (this.results.fieldsByName[field.label]) {
				headerByParams.push(this.results.fieldsByName[field.label]);
			}
		});
		return headerByParams;
	}

	@computed get filteredTotalRequested(): any[] {
		const totalFields = {};
		const totalFieldsValues = {};
		const totalResults = this.filteredResults.length;

		this.filteredResults.forEach((item) => {
			item.fields.forEach((field) => {
				const fieldFromContext = this.reportingService.getFieldByLabel(field.name, this.verticalType);
				const fieldKey = fieldFromContext ? fieldFromContext.value : field.name;

				if (!totalFields[field.name]) {

					totalFields[field.name] = {
						name: fieldFromContext ? fieldFromContext.label : field.name,
						type: fieldFromContext ? fieldFromContext.type : FieldType.SUMABLE,
						value: 0,
						isRequested: field.isRequested
					};

					totalFieldsValues[fieldKey] = 0;
				}

				const originalValue = extractReportingFieldValue(field, "value");

				totalFields[field.name].value += originalValue;
				// this is needed because calculated fields" formula expect a simple dictionary (like {adStart: 3, adLoad: 4}
				totalFieldsValues[fieldKey] += originalValue;
			});
		});

		_.forEach(totalFields, (field: any) => {

			const fieldFromContext = this.reportingService.getFieldByLabel(field.name, this.verticalType);

			if (!fieldFromContext) {
				return;
			}

			const format = fieldFromContext.format || defaultFieldFormat;
			const formula = fieldFromContext.formula;

			switch (field.type) {
				case FieldType.SUMABLE:
					field.typeSup = "sum";
					break;
				case FieldType.AVERAGABLE:
					field.typeSup = "avg";
					field.value = field.value / totalResults;
					break;
				case FieldType.CALCULATED:
					field.typeSup = "clc";
					field.value = formula(totalFieldsValues);
					break;
			}

			field.displayValue = format(field.value);
		});

		if (!this.results || !this.results.fieldsByName) {
			return [];
		}
		const filteredTotalFields = [];
		this.extractedParamsFields.forEach((field) => {
			if (this.results.fieldsByName[field.label] && totalFields[field.label] && totalFields[field.label].isRequested) {
				filteredTotalFields.push(totalFields[field.label]);
			}
		});

		return _.values(filteredTotalFields);
	}

	@computed get filteredTotal(): any[] {
		const totalFields = {};
		const totalFieldsValues = {};
		const totalResults = this.filteredResults.length;

		this.filteredResults.forEach((item) => {
			item.fields.forEach((field) => {
				const fieldFromContext = this.reportingService.getFieldByLabel(field.name, this.verticalType);
				const fieldKey = fieldFromContext ? fieldFromContext.value : field.name;

				if (!totalFields[field.name]) {

					field.isRequested && (
						totalFields[field.name] = {
							name: fieldFromContext ? fieldFromContext.label : field.name,
							type: fieldFromContext ? fieldFromContext.type : FieldType.SUMABLE,
							value: 0
						}
					);

					totalFieldsValues[fieldKey] = 0;
				}

				const originalValue = extractReportingFieldValue(field, "value");
				if (field.isRequested) {
					totalFields[field.name].value += originalValue;
				}

				// this is needed because calculated fields" formula expect a simple dictionary (like {adStart: 3, adLoad: 4}
				totalFieldsValues[fieldKey] += originalValue;
			});
		});

		_.forEach(totalFields, (field: any) => {
			const fieldFromContext = this.reportingService.getFieldByLabel(field.name, this.verticalType);

			if (!fieldFromContext) {
				return;
			}
			const format = fieldFromContext.format || defaultFieldFormat;
			const formula = fieldFromContext.formula;

			switch (field.type) {
				case FieldType.SUMABLE:
					field.typeSup = "sum";
					break;
				case FieldType.AVERAGABLE:
					field.typeSup = "avg";
					field.value = field.value / totalResults;
					break;
				case FieldType.CALCULATED:
					field.typeSup = "clc";
					field.value = formula ? formula(totalFieldsValues) : field.value;
					break;
			}

			field.displayValue = format(field.value);
		});

		return _.values(totalFields);
	}

	private get initialResults(): IReportingResults {
		return {
			result: observable([]),
			originResults: [],
			fields: [],
			groups: [],
			totalFields: {}
		};
	}

	@action
	public setParams(params) {
		// this.params.groups.replace(params.groups)
	}

	private get initialParams(): IBiReportingParams {
		const timezone = storageService.getGlobalTimezone() || "Etc/GMT+0";

		return {
			time: "now",
			from: null,
			to: null,
			isCompare: false,
			compareTo: null,
			compareFrom: null,
			period: "previous_period",
			fields: observable.array([]),
			groups: observable.array([]),
			filters: observable.array([]),
			subGroups: observable.array([]),
			constraints: observable.array([]),
			capsules: observable.array([]),
			timezone,
			accountsId: [],
		};
	}

	private get initialUIParams(): IReportingUI {
		return {
			density: Density.Medium,
			page: 1,
			pageSize: 50,
			sortDirection: SortDirection.ASC,
			sortBy: ""
		};
	}

	public generateReportLink = () => {
		const raw: any = {
			t: this.params.time,
			tz: this.params.timezone,
			from: this.params.from.unix(),
			to: this.params.to.unix(),
			fs: this.params.fields.map((x) => x.label),
			gs: this.params.groups.map((x) => x.label),
			f: this.flattenFiltersForQuery(this.params.filters),
			c: this.flattenConstraintsForQuery(this.params.constraints),
			v: this.verticalType,
			xaccounts: sessionStore.selectedAccounts.join(",")
		};

		if (this.params.isCompare) {
			raw.compare = true;
			this.params.compareFrom && (raw.cfrom = this.params.compareFrom.unix());
			this.params.compareTo && (raw.cto = this.params.compareTo.unix());
			this.params.period && (raw.p = this.params.period);
		}

		const params = URLQueryParams.stringify(raw);

		MemoryClipboard.copy(`${window.location.origin}/reporting?${params}`);

		notificationsStore.pushNotification({
			title: "Report Link",
			text: "Copied to your clipboard",
			success: true,
			timeout: 5000
		});
	};

	@action public changeVerticalType = (verticalType: REPORT_VERTICAL_TYPE) => {
		this.verticalType = verticalType;
		this.setInitialParams("");
	};

	@action public changeDbTypes = (value: ReportingDbType) => {
		this.dbType = value;
	};

	@action
	public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
		if (this.isTransposed) {
			this.reportTable.columns.forEach((row, idx) => {
				this.columnsWidthByEntity[idx] = columnsWidth[idx];
			});

		} else {

			const {groups, fields} = this.results.result[0];
			const headers = [...groups, ...fields.filter((field) => field.isRequested)];

			if (this.dynamicFormulaFields.length > 0) {
				headers.push(...this.dynamicFormulaFields);
			}

			for (const index in columnsWidth) {

				if (headers[index]) {
					const {name} = headers[index];
					this.columnsWidthByEntity[name] = columnsWidth[index];
				}
			}

			this.setColumnsWidthDebounce();
		}
	};

	@action
	public async initializeColumnsWidth() {
		const columnsWidthByEntity = await columnsWidthAPI.getColumnsWidth("reporting");
		this.setColumnsWidthByEntity(columnsWidthByEntity.data);
	}

	@action
	private setColumnsWidthByEntity(columnsWidthByEntity) {
		this.columnsWidthByEntity = columnsWidthByEntity;
	}

	@computed
	public get getColumnsWidth(): { [index: string]: number } {
		if (this.results && this.results.result.length > 0) {
			if (this.isTransposed) {
				const columnsWidth = {};

				this.reportTable.columns.forEach((row, idx) => {
					columnsWidth[idx] = this.columnsWidthByEntity[idx] || 100;
				});
				return columnsWidth;

			} else {
				const {groups, fields} = this.results.result[0];
				const headers = [...groups, ...fields.filter((field) => field.isRequested)];

				if (this.dynamicFormulaFields.length > 0) {
					headers.push(...this.dynamicFormulaFields);
				}

				const columnsWidth = {};
				headers.forEach((row, idx) => {
					columnsWidth[idx] = this.columnsWidthByEntity[row.name] || 100;
				});
				return columnsWidth;
			}

		}
	};

	@action
	public toggleReportingSumOnTop = () => {
		this.reportingSumOnTop = !this.reportingSumOnTop;
		storageService.setReportingSumOnTop(this.reportingSumOnTop);
	};

	@action public createPresetReport = (params: any) => {
		presetReportsStore.create(this.getPresetReportParams(params));
	};

	@action public savePresetReport = (preset: IReportingPreset) => {
		presetReportsStore.update(preset._id, this.getPresetReportParams(preset));
	};

	private getPresetReportParams(params: any = {}) {
		return {
			...params,
			vertical: this.verticalType,
			timespan: this.params.time,
			timezone: this.params.timezone,
			groups: this.params.groups.map((g) => g.value),
			fields: this.params.fields.map((f) => f.value),
			constraints: this.flattenConstraintsForQuery(this.params.constraints),
			filters: this.flattenFiltersForQuery(this.params.filters),
			subGroups: this.params.subGroups
		};
	}

	@action public applyAndGenerateReportPreset = (preset: IReportingPreset) => {

		this.applyReportPreset(preset);
		setTimeout(this.getReport, 10);

	};

	@action public applyReportPreset = (preset: IReportingPreset) => {

		presetReportsAPI.updateLastUsed(preset._id);

		transaction(async () => {
			this.params.constraints = [];

			this.verticalType = preset.vertical;

			for (let i = 0, len = preset.constraints.length; i < len; i++) {
				const constraint = preset.constraints[i];

				if (constraint && constraint.name) {
					const field = this.reportingService.getFieldByValue(constraint.name.value || constraint.name, preset.vertical);

					if (field) {
						this.addConstraint({
							name: field.value,
							op: constraint.op,
							value: constraint.value
						});
					}
				}
			}

			this.params.filters = [];

			for (let i = 0, len = preset.filters.length; i < len; i++) {
				const filter = preset.filters[i];

				const entry = this.reportingService.tryGetFilter(filter.key, preset.vertical);

				const newFilter = this.addFilter();

				this.setFilterParam(newFilter, "operator", filter.operator);

				await this.setFilterKey(newFilter, entry.value);

				const stringList = newFilter.allowNew;

				_.forEach(filter.values, (item) => {
					const label = item[newFilter.filterLabelKey] || item.name || item;
					const filterValue = !stringList ? reportingFiltersManager.getFilterListValueByLabel(newFilter, label, preset.vertical) : typeof (item) === "string" ? item : null;

					if (filterValue) {
						this.pushFilterValue(newFilter, filterValue, label);
					} else {
						const filterListLabelByValue = reportingFiltersManager.getFilterListLabelByValue(newFilter, label, this.verticalType);
						if (filterListLabelByValue) {
							this.pushFilterValue(newFilter, label, filterListLabelByValue);
						}
					}
				});
			}

			this.setParam("fields", []);

			_.forEach(preset.fields, (value) => {
				const entry = this.reportingService.tryGetField(value, preset.vertical);

				if (entry) {
					this.pushReportParam("fields", entry.value, entry.label);
				}
			});

			this.setParam("groups", []);

			_.forEach(preset.groups, (value) => {
				const entry = this.reportingService.tryGetGroup(value, preset.vertical);

				if (entry) {
					this.pushReportParam("groups", entry.value, entry.label);
				}
			});

			if (preset.subGroups) {
				preset.subGroups.forEach((subGroup) => {
					reportingSubGroupsManager.getSubGroupsEntities(subGroup.group, this.verticalType);
				});

				runInAction(() => {
					this.params.subGroups = preset.subGroups;
				});
			}

			this.setParam("timezone", preset.timezone);

			const isValidTimePreset = _(ReportingTimePreset).map("value").includes(preset.timespan);

			if (isValidTimePreset) {
				this.applyTimePreset(preset.timespan);
			}
		});
	};

	@action public onClearAllParam = (key: string) => {
		this.params[key] = observable.array([]);
	};

	@action private paramsChange = (change: any) => {
		const clonedParams = toJS(this.params);
		this.historyParams.push(clonedParams);
		if (this.historyParams.length > 100) {
			this.historyParams.shift();
		}
		return change;
	};

	@action public rollBackParams = () => {
		if (this.historyParams && this.historyParams.length > 0) {
			const lastParams = this.historyParams[this.historyParams.length - 1];
			this.params = observable(lastParams);
			intercept(this.params, this.paramsChange);
			this.historyParams.pop();
		}
	};

	@action
	public setTotalRows(header, totalResults) {
		header.displayValue = `Total Rows: ${totalResults}`;
	}

	@action public onMultiplePaste = async (param: string, filter?: IReportingFilter) => {
		try {
			const content = await navigator.clipboard.readText(); // while working on local ENV please change to localhost instead of vidazoo.local.com (devServer.js)
			if (!content) {
				return notificationsStore.pushErrorNotification({
					title: "No Clipboard Data",
					text: "Your clipboard is empty.",
					timeout: 10000
				});
			}

			let items = this.splitForPaste(content);

			param === "filters" ? (items = this.prepareForPasteByFilter(items, filter)) : (items = this.prepareForPaste(param, items));

			if (items.length === 0) {
				notificationsStore.pushErrorNotification({
					title: "Oops!",
					text: "Paste data does not match.",
					timeout: 10000
				});
			}

			runInAction(() => {
				param === "filters" ? (filter.values = filter.values.concat(items)) : (this.params[param] = this.params[param].concat(items as unknown as IReportingEntry[]));
			});

		} catch (e) {
			notificationsStore.pushErrorNotification({
				title: "No Clipboard permission",
				text: "Please check browser clipboard permission.",
				timeout: 10000
			});
		}
	};

	private splitForPaste(clipboardData: string): string[] {
		if (clipboardData.includes(",")) {
			return clipboardData.split((","));

		} else if (clipboardData.includes("\n")) {
			return clipboardData.split(/[\r\n]+/);

		} else if (clipboardData.includes("\t")) {
			return clipboardData.split(("\t"));
		}
		return [clipboardData];
	}

	private prepareForPasteByFilter(items: string[], filter: IReportingFilter) {
		if (filter.filterList.length > 0 && filter.filterValueKey && filter.filterLabelKey) {
			const newItems = [];
			const currentValuesLabels = filter.values.map((value: any) => value[filter.filterLabelKey].toLowerCase());
			items.forEach((item) => {
				item = item.trim().toLowerCase();
				for (const allowedFilter of filter.filterList) {
					if (allowedFilter[filter.filterLabelKey].toLowerCase() === item && !currentValuesLabels.includes(item)) {
						newItems.push({
							[filter.filterLabelKey]: allowedFilter[filter.filterLabelKey],
							[filter.filterValueKey]: allowedFilter[filter.filterValueKey]
						});
					}
				}
			});
			return newItems;
		}
		return items;
	}

	private prepareForPaste(param: string, items: string[]) {
		const newItems = [];
		const currentValueLabels = this.params[param].map((p) => p.label.toLowerCase());
		items.forEach((item) => {
			item = item.trim().toLowerCase();
			for (const allowedItem of metaDataStore.metaDataByVertical[this.verticalType][param]) {
				if (allowedItem.label.toLowerCase() === item && !currentValueLabels.includes(item)) {
					newItems.push(allowedItem);
					return;
				}
			}
		});
		return newItems;
	}

	@action
	public toggleTransposed = () => {
		this.isTransposed = !this.isTransposed;
	};

	@action
	public addMetric = (value: string, label: string, isNew: boolean, name: string, item?: IReportingEntry) => {
		if (item && item.type === "capsule") {
			this.params.fields = this.params.fields.concat(item);
		} else {
			this.pushReportParam("fields", value, label);
		}
	};

	private get extractedParamsFields() {
		return this.extractParamsFields();
	}

	private extractParamsFields(clearDuplicated?: boolean) {
		let allFields = [];
		let capsulesFields = [];
		const basicFields = [];
		this.params.fields.forEach((entry) => {
			if (entry.type === "capsule" && entry["fields"]) {
				const capsuleFields = [];
				entry["fields"].forEach((fieldId: string) => {
					const field = this.reportingService.getFieldById(fieldId, this.verticalType);
					field && capsuleFields.push(field);
				});
				allFields = allFields.concat(capsuleFields);
				capsulesFields = capsulesFields.concat(capsuleFields.map((field) => field.label));
			} else {
				allFields.push(entry);
				basicFields.push(entry.label);
			}
		});
		if (clearDuplicated) {
			this._handleClearFieldsDuplicated(capsulesFields, basicFields);
		}

		return _.uniq(allFields);
	}

	private _handleClearFieldsDuplicated(capsulesFields: string[], basicFields: string[]) {
		let removed = "";
		basicFields.forEach((field) => {
			if (capsulesFields.includes(field)) {
				removed += `${field}, `;
				this.removeReportParam("fields", this.reportingService.getFieldByLabel(field, this.verticalType).value);
			}
		});
		if (removed) {
			notificationsStore.pushWarningNotification({
				title: "Metrics removed",
				text: `${removed} already exists in one of the capsules selected`,
				timeout: 10000
			});
		}
	}

	@action
	public breakdownCapsules = () => {
		this.params.fields = this.extractedParamsFields;
	};

	@action public getReportFromReportingHistoryUrls = (item: IReportHistory, getUrlAction: GetUrlAction) => {
		if (this.isLoadingReportFromUrls) {
			notificationsStore.pushWarningNotification({
				title: "Please wait...",
				text: "We already try to get report",
				timeout: 5000
			});

			return;
		}
		this.isLoadingReportFromUrls = true;
		reportingHistoryAPI.getReportFromUrls(item._id)
			.then((res) => this.onLoadReportFromUrlsSuccess(res, getUrlAction, item))
			.catch((e) => this.onLoadReportFromUrlsError());
	};

	@action
	public onLoadReportFromUrlsSuccess = (res: any, getUrlAction: GetUrlAction, item: IReportHistory) => {
		if (res.data) {
			axios.get(res.data).then((reportRes) => {
				switch (getUrlAction) {
					case GetUrlAction.Reuse:
						historyReportsStore.setSelectedReport(item);
						this.onReportReady({item, report: reportRes.data}, true);
						break;
					case GetUrlAction.Generate:
						historyReportsStore.setSelectedReport(item);
						this.onReportReady({item, report: reportRes.data});
						break;
					case GetUrlAction.Download:
						this.exportHistoryReport(reportRes.data, item.params);
						break;
				}
			});
		}
	};
	public onLoadReportFromUrlsError = () => {
		this.isLoading = false;

		notificationsStore.pushErrorNotification({
			title: "Oops!",
			text: "Load Report From Urls Failed",
			timeout: 10000
		});
	};

	@action
	public createDynamicFormula = () => {
		const id = guid();

		const reportingEntry: any = {
			type: "formula",
			label: this.editDynamicFormula.name,
			formulaFunction: compileFieldFormula((this.editDynamicFormula.formula as any).replaceAll(" ", "")),
			formula: this.editDynamicFormula.formula,
			isGroup: false,
			name: this.editDynamicFormula.name,
			isRequested: true,
			id: this.editDynamicFormula.id || id
		};

		if (!this.editDynamicFormula.id) {
			this.results.fieldsByName[this.editDynamicFormula.name] = reportingEntry;
			this.params.fields.push(reportingEntry);
		} else {
			const index = this.params.fields.findIndex((field: any) => field.id === this.editDynamicFormula.id);
			if (index !== -1) {
				this.params.fields.splice(index, 1, reportingEntry);
				this.results.fieldsByName[this.editDynamicFormula.name] = reportingEntry;
			}
		}
	};

	@action public setDynamicFormula = (dynamicFormula: IDynamicFormula) => {
		this.editDynamicFormula = dynamicFormula;
	};

	@action public onChangeDynamicFormula = (value: string, key: string) => {
		this.editDynamicFormula[key] = value;
	};

	@action
	public onChangeSample = (value: number) => {
		this.params.sample = value;
	}
}
