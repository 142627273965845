import { BaseAPI } from "@vidazoo/ui-framework";
import {IConfigurations} from "../interfaces/IConfigurations";

class CloudsAPI extends BaseAPI {

	public getMyCloud() {
		return this.request({
			method: "get",
			url: `/api/clouds/me`,
		});
	}

	public updateMyCloud(configurations: IConfigurations) {
		return this.request({
			method: "patch",
			url: `/api/clouds/me`,
			data: {configurations}
		});
	}

}

export default new CloudsAPI(window.__BASE_API_URL__);
