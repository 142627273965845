import * as React from "react";
import * as theme from "styles/_baseForm.scss";
import {withRouter} from "react-router-dom";
import {observer} from "mobx-react";
import {RouteComponentProps} from "react-router";
import {BaseContainer} from "@vidazoo/ui-framework";
import {Surface, Form, Button, SliderPanel} from "@vidazoo/ui";
import {FormMode} from "@vidazoo/ui-framework";
import {IBreadcrumb} from "@vidazoo/ui/lib/components/breadcrumbs";
import {appUrlsService} from "common/services";
import CreateDashboardStore from "../../stores/CreateDashboardStore";
import duplicateEntityManager from "common/managers/duplicateEntityManager";
import {Entity} from "common/enums";
import DetailsForm from "./DetailsForm";
import ChartsForm from "./ChartsForm";

export interface ICreateDashboardProps extends RouteComponentProps<any> {
}

export interface ICreateDashboardState {
}

class CreateDashboard extends BaseContainer<ICreateDashboardProps, ICreateDashboardState> {

	public static defaultProps: Partial<ICreateDashboardProps> = {};

	private store: CreateDashboardStore;

	constructor(props, context) {
		super(props, context);

		this.store = new CreateDashboardStore(
			duplicateEntityManager.getEntityDuplication(Entity.Dashboard)
		);
	}

	public componentWillMount() {
		this.store.getAllCharts();
	}

	private getBreadcrumbs(): IBreadcrumb[] {
		return [{
			label: "Dashboards",
			iconName: "icon-scenarios",
			link: {to: appUrlsService.dashboards()}
		}, {
			label: "Create Dashboard",
			primary: true
		}];
	}

	private onSubmit = () => {
		this.store.submit();
	};

	private onCancelClick = () => {
		const {history} = this.props;

		history.push(appUrlsService.dashboards());
	};

	public render(): JSX.Element {
		return (
			<Form className={theme.form} onSubmit={this.onSubmit}>
				<Surface className={theme.surface} level={1}>
					<DetailsForm store={this.store} mode={FormMode.Create}/>
				</Surface>
				<ChartsForm store={this.store}/>
				<SliderPanel>
					<div className={theme.actions}>
						<Button type="button" medium gray uppercase onClick={this.onCancelClick}>Cancel</Button>
						<Button type="submit" medium blue uppercase>Create</Button>
					</div>
				</SliderPanel>
			</Form>
		);
	}
}

export default withRouter<any, any>(observer(CreateDashboard));
