import {REPORT_VERTICAL_TYPE} from "../../../../../common/enums";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import BaseFilter from "../BaseFilter";
import {action} from "mobx";
import {metaDataStore} from "../../../../../common/stores";

export default class UserFilter extends BaseFilter {

	public static type = PlatformReportingFilterType.User;

	constructor() {
		super();

		this.allowNew = false;
		this.labelKey = "username";
		this.valueKey = "_id";
	}

	@action public initialize(): Promise<any> {
		this.setData()
		return Promise.resolve();
	}

	@action private setData() {
		this.items = metaDataStore.accountsByVertical[REPORT_VERTICAL_TYPE.PLATFORM];
		this.isLoading = false;
	}
}
