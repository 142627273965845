import * as React from "react";
import {observer} from "mobx-react";
import {RouteComponentProps} from "react-router";
import {
	Surface,
	Form,
	Button,
	PageContainer,
	SliderPanel,
	DateTimeRangePicker,
	LinkButton,
	GroupButton, ButtonsGroup
} from "@vidazoo/ui";
import ChangeLogsContext from "../../containers/ChangeLogsContext";
import * as moment from "moment";
import * as theme from "./theme.scss";
import ChangeLogsFilter from "../changeLogsFilter/ChangeLogsFilter";
import {ReportingTimePreset} from "@vidazoo/ui-framework";

export interface IChangeLogsHeaderProps extends RouteComponentProps<any> {

}

export interface IChangeLogsHeaderState {
	hideFilters: boolean;
}

const dateTimeRangePickerProps: any = {
	fromPickerProps: {
		dateProps: {
			className: theme.date,
			small: true,
			format: "DD/MM/YY"
		},
		hourProps: {
			className: theme.hour,
			small: true,
		}
	},
	toPickerProps: {
		dateProps: {
			className: theme.date,
			small: true,
			format: "DD/MM/YY"
		},
		hourProps: {
			className: theme.hour,
			small: true
		}
	}
};

@observer
export default class ChangeLogsHeader extends React.Component<IChangeLogsHeaderProps, IChangeLogsHeaderState> {

	public static defaultProps: Partial<IChangeLogsHeaderProps> = {};
	public static contextType = ChangeLogsContext;

	constructor(props, context) {
		super(props, context);

		this.state = {hideFilters: false};
	}

	private onFromChange = (from: moment.Moment) => {
		const {setParam} = this.context.store;
		setParam("from", from);
	};

	private onToChange = (to: moment.Moment) => {
		const {setParam} = this.context.store;
		setParam("to", to);
	};

	private toggleHideFilters = () => {
		this.setState({hideFilters: !this.state.hideFilters});
	};

	private onPresetChange = (preset: string) => {
		const {applyTimePreset} = this.context.store;

		applyTimePreset(preset);
	};

	public render(): JSX.Element {
		const {
			params,
			isLoading,
			addFilter,
			getItems,
			clearAllFilters,
			activePreset,
			generateChangelogLink
		} = this.context.store;

		if (params) {
			return (
				<Surface className={theme.header}>
					<ButtonsGroup flat={window.innerWidth < 600} onChange={this.onPresetChange}
								  active={activePreset} disabled={isLoading}
								  className={theme.preset}>
						{ReportingTimePreset.map((preset) => <GroupButton key={preset.value}
																		  name={preset.value}>{preset.label}</GroupButton>)}
					</ButtonsGroup>
					<div className={theme.top}>
						<DateTimeRangePicker
							fromDate={params.from}
							toDate={params.to}
							onFromChange={this.onFromChange}
							onToChange={this.onToChange}
							enableAllDay={false}
							{...dateTimeRangePickerProps}
						/>
						{params.filters.length > 0 ? (
							<Button type="button" medium flat uppercase
									iconName={this.state.hideFilters ? "icon-arrow-down-small" : "icon-arrow-up-small"}
									onClick={this.toggleHideFilters}>{this.state.hideFilters ? `Show Filters (${params.filters.length})` : "Hide Filters"}</Button>
						) : null}
						{params.filters.length > 0 ? (
							<Button type="button" medium flat uppercase onClick={clearAllFilters}>Clear all
								Filters</Button>
						) : null}
						<Button type="button" medium flat uppercase iconName="icon-plus-small" onClick={addFilter}>Add
							Filter</Button>
					</div>

					{!this.state.hideFilters ? (
						<div>
							{params.filters.map((filter, index) => <ChangeLogsFilter key={index} filter={filter}
																					 index={index}/>)}
						</div>
					) : null}

					<div className={theme.actions}>
						<Button type="button" uppercase green medium onClick={generateChangelogLink}>Copy Change Logs
							Link</Button>
						<Button type="button" uppercase blue medium onClick={getItems}>Get Change logs</Button>
					</div>
				</Surface>
			);
		}
	}
}
