import {observable, action, computed, transaction} from "mobx";
import {BaseListStore} from "@vidazoo/ui-framework";
import {IScheduleReport} from "../interfaces/IScheduleReport";
import IUser from "../../../common/interfaces/IUser";
import INetwork from "../../../common/interfaces/INetwork";
import {notificationsStore} from "../../../common/stores";
import scheduleReportsAPI from "../api/scheduleReportsAPI";
import {storageService} from "../../../common/services";

const SCHEDULE_REPORTS_SEARCHABLE_FIELDS = ["name", "isActive", "schedule", "dateRange", "lastSchedule", "nextSchedule", "user.username"];
const SCHEDULE_REPORTS_LIST_FIELDS = {
	name: 1,
	isActive: 1,
	schedule: 1,
	dateRange: 1,
	lastSchedule: 1,
	nextSchedule: 1,
	created: 1,
	errorCount: 1
};

export default class ScheduleReportsStore extends BaseListStore<IScheduleReport, IUser, INetwork> {
	constructor() {
		super(notificationsStore, SCHEDULE_REPORTS_SEARCHABLE_FIELDS, "schedule report", "name");
	}

	@action public getItems = () => {
		if (this.isLoading) {
			return;
		}

		transaction(() => {
			this.isLoading = true;
			this.resetSearchQueries();
			this.setPromptDeleteItem(null);
		});

		const sort = {
			[this.sortBy]: this.sortDir
		};

		scheduleReportsAPI.getAll(1, 10000, {}, SCHEDULE_REPORTS_LIST_FIELDS, sort)
			.then((res) => this.onLoadItemsSuccess(res))
			.catch((res) => this.onLoadItemsError(res));
	};

	@action public toggleActiveState = (item: IScheduleReport) => {
		item.isActive = !item.isActive;

		scheduleReportsAPI.active(item._id, item.isActive)
			.then(() => this.onUpdateStateSuccess())
			.catch(() => this.onUpdateStateError(item));
	};

	@action public deleteItem = () => {
		scheduleReportsAPI.delete(this.promptDeleteItem._id)
			.then(() => this.onDeleteItemSuccess(this.promptDeleteItem._id))
			.catch(() => this.onDeleteItemError());
	};

	public setColumnsWidth = (columnsWidth: { [index: string]: number }) => {
		storageService.setColumnsWidth(`scheduleReports`, columnsWidth);
	};

	public getColumnsWidth = (): { [index: string]: number } => {
		return storageService.getColumnsWidth(`scheduleReports`);
	};
}
