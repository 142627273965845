import * as _ from "lodash";
import {IReportingFilter, IDictionary} from "@vidazoo/ui-framework";
import IReportingFilterHandler from "./IReportingFilterHandler";
import {action, observable} from "mobx";

export default class BaseFilter<T = any> implements IReportingFilterHandler<T> {

	public static type;
	@observable public isLoading: boolean;
	@observable public items: T[];
	public valueKey: string;
	public labelKey: string;
	public allowNew: boolean;
	protected initialized: boolean;
	private valueByLabel: IDictionary<any>;
	private labelByValue: IDictionary<any>;

	constructor() {
		this.reset();
	}

	@action private reset() {
		this.items = [];
		this.valueKey = "";
		this.labelKey = "";
		this.valueByLabel = null;
		this.labelByValue = null;
		this.allowNew = true;
		this.initialized = false;
	}

	@action public initialize(): Promise<any> {
		this.isLoading = false;
		this.initialized = true;

		return Promise.resolve();
	}

	public getValueByLabel(label: string): any {
		if (this.valueKey && this.labelKey) {
			if (!this.valueByLabel) {
				this.indexItems();
			}

			return this.valueByLabel[label];
		}

		return label;
	}

	public getLabelByValue(value: string): any {
		if (this.valueKey && this.labelKey) {
			if (!this.labelByValue) {
				this.indexItems();
			}

			return this.labelByValue[value];
		}

		return value;
	}

	private indexItems() {
		(!this.valueByLabel && (this.valueByLabel = {}));
		(!this.labelByValue && (this.labelByValue = {}));

		_.forEach(this.items, (item) => {
			this.valueByLabel[item[this.labelKey]] = item[this.valueKey];
			this.labelByValue[item[this.valueKey]] = item[this.labelKey];
		});
	}

	public reportify(filter: IReportingFilter): { key: string; exclude: boolean; values: string[]; operator: string; } | null {
		const values = _.compact(filter.values);

		if (values.length) {
			return {
				key: filter.key,
				exclude: filter.exclude,
				operator: filter.operator,
				values: _.map(values, (value: any) => _.isObject(value) ? value[this.valueKey] : value)
			};
		}

		return null;
	}
}
