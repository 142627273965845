import * as React from "react";
import * as theme from "./theme.scss";
import {DataGridColumn, Switch, LinkButton, DataGridRow, Checkbox, Tooltip, Icon, Loader, TableCell} from "@vidazoo/ui";
import * as moment from "moment";
import {Link} from "react-router-dom";
import {IScheduleReport} from "../../../interfaces/IScheduleReport";
import {appUrlsService} from "../../../../../common/services";

export interface IScheduleReportRowProps {
	item: IScheduleReport;
	sortBy: string;
	onActiveChange: (item: IScheduleReport) => void;
	onSyncChange: (item: IScheduleReport) => void;
	onRpmGroupChange: (item: IScheduleReport) => void;
	onSpsGroupChange: (item: IScheduleReport) => void;
	onRpsGroupChange: (item: IScheduleReport) => void;
	onEdit: (item: IScheduleReport) => void;
	onDelete: (item: IScheduleReport) => void;
	onToggle: (id: string) => void;
	isSelected: boolean;
	isMultiSelectedAccounts: boolean;
	columnsWidth: any;
	freezeColumnIndex: number;
	index: number;
	tagsByAbTest?: { string: Array<{ parentId: string, percent: number }> };
	isVidazooOrganization: boolean;
	isLoadingReportingData: boolean;
	isLoadingDemandPartnersDone: boolean;
	isLoadingPrebidParamsStringsData: boolean;
}

export interface IScheduleReportRowRowState {

}

export default class ScheduleReportRow extends React.Component<IScheduleReportRowProps, IScheduleReportRowRowState> {

	public static defaultProps: Partial<IScheduleReportRowProps> = {};

	constructor(props, context) {
		super(props, context);
	}

	private onActiveChange = () => {
		const {onActiveChange, item} = this.props;
		onActiveChange(item);
	};

	private onDelete = () => {
		const {onDelete, item} = this.props;
		onDelete(item);
	};

	private onEdit = () => {
		const {item, onEdit} = this.props;
		onEdit(item);
	};

	public render(): JSX.Element {

		const {
			item,
			index,
			columnsWidth,
			freezeColumnIndex,
		} = this.props;
		const message = `The report failed ${item.errorCount} times`;

		return (
			<DataGridRow className={theme.row} index={index} columnsWidth={columnsWidth} key={index}
						 freezeColumnIndex={freezeColumnIndex}>
				<DataGridColumn className={theme.column}>
					<div className={theme.name}>
						{item.errorCount > 0 ? <Tooltip position="right" title={message}>
							<div className={theme.errorIcon}>
								<Icon iconName="icon-rejected-status" size={3} iconColor="red"/>
							</div>
						</Tooltip> : null}
						<Link to={appUrlsService.editScheduleReport(item._id)}>{item.name}</Link>
					</div>
				</DataGridColumn>
				<DataGridColumn className={theme.column}>
					{item.schedule}
				</DataGridColumn>
				<DataGridColumn className={theme.column}>
					{item.dateRange}
				</DataGridColumn>
				<DataGridColumn className={theme.column}>
					{moment(item.lastSchedule, "X").isValid() ? moment(item.lastSchedule, "X").format("MMM DD, YYYY") : "-"}
				</DataGridColumn>
				<DataGridColumn className={theme.column}>
					{moment(item.nextSchedule, "X").format("MMM DD, YYYY")}
				</DataGridColumn>
				<DataGridColumn className={theme.column}>
					{moment(item.created).format("MMM DD, YYYY")}
				</DataGridColumn>
				<DataGridColumn className={theme.column}>
					<Switch small checked={item.isActive} onChange={this.onActiveChange}/>
				</DataGridColumn>
				<DataGridColumn className={theme.column}>
					<div className={theme.links}>
						<LinkButton onClick={this.onEdit} iconName="icon-edit" iconSize={1.8}/>
						<LinkButton onClick={this.onDelete} iconName="icon-delete" iconSize={1.8}/>
					</div>
				</DataGridColumn>
			</DataGridRow>
		);
	}
}
