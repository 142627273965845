import * as React from "react";
import {
	DataGrid,
	DataGridHeaderColumn,
	DataGridRow,
	SortDirection,
	IDataGridHeader,
	Density,
	Checkbox
} from "@vidazoo/ui";
import {observer} from "mobx-react";
import {IDictionary} from "@vidazoo/ui-framework/lib/interfaces";
import ChartRow from "./ChartRow";
import {ChartTypes} from "../../../../common/enums";

export interface IDataGridHeaderCharts extends IDataGridHeader {
	searchOptions?: Array<{ value: string, label: string }>;
}

const headers: IDataGridHeaderCharts[] = [
	{
		label: "Name",
		sortBy: "name",
		searchKey: "name"
	},
	{
		label: "Type",
		sortBy: "type",
		searchKey: "type",
		searchOptions: ChartTypes
	},
	{
		label: "Date",
		sortBy: "created",
	},
	{
		label: "Updated",
		sortBy: "updated",
	},
	{
		label: "Active",
		sortBy: "isActive",
	}, {
		label: "Actions"
	}];

export interface IChartsListProps {
	items: any;
	sortBy: string;
	onSort: (sortBy: string, preserveDirection?: boolean) => void;
	onEdit: (item: any) => void;
	density: Density;
	onSearch: (searchKey: string, value: string) => void;
	onDelete: (item: any) => void;
	toggleItem: (id: string) => void;
	sortDirection: SortDirection;
	searchQueries: IDictionary<string>;
	onActiveChange: (item: any) => void;
	selectedValues: string[];
	toggleAllItems: () => void;
	allItemsChecked: boolean;
}

export interface IChartsListState {
}

@observer
export default class ChartsList extends React.Component<IChartsListProps, IChartsListState> {

	constructor(props, context) {
		super(props, context);
	}

	private renderHeaderAndFooter: any = (scrollTop, scrollLeft, columnsWidth, setFreesColumn, freezeColumnIndex) => {
		const {sortDirection, onSearch, sortBy, onSort, searchQueries, allItemsChecked, toggleAllItems} = this.props;
		return (
			<DataGridRow columnsWidth={columnsWidth}>
				<DataGridHeaderColumn index={0} columnsWidth={columnsWidth}>
					<Checkbox checked={allItemsChecked} onChange={toggleAllItems}/>
				</DataGridHeaderColumn>

				{headers.map((header: IDataGridHeaderCharts, index) => {
					const props = {
						blue: true,
						label: header.label,
						sortable: !!header.sortBy,
						sortBy: header.sortBy,
						sortActive: sortBy === header.sortBy,
						searchKey: header.searchKey,
						searchable: !!header.searchKey,
						searchQuery: searchQueries[header.searchKey || ""],
						searchOptions: header.searchOptions,
						index: index + 1,
						freezeColumnIndex,
						columnsWidth,
						setFreesColumn,
						sortDirection,
						onSort,
						onSearch,
					};
					return (
						<DataGridHeaderColumn {...props}/>
					);
				})}
			</DataGridRow>
		);
	};

	private renderRow: any = (index, viewportIndex, columnsWidth) => {
		const {items, onActiveChange, onEdit, onDelete, sortBy, selectedValues, toggleItem} = this.props;

		return (<ChartRow sortBy={sortBy}
						  columnsWidth={columnsWidth}
						  onActiveChange={onActiveChange}
						  onEdit={onEdit}
						  isSelected={selectedValues.indexOf(items[index]._id) > -1}
						  onToggle={toggleItem}
						  onDelete={onDelete}
						  item={items[index]}/>);
	};

	public render() {
		const {items} = this.props;
		return (
			<DataGrid
				extraData={items}
				headersCount={headers.length}
				itemsCount={items.length}
				renderRow={this.renderRow}
				renderHeader={this.renderHeaderAndFooter}/>
		);
	}
}
