import availablePrebidAdapters from "../../../../../common/availablePrebidAdapters";
import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import {TagTypeLabels, TagTypes} from "../../../../../common/enums";
import {ILabelValuePair, PrebidAdapters} from "@vidazoo/ui-framework";
import {PrebidAdapter} from "@vidazoo/ui-framework/lib/common/enums";
import {currentUserStore} from "../../../../../common/stores";

export default class TagTypePrebidFilter extends BaseFilter {

	public static type = PlatformReportingFilterType.TagTypePrebid;

	constructor() {
		super();

		this.allowNew = true;
		this.labelKey = "label";
		this.valueKey = "value";
		this.items = [...getAdapters(availablePrebidAdapters, currentUserStore.isVidazooNetwork()), ...TagTypeLabels, ...TagTypes];
	}
}

function getAdapters(availableAdapters: PrebidAdapter[], isVidazooUser: boolean): ILabelValuePair[] {
	if (!availableAdapters || !availableAdapters.length || isVidazooUser) {
		return PrebidAdapters;
	}

	return PrebidAdapters.filter((item) => availableAdapters.includes(item.value as PrebidAdapter));
}
