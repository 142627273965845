import { BaseAPI } from "@vidazoo/ui-framework";

class ColumnsWidthAPI extends BaseAPI {

	public getColumnsWidth(entity: string) {
		return this.request({
			method: "get",
			url: `/api/columns-width/get/${entity}`,
		});
	}

	public setColumnsWidth(entity: string, columnsWidth: any) {
		return this.request({
			method: "patch",
			url: `/api/columns-width/set`,
			data: {columnsWidth, entity}
		});
	}

}

export default new ColumnsWidthAPI(window.__BASE_API_URL__);
