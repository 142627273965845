import * as React from "react";
import * as PropTypes from "prop-types";
import {observer} from "mobx-react";
import {Layout} from "react-grid-layout";
import {CircleLoader, ProgressBar, Surface} from "@vidazoo/ui";
import * as theme from "../../../dashboard/containers/dashboard/theme.scss";
import {DayField} from "@vidazoo/ui-framework";
import IChart from "../../interfaces/IChart";
import {ChartType} from "../../../../common/enums";
import DiffTable from "../../../dashboard/containers/dashboard/diffTable/DiffTable";
import PerformanceChart from "../../../dashboard/containers/dashboard/performanceChart";
import Summary from "../../../dashboard/containers/dashboard/summary";
import * as chartTheme from "./theme.scss";
import {toJS} from "mobx";

export interface IChartDisplayProps {
	chartReport: any;
	chart: IChart;
	isLoadingReport: boolean;
	columnsWidth?: {
		[index: string]: number;
	};
	setColumnsWidth?: (columnsWidth: { [index: string]: number }) => void;
}

function ChartDisplay({
						  chartReport,
						  chart,
						  isLoadingReport,
						  columnsWidth,
						  setColumnsWidth
					  }: IChartDisplayProps): JSX.Element {

	if (!chartReport || isLoadingReport) {
		return (
			<Surface className={theme.center}>
				<CircleLoader size={8} gray/>
			</Surface>
		);
	}

	if (chart) {
		let component;
		let classname;
		switch (chart.type) {
			case ChartType.DATA_TABLE:
				component = <DiffTable columnsWidth={columnsWidth} setColumnsWidth={setColumnsWidth}
									   listClassName={chartTheme.list} chart={chart} report={chartReport}/>;
				classname = theme.table;
				break;
			case ChartType.SUMMARY:
				component = <Summary fields={chartReport}/>;
				classname = theme.summary;
				break;
			case ChartType.PERFORMANCE:
				component = <PerformanceChart isLoading={isLoadingReport}
											  series={chartReport.series}
											  dayField={DayField.DATE}
											  height="100%"
											  labels={chartReport.labels}/>;
				classname = theme.summary;
				break;
		}

		return (
			<div className={classname} style={{marginBottom: 30}}>
				<Surface>
					{component}
				</Surface>
			</div>
		);
	}

	return (
		<Surface>
			No Data!
		</Surface>
	);
}

export default observer(ChartDisplay);
