import BaseNeededGroup from "../BaseNeededGroup";
import {REPORT_VERTICAL_TYPE} from "../../../../../common/enums";
import {OpenRtbNeededGroups} from "../NeededGroups";
import {OpenRtbReportingFilterType} from "../ReportingFilterType";

export default class ExchangeFilter extends BaseNeededGroup {

	public static type = OpenRtbReportingFilterType.Exchange;

	constructor() {
		super(REPORT_VERTICAL_TYPE.OPEN_RTB, OpenRtbNeededGroups.Exchanges);
	}
}
