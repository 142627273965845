import * as React from "react";
import * as PropTypes from "prop-types";
import {observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {
	ButtonsList,
	PageTitle,
	Modal,
	ConfirmPopMessage,
	Button,
	Loader,
	Label,
	PageMessage,
	PageContainer, HLine
} from "@vidazoo/ui";
import {RouteComponentProps} from "react-router";
import * as theme from "styles/_baseList.scss";
import {appUrlsService} from "common/services";
import {AddItemButton} from "common/components";
import IDashboard from "../../interfaces/IDashboard";
import {cloudStore, dashboardsStore} from "../../../../common/stores";
import DashboardsGrid from "./DashboardsGrid";

export interface IDashboardsProps extends RouteComponentProps<any> {

}

export interface IDashboardsState {
	activeBulkAction: string;
}

class Dashboards extends React.Component<IDashboardsProps, IDashboardsState> {

	public static defaultProps: Partial<IDashboardsProps> = {};

	constructor(props, context) {
		super(props, context);

		this.state = {activeBulkAction: null};
	}

	public componentDidMount() {
		dashboardsStore.getItems();
	}

	private cancelDeleteItem = () => {
		dashboardsStore.setPromptDeleteItem(null);
	};

	private onAddItem = () => {
		const {history} = this.props;

		history.push(appUrlsService.createDashboard());
	};

	private onEditItem = (item: IDashboard) => {
		const {history} = this.props;

		history.push(appUrlsService.editDashboard(item._id));
	};

	private onBulkActionClick = (e) => {
		const action = e.currentTarget.name;

		this.setState({activeBulkAction: action});
	};

	private dismissBulkAction = () => {
		this.setState({activeBulkAction: null});
	};

	private confirmBulkAction = () => {
		const {activeBulkAction} = this.state;

		this.dismissBulkAction();

		dashboardsStore.applyBulkAction(activeBulkAction);
	};

	private toggleAllItems = () => {
		dashboardsStore.toggleAllItems(false);
	};

	private renderLoader(): JSX.Element {
		return (
			<div className={theme.message}>
				<Loader size={1} gray className={theme.loader}/>
				<PageMessage
					small
					title="Loading Dashboards"
					description="Please wait..."
				/>
			</div>
		);
	}

	public render(): JSX.Element {

		const {activeBulkAction} = this.state;

		return (
			<PageContainer pageTitle="Dashboards" className={theme.list} top={1.2}>
				<div className={theme.actions}>
					<PageTitle iconName="icon-scenarios" label="Dashboards"/>
					<div className={theme.buttons}>
						<AddItemButton label="Create Dashboard" iconName="icon-plus-small" medium blue
									   onClick={this.onAddItem}/>
					</div>
				</div>

				{!dashboardsStore.isLoading ? (
					<DashboardsGrid items={dashboardsStore.items.filter((item) => !!cloudStore.dashboardsStarsById[item._id])}/>
				) : null}

				<HLine space={3.2}/>

				{!dashboardsStore.isLoading ? (
					<DashboardsGrid items={dashboardsStore.items}/>
				) : null}

				{dashboardsStore.isLoading ? this.renderLoader() : null}

				<Modal external exitOnClickOutside onClose={this.cancelDeleteItem}
					   isOpen={!!dashboardsStore.promptDeleteItem}>
					<ConfirmPopMessage
						red
						title="Delete Dashboard?"
						text={<span>Are you sure you want to delete {dashboardsStore.promptDeleteItem ?
							<Label weight={600}
								   block={false}>{`${dashboardsStore.promptDeleteItem.name}`}</Label> : ""}?</span>}
						cancelText="No, Cancel"
						confirmText="Yes, Delete"
						onCancelClick={this.cancelDeleteItem}
						onConfirmClick={dashboardsStore.deleteItem}
					/>
				</Modal>
				<Modal external exitOnClickOutside onClose={this.dismissBulkAction} isOpen={!!activeBulkAction}>
					<ConfirmPopMessage
						yellow
						title="Confirm action"
						text={`Are you sure you want to ${activeBulkAction} ${dashboardsStore.selectedValues.length} Dashboards?`}
						cancelText="Cancel"
						confirmText="Confirm"
						onCancelClick={this.dismissBulkAction}
						onConfirmClick={this.confirmBulkAction}
					/>
				</Modal>
			</PageContainer>
		);
	}
}

export default withRouter<any, any>(observer(Dashboards));
