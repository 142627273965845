import * as React from "react";
import {observer} from "mobx-react";
import {FormMode} from "@vidazoo/ui-framework";
import {Responsive, WidthProvider} from "react-grid-layout";
import * as theme from "./theme.scss";
import LayoutChart from "./layoutChart/LayoutChart";
import EditDashboardStore from "../../stores/EditDashboardStore";
import CreateDashboardStore from "../../stores/CreateDashboardStore";

const ResponsiveReactGridLayout = observer(WidthProvider(Responsive));

export interface IChartsFormProps {
	mode: FormMode;
	editMode?: boolean;
	store: EditDashboardStore | CreateDashboardStore;
}

export interface IChartsFormState {
}

@observer
export default class ChartsForm extends React.Component<IChartsFormProps, IChartsFormState> {

	public static defaultProps: Partial<IChartsFormProps> = {
		editMode: true
	};

	constructor(props: IChartsFormProps) {
		super(props);
	}

	public render(): JSX.Element {

		const {store} = this.props;
		const {editMode} = this.props;
		const disableDrag = !editMode || (window as any).__device === "mobile" || window.innerWidth < 500;

		return (
			<ResponsiveReactGridLayout rowHeight={1} isDraggable={!disableDrag} isResizable={!disableDrag} useCSSTransforms={false} onLayoutChange={store.onLayoutChange} className="layout">
				{store.item.layouts.lg.map((layout) => {
					return (
						<div className={editMode ? theme.editLayout : theme.layout} key={layout.i} data-grid={layout}>
							<LayoutChart store={store} editMode={editMode} layout={layout}/>
						</div>
					);
				})}
			</ResponsiveReactGridLayout>
		);
	}
}
