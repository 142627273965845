import {IReportingEntry} from "@vidazoo/ui-framework";
import {REPORT_VERTICAL_TYPE} from "../enums";

class LinksByVerticalService {
	public getLink(id: string, group: IReportingEntry, verticalType: REPORT_VERTICAL_TYPE, userId?: string): string {
		switch (verticalType) {
			case REPORT_VERTICAL_TYPE.PLATFORM:
				if (userId) {
					const link = this._getLinkByPlatform(id, group, userId);
					return `${link}?xaccounts=${userId}`;
				}
				return this._getLinkByPlatform(id, group);
			case REPORT_VERTICAL_TYPE.OPEN_RTB:
				return this._getLinkByOpenRtb(id, group);
			case REPORT_VERTICAL_TYPE.CONNECTION:
				return this._getLinkByConnection(id, group);
		}
	}

	private _getLinkByPlatform(id: string, group: IReportingEntry, userId?: string): string {
		const baseUrl = "https://bo.vidazoo.com";

		switch (group.value) {
			case "playerName":
				return `${baseUrl}/player/${id}/edit`;
			case "scenarioName":
			case "marketScenario":
				return `${baseUrl}/scenario/${id}/edit`;
			case "tagName":
				return `${baseUrl}/tag/${id}/edit`;
			case "publisherName":
				return `${baseUrl}/supply-partner/${id}/edit`;
			case "partnerName":
			case "partnerTagId":
			case "removedDemandPartnerName":
				return `${baseUrl}/demand-partner/${id}/edit`;
			case "playerWidgetId":
			case "widgetOriginal":
				return `${baseUrl}/widget_new/${id}/edit`;
			case "userName":
				return `${baseUrl}/account/${id}/edit`;
			case "widgetType":
				return `${baseUrl}/widget-settings/${id}/edit`;
			case "crawlerName":
				return `${baseUrl}/crawler/${id}/edit`;
			case "videoName":
				return `${baseUrl}/cms-video/${id}/edit`;
			case "playlistName":
				return this._getPlaylistUrl(id, baseUrl);
			case "connectionName":
				return `${baseUrl}/connection/${id}/edit`;
			case "serverApp":
				return `https://os.vidazoo.com/app/vroku/edit-app?id=${id}`;
			case "serverHost":
				return `https://os.vidazoo.com/app/vroku/edit-host?id=${id}`;
		}
	}

	private _getPlaylistUrl(id: string, baseUrl: string) {
		const [playlistId, type] = id.split("_");

		switch (type) {
			case "contextual":
				return `${baseUrl}/contextual-playlist/${playlistId}/edit`;
			case "combined":
				return `${baseUrl}/combined-playlist/${playlistId}/edit`;
			case "image":
				return `${baseUrl}/images-playlist/${playlistId}/edit`;
			case "dynamic":
				return `${baseUrl}/dynamic-playlist/${playlistId}/edit`;
			case "static":
				return `${baseUrl}/static-playlist/${playlistId}/edit`;
		}
	}

	private _getLinkByOpenRtb(id: string, group: IReportingEntry): string {
		const baseUrl = "https://openrtb.vidazoo.com";
		switch (group.value) {
			case "exchangeName":
				return `${baseUrl}/exchange/${id}/edit`;
			case "bid":
				return `${baseUrl}/connection/${id}/edit`;
		}
	}

	private _getLinkByConnection(id: string, group: IReportingEntry): string {
		const baseUrl = "https://cbo.cliipa.com";

		switch (group.value) {
			case "connectionName":
				return `${baseUrl}/connection/${id}/edit`;
			case "scenarioName":
				return `${baseUrl}/scenario/${id}/edit`;
			case "tagName":
				return `${baseUrl}/tag/${id}/edit`;
			case "abTestName":
				return `${baseUrl}/ab-test-policy/${id}/edit`;
		}
	}
}

export default new LinksByVerticalService();
