import * as React from "react";
import * as PropTypes from "prop-types";
import clsx from "clsx";
import * as theme from "./theme.scss";
import {RouteComponentProps} from "react-router";
import {UserScope} from "common/components";
import {AppBar} from "@vidazoo/ui/lib/components/appBar";
import {UserMenu} from "@vidazoo/ui/lib/components/userMenu";
import {MainMenu} from "@vidazoo/ui/lib/components/mainMenu";
import {Menu, MenuItem} from "@vidazoo/ui/lib/components/menu";
import {MenuTree, IMenuNode} from "@vidazoo/ui/lib/components/menuTree";
import {UserRole, ThemeSwitch, VidazooApps, NotificationPanel, IDictionary} from "@vidazoo/ui-framework";
import {appUrlsService} from "common/services";
import AccountsList from "../accountsList";
import routes from "common/routes";
import settingsRoute from "common/settingsRoute";
import {AuthScope, DEFAULT_ACCESS, NetworkFeatures, TimePresets} from "common/enums";
import {ButtonsGroup, GroupButton, VidazooHeaderLogo} from "@vidazoo/ui";
import {userNotificationsStore} from "../../../../common/stores";
import IMenuEntry from "../../../../common/interfaces/IMenuEntry";
import {Twirl as Hamburger} from "hamburger-react";
import {Link} from "react-router-dom";
import IDashboard from "../../../dashboard/interfaces/IDashboard";
import VidazooLogo from "../../../../common/logo";

const USER_MENU_LINKS = [
	{label: "Terms & Conditions", native: true, linkProps: {href: (window as any).__TERMS_URL__, target: "_blank"}},
	{label: "Privacy Policy", native: true, linkProps: {href: (window as any).__PRIVACY_URL__, target: "_blank"}}
];

export interface IAppHeaderProps extends RouteComponentProps<any> {
	userName?: string;
	userEmail?: string;
	userScopes?: string[];
	isInRoles?: (rols: UserRole[]) => boolean;
	isFeatureAllowed?: (include?: NetworkFeatures[] | NetworkFeatures, exclude?: NetworkFeatures[] | NetworkFeatures) => boolean;
	userAvatarUrl?: string;
	onSignOut?: () => void;
	openMyProfile?: () => void;
	setTimePreset: (name: string) => void;
	timePreset: TimePresets;
	userId?: string;
	cloudDashboardsStars: string[];
	dashboardsById: IDictionary<IDashboard>;
}

export interface IAppHeaderState {
	mounted: boolean;
	hideAppHeader: boolean;
	scrollY: number;
	routes: IMenuEntry[];
	isNavbarMobileOpen: boolean;
}

export default class AppHeader extends React.Component<IAppHeaderProps, IAppHeaderState> {

	public static defaultProps: Partial<IAppHeaderProps> = {};

	constructor(props, context) {
		super(props, context);

		this.state = {isNavbarMobileOpen: false, mounted: false, hideAppHeader: false, scrollY: window.scrollY, routes};
		if ((window as any).__device === "mobile") {
			addEventListener("scroll", this.onScroll);
		}
	}

	public componentDidMount() {
		window.requestAnimationFrame(() => {
			this.setState({mounted: true});
		});
		this.setRoutes();
	}

	public componentDidUpdate(prevProps: Readonly<IAppHeaderProps>) {
		if (this.props.cloudDashboardsStars !== prevProps.cloudDashboardsStars || this.props.dashboardsById !== prevProps.dashboardsById) {
			this.setRoutes();
		}
	}

	private setRoutes = () => {
		const clonedRoutes = JSON.parse(JSON.stringify(routes));
		const dashboardsRoutes = clonedRoutes.find((route) => route.title === "Dashbords");
		if (dashboardsRoutes) {
			for (const dashboardStarId of this.props.cloudDashboardsStars) {
				if (this.props.dashboardsById[dashboardStarId]) {
					dashboardsRoutes.items.push({
						title: this.props.dashboardsById[dashboardStarId].name,
						link: {to: appUrlsService.editDashboard(dashboardStarId)},
						access: DEFAULT_ACCESS,
					});
				}
			}
		}

		this.setState({routes: clonedRoutes});
	};

	private toggleNavbarMobileOpen = () => {
		this.setState({isNavbarMobileOpen: !this.state.isNavbarMobileOpen});
	};

	private onScroll = (e) => {
		this.setState({hideAppHeader: (this.state.scrollY < window.scrollY), scrollY: window.scrollY});
	};

	public onLogoClick = () => {
		const {history} = this.props;

		history.push(appUrlsService.dashboards());
	};

	private onLogout = () => {
		const {onSignOut} = this.props;

		onSignOut();
	};

	private reduceRouteItems(items: IMenuNode[]): IMenuNode[] {
		const {isInRoles, isFeatureAllowed} = this.props;

		return items.reduce((result, item) => {
			if (isInRoles(item.access) && isFeatureAllowed(item.includeFeatures, item.excludeFeatures)) {
				if (item.items) {
					item.items = this.reduceRouteItems(item.items);
				}

				return result.concat(item);
			}

			return result;
		}, []);
	}

	public renderMobileNavbar(items: IMenuNode[]): JSX.Element {
		const {isNavbarMobileOpen} = this.state;
		const classes = clsx(theme.navbar, {
			[theme.open]: isNavbarMobileOpen
		});
		return (
			<div className={theme.mobileNavbar}>
				<div className={theme.hamburger}>
					<Hamburger toggled={isNavbarMobileOpen} toggle={this.toggleNavbarMobileOpen} color="white"/>
				</div>
				<div className={classes}>
					{items.map((item) => {
						return (
							<Link onClick={this.toggleNavbarMobileOpen} to={item.link.to}>{item.title}</Link>
						);
					})}
				</div>
			</div>
		);
	}

	public renderSettingsMenu(): JSX.Element {
		return (
			<UserScope scope={settingsRoute.access}>
				<MainMenu iconName={settingsRoute.iconName} stickTo="right">
					{settingsRoute.items ? settingsRoute.items.map((item) => {
						return (
							<UserScope scope={item.access} key={item.title}>
								<MenuItem label={item.title} link={item.link}/>
							</UserScope>
						);
					}) : null}
				</MainMenu>
			</UserScope>
		);
	}

	private onTimePresetChange = (name: string) => {
		this.props.setTimePreset(name);
	};

	public render(): JSX.Element {

		const {
			location,
			userName,
			userEmail,
			userAvatarUrl,
			openMyProfile,
			userScopes,
			timePreset,
			userId
		} = this.props;

		const {mounted} = this.state;

		const classes = clsx(theme.header, {
			[theme.mounted]: (!this.state.hideAppHeader && mounted),
			[theme.hide]: this.state.hideAppHeader
		});

		return (
			<AppBar enableSticky className={classes}>
				<div className={theme.inner}>
					{((window as any).__device !== "mobile" && window.innerWidth > 600) || !location.pathname.includes("dashboard") ? (
						<div className="flex align-center">
							<div onClick={this.onLogoClick} className={theme.logo}>
								<VidazooLogo width={181} height={66} />
							</div>
						</div>
					) : null}
					{((window as any).__device !== "mobile" && window.innerWidth > 600) ? (
						<MenuTree className="flex-1" items={this.reduceRouteItems(this.state.routes)}/>
					) : null}

					{location.pathname.includes("dashboard") || location.pathname.includes("chart") ? (
						<ButtonsGroup className={theme.timePreset} active={timePreset}
									  onChange={this.onTimePresetChange}>
							<GroupButton className={theme.groupButton} name={TimePresets.TwoHours}>Two
								Hours</GroupButton>
							<GroupButton className={theme.groupButton} name={TimePresets.FourHours}>Four
								Hours</GroupButton>
							<GroupButton className={theme.groupButton} name={TimePresets.Today}>Today</GroupButton>
							<GroupButton className={theme.groupButton}
										 name={TimePresets.Yesterday}>Yesterday</GroupButton>
						</ButtonsGroup>
					) : null}
					{((window as any).__device !== "mobile" && window.innerWidth > 600) ? (
						<>
							<ThemeSwitch className={theme["theme-switch"]}/>
							<AccountsList/>
							<NotificationPanel userId={userId} userNotificationsStore={userNotificationsStore}/>
							<div className="flex align-center">
								<VidazooApps userScopes={userScopes} currentScope={AuthScope.BI_DASHBOARD}/>
								{this.renderSettingsMenu()}
								<UserMenu links={USER_MENU_LINKS} smallAvatar darkAvatar userName={userName}
										  userEmail={userEmail} avatarUrl={userAvatarUrl} className={theme.user}>
									<MenuItem iconName="icon-settings" label="My Profile" onClick={openMyProfile}/>
									<MenuItem iconName="icon-sign-out" label="Sign Out" onClick={this.onLogout}/>
								</UserMenu>
							</div>
						</>
					) : this.renderMobileNavbar(this.state.routes)}
				</div>
			</AppBar>
		);
	}
}
