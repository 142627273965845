import {BaseAPI} from "@vidazoo/ui-framework";

class ReportingHistoryAPI extends BaseAPI {

	public getAll() {
		return this.request({
			method: "get",
			url: "/api/report-history/",
			params: {origin: "bi_dashboards"}
		});
	}

	public getReportFromUrls(id: string) {
		return this.request({
			method: "get",
			url: "/api/report-history/report-from-urls",
			params: {id}
		});
	}

	public getOne(id: string, populate: any = []) {
		return this.request({
			method: "get",
			url: `/api/report-history/${id}/`,
			params: {populate}
		});
	}

	public create(data: any) {
		return this.request({
			method: "post",
			url: "/api/report-history/",
			data
		});
	}

	public delete(id: string) {
		return this.request({
			method: "delete",
			url: `/api/report-history/${id}/`
		});
	}

	public update(id: string, data: any) {
		return this.request({
			method: "patch",
			url: `/api/report-history/${id}/`,
			data
		});
	}

}

export default new ReportingHistoryAPI(window.__REPORTING_HISTORY_URL__);
