import * as React from "react";
import {IReportSection} from "@vidazoo/ui-framework";
import {QuickReportAction} from "@vidazoo/ui-framework/lib/containers/reporting/components";

export interface IReportGroupValueCellProps {
	group: IReportSection;
	enableQuickFilter?: boolean;
	onQuickFilter?: (item: IReportSection) => void;
}

export interface IReportGroupValueCellState {

}

export default React.memo(({group, onQuickFilter, enableQuickFilter}: IReportGroupValueCellProps): JSX.Element => {

	const value = group.link
		? <a href={group.link} target="_blank">{group.value}</a>
		: group.value;

	return (enableQuickFilter && onQuickFilter)
		? <QuickReportAction iconName="icon-filter" tooltipTitle="Add Filter" item={group}
							 onClick={onQuickFilter}>{value}</QuickReportAction>
		: value;
});
