import * as React from "react";
import * as theme from "./theme.scss";
import {RouteComponentProps} from "react-router";
import {dashboardsStore} from "../../../../common/stores";
import {appUrlsService} from "../../../../common/services";
import {Link} from "react-router-dom";
import clsx from "clsx";
import {Icon} from "@vidazoo/ui";
import {normalizeEventValue} from "@vidazoo/ui-framework";

export interface IDefaultChartsProps extends RouteComponentProps<any> {
}

export interface IDefaultChartsState {
	searchValue: string;
}

export default class DefaultCharts extends React.Component<IDefaultChartsProps, IDefaultChartsState> {

	private ref: HTMLElement;
	private inputRef: HTMLElement;

	constructor(props, context) {
		super(props, context);
		this.state = {searchValue: ""};
	}

	public componentDidMount() {

		if (this.ref) {
			const onWheel = (e) => {
				if (e.deltaY === 0) {
					return;
				}
				e.preventDefault();
				this.ref.scrollTo({
					left: this.ref.scrollLeft + e.deltaY,
					behavior: "smooth"
				});
			};
			this.ref.addEventListener("wheel", onWheel);
			return () => this.ref.removeEventListener("wheel", onWheel);
		}
	}

	private setRef = (el) => {
		this.ref = el;
	};

	private setInputRef = (el) => {
		this.inputRef = el;
	};

	private onChangeSearchValue = (e) => {
		const value = normalizeEventValue(e);
		this.setState({searchValue: value});
	};

	private clearSearchValue = () => {
		this.setState({searchValue: ""});
	};

	private setFocusSearchValue = () => {
		this.inputRef && this.inputRef.focus();
	};

	public render(): JSX.Element {
		const {searchValue} = this.state;

		return (
			<div className={theme.chipsContainer}>
				<div className={theme.searchContainer}>
					<div onClick={searchValue ? this.clearSearchValue : this.setFocusSearchValue}
						 className={theme.iconContainer}>
						<Icon size={2.2} iconName={searchValue ? "icon-cancel-small" : "icon-search"}/>
					</div>
					<input ref={this.setInputRef} onChange={this.onChangeSearchValue} className={theme.searchValue}
						   value={searchValue}
						   name="searchValue"/>
				</div>
				<div ref={this.setRef} className={theme.chipsOutside}>
					<div className={theme.chips}>
						{dashboardsStore.defaultCharts.filter((chart) => chart.name.toLowerCase().includes(searchValue.toLowerCase())).map((chart) => {
							return (
								<div key={chart._id}
									 className={clsx(theme.chip, {[theme.chipSelected]: location.pathname.includes(chart._id)})}>
									<Link to={appUrlsService.viewChart(chart._id)}>{chart.name}</Link>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}
