import {
	BaseNotificationsStore,
	ProfileStore,
	UserNotificationsStore,
	UserNotificationsSettingsStore,
	UserNotificationsSocketService
} from "@vidazoo/ui-framework";
import InitialState from "./InitialState";
import NavigationStore from "./NavigationStore";
import SessionStore from "./SessionStore";
import CurrentUserStore from "./CurrentUserStore";
import CloudStore from "./CloudStore";
import UIStore from "./UIStore";
import MetaDataStore from "../../modules/reporting/stores/MetaDataStore";
import DashboardsStore from "../../modules/dashboard/stores/DashboardsStore";
import ReportingStore from "../../modules/reporting/stores/ReportingStore";
import PresetReportsStore from "../../modules/reporting/stores/PresetReportsStore";
import {storageService} from "../services";
import EditChartsManagerStore from "../../modules/chart/stores/EditChartsManagerStore";
import HistoryReportsStore from "../../modules/reporting/stores/HistoryReportsStore";

export const initialState = new InitialState();
export const uiStore = new UIStore();
export const navigationStore = new NavigationStore();
export const notificationsStore = new BaseNotificationsStore();
export const sessionStore = new SessionStore();
export const currentUserStore = new CurrentUserStore();
export const cloudStore = new CloudStore();
export const profileStore = new ProfileStore(notificationsStore);
export const metaDataStore = new MetaDataStore();
export const dashboardsStore = new DashboardsStore();
export const reportingStore = new ReportingStore();
export const editChartsManagerStore = new EditChartsManagerStore();

export const presetReportsStore = new PresetReportsStore();
export const historyReportsStore = new HistoryReportsStore();

export const userNotificationsStore = new UserNotificationsStore(UserNotificationsSocketService);
export const userNotificationsSettingsStore = new UserNotificationsSettingsStore(UserNotificationsSocketService, notificationsStore, storageService);
