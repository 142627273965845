import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import {AccountManagers} from "../../../../../common/enums";

export default class AccountManagerFilter extends BaseFilter {

	public static type = PlatformReportingFilterType.AccountManager;

	constructor() {
		super();

		this.allowNew = true;
		this.labelKey = "label";
		this.valueKey = "value";
		this.items = AccountManagers;
	}
}
