import * as _ from "lodash";
import {
	momentToTimeZoneUnix,
	IDictionary,
	IReportingEntry,
	IReportingConstraint,
	IReportingFilter,
	formatNumber,
	guid, IReportingParams,
} from "@vidazoo/ui-framework";
import {intermittentLoop} from "common/utils";
import {
	historyReportsSocketService,
	linksByVerticalService,
	socketService,
	storageService
} from "../../../common/services";
import reportingFiltersManager from "../stores/filters/reportingFiltersManager";
import IReportingResults from "../interfaces/IReportingResults";
import {REPORT_VERTICAL_TYPE} from "../../../common/enums";
import {IReportingFilterByVertical} from "../../dashboard/interfaces/IReportingFilterByVertical";
import {metaDataStore} from "../../../common/stores";
import reportingSubGroupsManager from "../stores/reportingSubGroupsManager";
import {IBiReportingParams, ISubGroup} from "../interfaces/IBiReportingParams";
import IReportParams from "../interfaces/IReportParams";

export class ReportingService {

	protected groupByValue: { [vertical: string]: IDictionary<IReportingEntry> };
	protected groupByLabel: { [vertical: string]: IDictionary<IReportingEntry> };

	protected fieldByValue: { [vertical: string]: IDictionary<IReportingEntry> };
	protected fieldByLabel: { [vertical: string]: IDictionary<IReportingEntry> };
	protected fieldById: { [vertical: string]: IDictionary<IReportingEntry> };

	protected capsuleByValue: { [vertical: string]: IDictionary<IReportingEntry> };
	protected capsuleByLabel: { [vertical: string]: IDictionary<IReportingEntry> };
	protected capsuleById: { [vertical: string]: IDictionary<IReportingEntry> };

	protected filterByValue: { [vertical: string]: IDictionary<IReportingEntry> };
	protected filterByLabel: { [vertical: string]: IDictionary<IReportingEntry> };

	public mappableGroups: IReportingEntry[];

	protected readonly NUMBER_FORMAT_REGEXP = /(.?)\.(0+)(.?)/;

	public initialize() {
		this.indexEntries();
	}

	protected enhanceField(field: IReportingEntry): IReportingEntry {
		if (field.formula) {
			field.formula = this.compileFieldFormula(field.formula);
		}

		if (field.format) {
			field.format = this.compileFieldFormat(field.format);
		}

		return field;
	}

	protected compileFieldFormula(formula): (dto: any) => number {
		const template = _.template(formula, {interpolate: /([a-zA-Z]+)/g});
		return (dto) => {
			try {
				const fn = new Function("return " + template(dto));
				const result = fn();
				return (Infinity === result || isNaN(result)) ? 0 : result;
			} catch (e) {
				return 0;
			}
		};
	}

	protected compileFieldFormat(format): (value: number) => string {
		const matches = format.match(this.NUMBER_FORMAT_REGEXP);
		if (matches) {
			const [pre, decimals, post] = matches.slice(1);
			return (value) => pre + formatNumber(value, decimals.length) + post;
		}
	}

	protected indexEntries() {
		this.fieldByValue = {};
		this.fieldByLabel = {};
		this.fieldById = {};
		this.groupByValue = {};
		this.groupByLabel = {};
		this.filterByValue = {};
		this.filterByLabel = {};

		this.capsuleById = this.capsuleByValue = this.capsuleByLabel = {};

		for (const vertical of Object.keys(metaDataStore.metaDataByVertical)) {

			const {fields, groups, filters, capsules} = metaDataStore.metaDataByVertical[vertical];

			for (const field of fields) {
				this.fieldByLabel[vertical] = this.fieldByLabel[vertical] || {};
				this.fieldByValue[vertical] = this.fieldByValue[vertical] || {};
				this.fieldById[vertical] = this.fieldById[vertical] || {};
				this.fieldByLabel[vertical][field.label] = this.fieldByValue[vertical][field.value] = this.fieldById[vertical][field["_id"]] = field;
			}

			for (const group of groups) {
				this.groupByLabel[vertical] = this.groupByLabel[vertical] || {};
				this.groupByValue[vertical] = this.groupByValue[vertical] || {};
				this.groupByLabel[vertical][group.label] = this.groupByValue[vertical][group.value] = group;
			}

			for (const filter of filters) {
				this.filterByLabel[vertical] = this.filterByLabel[vertical] || {};
				this.filterByValue[vertical] = this.filterByValue[vertical] || {};
				this.filterByLabel[vertical][filter.label] = this.filterByValue[vertical][filter.value] = filter;
			}

			for (const capsule of capsules) {
				this.capsuleByLabel[vertical] = this.capsuleByLabel[vertical] || {};
				this.capsuleByValue[vertical] = this.capsuleByValue[vertical] || {};
				this.capsuleById[vertical] = this.capsuleById[vertical] || {};
				this.capsuleByLabel[vertical][capsule.label] = this.capsuleByValue[vertical][capsule.value] = this.capsuleById[vertical][capsule["_id"]] = capsule;
			}
		}
	}

	public getFieldByLabel(label: string, verticalType: REPORT_VERTICAL_TYPE, defaultValue?): IReportingEntry {
		return this.fieldByLabel[verticalType][label] || defaultValue;
	}

	public getFieldByValue(value: string, verticalType: REPORT_VERTICAL_TYPE, defaultValue?): IReportingEntry {
		return this.fieldByValue[verticalType][value] || defaultValue;
	}

	public getFieldById(value: string, verticalType: REPORT_VERTICAL_TYPE, defaultValue?): IReportingEntry {
		return this.fieldById[verticalType][value] || defaultValue;
	}

	public getCapsuleByLabel(label: string, verticalType: REPORT_VERTICAL_TYPE, defaultValue?): IReportingEntry {
		return this.capsuleByLabel[verticalType][label] || defaultValue;
	}

	public getCapsuleByValue(value: string, verticalType: REPORT_VERTICAL_TYPE, defaultValue?): IReportingEntry {
		return this.capsuleByValue[verticalType][value] || defaultValue;
	}

	public getCapsuleById(value: string, verticalType: REPORT_VERTICAL_TYPE, defaultValue?): IReportingEntry {
		return this.capsuleById[verticalType][value] || defaultValue;
	}

	public getGroupByLabel(label: string, verticalType: REPORT_VERTICAL_TYPE, defaultValue?): IReportingEntry {
		return this.groupByLabel[verticalType][label] || defaultValue;
	}

	public getGroupByValue(value: string, verticalType: REPORT_VERTICAL_TYPE, defaultValue?): IReportingEntry {
		return this.groupByValue[verticalType][value] || defaultValue;
	}

	public tryGetGroup(labelOrValue: string, verticalType: REPORT_VERTICAL_TYPE): IReportingEntry {
		return this.getGroupByLabel(labelOrValue, verticalType) || this.getGroupByValue(labelOrValue, verticalType);
	}

	public tryGetField(labelOrValue: string, verticalType: REPORT_VERTICAL_TYPE): IReportingEntry {
		return this.getFieldByLabel(labelOrValue, verticalType) || this.getFieldByValue(labelOrValue, verticalType);
	}

	public getFilterByLabel(label: string, verticalType: REPORT_VERTICAL_TYPE, defaultValue?): IReportingEntry {
		return this.filterByLabel[verticalType][label] || defaultValue;
	}

	public getFilterByValue(value: string, verticalType: REPORT_VERTICAL_TYPE, defaultValue?): IReportingEntry {
		return this.filterByValue[verticalType][value] || defaultValue;
	}

	public tryGetFilter(labelOrValue: string, verticalType: REPORT_VERTICAL_TYPE): IReportingEntry {
		return this.getFilterByLabel(labelOrValue, verticalType) || this.getFilterByValue(labelOrValue, verticalType);
	}

	public getPreselectedFields(verticalType: REPORT_VERTICAL_TYPE): IReportingEntry[] {
		const storageFields = storageService.getReportingEntries("fields", verticalType);

		if (storageFields) {
			return _(storageFields)
				.map((field) => this.getFieldByValue(field.value, verticalType) || null)
				.compact()
				.value();
		}

		return _(metaDataStore.metaDataByVertical[verticalType].fields)
			.pickBy({preselected: true})
			.toArray()
			.compact()
			.value();
	}

	public getPreselectedGroups(verticalType: REPORT_VERTICAL_TYPE): IReportingEntry[] {
		const storageGroups = storageService.getReportingEntries("groups", verticalType);

		if (storageGroups) {
			return _(storageGroups)
				.map((group) => this.getGroupByValue(group.value, verticalType) || null)
				.compact()
				.value();
		}

		return _(metaDataStore.metaDataByVertical[verticalType].groups)
			.pickBy({preselected: true})
			.toArray()
			.compact()
			.value();
	}

	public getPreselectedFilters(verticalType: REPORT_VERTICAL_TYPE): IReportingFilter[] {
		const storageFilters = storageService.getReportingFilters(verticalType);

		if (storageFilters) {
			return storageFilters.concat();
		}

		return [];
	}

	public getPreselectedConstraints(verticalType: REPORT_VERTICAL_TYPE): IReportingConstraint[] {
		const storageConstraints = storageService.getReportingConstraints(verticalType);

		if (storageConstraints) {
			return storageConstraints.concat();
		}

		return [];
	}

	public getPreselectedSubGroups(verticalType: REPORT_VERTICAL_TYPE): ISubGroup[] {
		const storageSubGroups = storageService.getReportingSubGroups(verticalType);

		if (storageSubGroups) {
			storageSubGroups.forEach((subGroups) => {
				reportingSubGroupsManager.getSubGroupsEntities(subGroups.group, verticalType);
			});

			return storageSubGroups.concat();
		}

		return [];
	}

	public getReport(params: IBiReportingParams, isCompareReport: boolean = false, verticalType: REPORT_VERTICAL_TYPE): Promise<any> {
		const reportParams = this.getReportParams(params, true, verticalType);

		return isCompareReport
			? socketService.getCompareReporting(reportParams)
			: socketService.getReporting(reportParams);
	}

	public getReportingHistory(params: IBiReportingParams, isCompareReport: boolean = false, verticalType: REPORT_VERTICAL_TYPE, usePublishBus: boolean): Promise<any> {
		const reportParams = this.getReportParams(params, true, verticalType);

		const originFields = this.prepareOriginFields(params.fields);

		return isCompareReport
			? historyReportsSocketService.getCompareReportingHistory({params: reportParams, originFields})
			: historyReportsSocketService.getReportingHistory({
				params: reportParams,
				originFields,
				usePublish: usePublishBus
			});
	}

	public getReportByChart(chartId: string, timePreset: string, timezone: string, reportParams: {
		filters: any[],
		constraints: any[]
	}): Promise<any> {
		return socketService.getReportByChart({chartId, timePreset, timezone, reportParams});
	}

	public getReportByChartCreate(chartId: string, type: string, timePreset: string, timezone: string, reportParams: any, preparedReportParams: any): Promise<any> {
		return socketService.getReportByChartCreate({chartId, timePreset, type, timezone, reportParams, preparedReportParams});
	}

	public prepareOriginFields(fields: IReportingEntry[]): string[] {
		return fields.map((field) => {
			if (field.type === "capsule") {
				return (field as any)._id;
			}
			return field.value;
		});
	}

	public getReportParamsForChart(params: IReportParams): any {
		const dto: any = {
			fields: params.fields,
			groups: params.groups,
			filters: this.prepareReportFilters(params.filters, params.verticalType),
			constraints: this.prepareReportConstraints(params.constraints),
			origin: "bi_dashboards",
		};

		return dto;
	}

	protected getReportParams(params: IBiReportingParams, includeOriginals: boolean = false, verticalType: REPORT_VERTICAL_TYPE): any {
		const dto: any = {
			from: momentToTimeZoneUnix(params.from, params.timezone),
			to: momentToTimeZoneUnix(params.to, params.timezone),
			fields: this.prepareReportFieldsByVertical(params.fields, verticalType),
			groups: params.groups.map((group) => group.value),
			subGroups: params.subGroups,
			itemType: "player",
			filters: this.prepareReportFilters(params.filters, verticalType),
			constraints: this.prepareReportConstraints(params.constraints),
			dbType: params.dbType,
			timeZone: params.timezone,
			accountsId: params.accountsId,
			origin: "bi_dashboards",
			verticalType,
			includeOriginals
		};

		if (params.sample && params.sample > 0) {
			dto.sample = params.sample;
		}

		if (params.isCompare) {
			dto.compareFrom = momentToTimeZoneUnix(params.compareFrom, params.timezone);
			dto.compareTo = momentToTimeZoneUnix(params.compareTo, params.timezone);
		}

		return dto;
	}

	protected prepareReportFilters(filters: IReportingFilter[], vertical: REPORT_VERTICAL_TYPE) {
		if (!filters || !filters.length) {
			return [];
		}

		return _(filters)
			.map((filter: IReportingFilter) => reportingFiltersManager.getFilter(filter.key, vertical).reportify(filter))
			.compact()
			.value();
	}

	protected prepareReportFieldsByVertical(fields: IReportingEntry[], vertical: REPORT_VERTICAL_TYPE) {
		let neededFields = [];
		fields.filter((currFilter) => currFilter.type !== "formula").forEach((field) => {
			if (field.type === "capsule" && field["fields"]) {
				const capsulesFields = [];
				field["fields"].forEach((fieldId: string) => {
					const capsuleField = this.getFieldById(fieldId, vertical);
					capsuleField && capsulesFields.push(capsuleField.value);
				});
				neededFields = neededFields.concat(capsulesFields);
			} else {
				neededFields.push(field.value);
			}
		});

		return _.uniq(neededFields);
	}

	public prepareReportFiltersByVertical(filters: IReportingFilterByVertical[], vertical: REPORT_VERTICAL_TYPE) {
		if (!filters || !filters.length) {
			return [];
		}

		return _(filters)
			.filter((filter: IReportingFilterByVertical) => filter.verticalType === vertical)
			.map((filter: IReportingFilterByVertical) => reportingFiltersManager.getFilter(filter.key, vertical).reportify(filter))
			.compact()
			.value();
	}

	public prepareReportConstraints(constraints: IReportingConstraint[]) {
		if (!constraints || !constraints.length) {
			return [];
		}

		return _(constraints)
			.map((constraint: IReportingConstraint) => ({
				name: constraint.name,
				op: constraint.op,
				value: constraint.value
			}))
			.compact()
			.value();
	}

	public buildResults(groups: IReportingEntry[], fields: IReportingEntry[], data: any, isCompareReport: boolean, verticalType: REPORT_VERTICAL_TYPE): Promise<IReportingResults> {

		return new Promise((resolve, reject) => {
			const rows = [];

			const handleRow = (item, idx) => {
				const dto = {
					id: idx,
					fields: [],
					groups: [],
					indexed: {}
				};

				const requestedFieldsDtos = [];
				const nonRequestedFieldsDtos = [];

				_.forEach(item.fields, (fieldNumericValue, fieldName) => {

					const fieldFromContext = this.getFieldByValue(fieldName, verticalType);
					const requestedFieldIndex = _.findIndex(fields, {value: fieldName});
					const isRequested = requestedFieldIndex > -1;

					// `value` can be an array in compare report: [`original_value`, `compare_value`, `delta`, `diff`]
					const value = item.fields[fieldName];

					const fieldDto: any = {
						id: guid(),
						name: fieldName,
						value,
						isRequested,
					};

					if (fieldFromContext) {
						fieldDto.type = fieldFromContext.type;
						fieldDto.name = fieldFromContext.label;

						if (isCompareReport) {
							const [originalValue, compareValue, delta] = fieldDto.value;

							fieldDto.displayValue = [originalValue, compareValue, delta].map((val) => {
								return fieldFromContext.format
									? fieldFromContext.format(val)
									: formatNumber(val, 2);
							});
						} else {
							fieldDto.displayValue = fieldFromContext.format
								? fieldFromContext.format(fieldDto.value)
								: formatNumber(fieldDto.value, 2);
						}

					}

					// this is critical in order to preserve the requested fields order
					if (isRequested) {
						requestedFieldsDtos[requestedFieldIndex] = fieldDto;
					} else {
						nonRequestedFieldsDtos.push(fieldDto);
					}

					dto.fields.push(fieldDto);
				});

				dto.fields = [...requestedFieldsDtos, ...nonRequestedFieldsDtos];
				groups.forEach((group, gIndex) => {
					const value = item.groups[group.value];

					if (_.isObject(value)) {
						for (const key in value) {
							const valueInObj = typeof value[key] === "boolean" ? JSON.stringify(value[key]) : value[key];
							dto.groups.push({
								id: gIndex + key,
								name: `${group.value}.${key}`,
								valueDisplay: valueInObj || "",
								value: valueInObj || "",
								link: "",
								originalId: "",
								hasFilterType: !!group.filterType
							});
						}
					} else {
						const originalId = group.value === "advertiserName" ? item.groups["advertiserId"] : item.groups[`${group.value}Original`];
						const valueName = item.groups[`${group.value}ValueName`];
						const userId = item.groups.userId ? item.groups.userId : null;
						let link;
						if (originalId) {
							link = linksByVerticalService.getLink(originalId, group, verticalType, userId);
						}
						if (valueName) {
							link = linksByVerticalService.getLink(value, group, verticalType, userId);
						}
						const groupObj = {
							id: gIndex,
							name: group.label,
							valueDisplay: value,
							value: valueName || value,
							link,
							originalId,
							hasFilterType: !!group.filterType,
							isGroup: true
						};
						dto.groups.push(groupObj);
					}
				});

				dto.indexed = _.keyBy(dto.groups.concat(dto.fields), "name");

				rows.push(dto);
			};

			try {
				intermittentLoop(data, handleRow, 50, () => {
					const results = {
						result: rows as any,
						fields: rows.length ? rows[0].fields : [],
						groups: rows.length ? rows[0].groups : [],
						originResults: data
					};

					resolve(results);
				});
			} catch (e) {
				reject(e);
			}
		});
	}
}

export default new ReportingService();
