import {nodeListToArrayOf} from "@vidazoo/ui/lib/components/utils";
import * as _ from "lodash";

export function intermittentLoop(collection: any[], iterator: (value: any, index: number) => void, batchSize: number, onDone: () => void, startAt: number = 0) {
	const stop = Math.min(batchSize, collection.length - startAt) + startAt;
	let i = startAt;
	for (; i < stop; i++) {
		iterator(collection[i], i);
	}
	i < collection.length ?
		setImmediate(() => intermittentLoop(collection, iterator, batchSize, onDone, i), 0)
		: onDone();
}

export function refreshAllCodeMirrorEditors() {
	nodeListToArrayOf<HTMLDivElement>(document.querySelectorAll(".CodeMirror")).map((element: any) => {
		if (element.CodeMirror) {
			element.CodeMirror.refresh();
		}
	});
}

export const enumNames = (enm: any): string[] => {
	const res = [];

	for (const key in enm) {
		if (res.indexOf(key) === -1 && res.indexOf(enm[key]) === -1 && isNaN(key as any)) {
			res.push(key);
		}
	}

	return res;
};

export const enumValues = <T>(enm): T[] => {
	const res = [];

	for (const key in enm) {
		if (enm.hasOwnProperty(key)) {
			let useValue: any = enm[key];

			if (!isNaN(key as any)) {
				useValue = +key;
			}

			if (res.indexOf(useValue) === -1 && res.indexOf(key) === -1) {
				res.push(useValue);
			}
		}
	}

	return res;
};

export function compileFieldFormula(formula: any): (dto: any) => number {
	let template;
	if (formula && formula.includes("${")) {
		template = _.template(formula);

	} else {
		template = _.template(formula, {interpolate: /([a-zA-Z][\w]+)/g});
	}
	return (dto) => {
		try {
			const fn = new Function("return " + template(dto));
			const result = fn();
			return (Infinity === result || isNaN(result)) ? 0 : result;
		} catch (e) {
			return 0;
		}
	};
}
