import * as React from "react";
import * as theme from "styles/_baseForm.scss";
import {observer} from "mobx-react";
import {FormMode, normalizeEventValue} from "@vidazoo/ui-framework";
import ChartContext, {IChartContext} from "./ChartContext";
import {Checkbox, FormInput, Label, Surface} from "@vidazoo/ui";
import {ChartType} from "../../../../common/enums";
import ReportForm from "./ReportForm";
import {currentUserStore} from "../../../../common/stores";
import ChartTypesSelect from "./chartTypes/ChartTypesSelect";

export interface IDetailsFormFormProps {

	mode: FormMode;

}

export interface IDetailsFormFormState {

}

@observer
export default class DetailsForm extends React.Component<IDetailsFormFormProps, IDetailsFormFormState> {

	public static defaultProps: Partial<IDetailsFormFormProps> = {};
	public static contextType = ChartContext;

	constructor(props: IDetailsFormFormProps, context: IChartContext) {
		super(props, context);
	}

	private onChangeDetail = (e) => {
		const {store} = this.context;

		const value = normalizeEventValue(e);

		store.updateParam(e.target.name, value);
	};

	private onChangeReportParams = (e) => {
		const {store} = this.context;

		const value = normalizeEventValue(e);

		store.updateDeepParam("reportParams", e.target.name, value);
	};

	private onDeleteAccount = (value: string) => {
		const {store} = this.context;

		store.removeFromArrayInObj("reportParams", "ids", value);
	};

	private onOrderAccount = (accounts: any[]) => {
		const {store} = this.context;

		store.updateDeepParam("reportParams", "ids", accounts);
	};

	private onAddAccount = (value: string, label: string) => {
		const {store} = this.context;

		store.addToArrayInObj("reportParams", "ids", value);
	};

	private onChartTypeChange = (type: ChartType) => {
		const {store} = this.context;

		store.resetChartByReport();
		store.updateParam("type", type);
	}

	public render(): JSX.Element {

		const {mode} = this.props;
		const {store, metaDataStore} = this.context;
		const {item} = store;

		return (
			<div className={theme.form}>
				<Surface className={theme.surface} level={1}>
					<div className={theme.row}>
						<FormInput onChange={this.onChangeDetail} className={theme.input} value={item.name} autoFocus
								   name="name" label="Name"
								   required fill hideValidationMessage/>
					</div>
					<Label weight={600} spacing={1} size={12}
						   style={{color: "var(--field-label-color)", marginBottom: 10}}>Chart Type</Label>
					<ChartTypesSelect selectedType={item.type} disabled={mode === FormMode.Edit}
									  onSelectType={this.onChartTypeChange}/>
					<div className={theme.row}>
						{currentUserStore.isDeveloper() && item.type === ChartType.DATA_TABLE ? (
							<Checkbox className={theme.input} name="mustFilter" label="Must Filter"
									  defaultChecked={item.mustFilter} onChange={this.onChangeDetail}/>
						) : null}
						{(currentUserStore.isDeveloper() && item.type === ChartType.DATA_TABLE) ? (
							<Checkbox className={theme.input} name="isDefault" label="Is Default"
									  defaultChecked={item.isDefault} onChange={this.onChangeDetail}/>
						) : null}
					</div>
					<ReportForm mode={this.props.mode}/>
				</Surface>
			</div>
		);
	}
}
