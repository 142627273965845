import * as platformHandlers from "./platformHandlers";
import * as openRtbHandlers from "./openRtbHandlers";
import {IDictionary, IReportingFilter} from "@vidazoo/ui-framework";
import IReportingFilterHandler from "./IReportingFilterHandler";
import {REPORT_VERTICAL_TYPE} from "../../../../common/enums";
import BaseFilter from "./BaseFilter";

class ReportingFiltersManager {

	private filters: IDictionary<IReportingFilterHandler>;

	constructor() {
		this.filters = {};
	}

	public getFilter(type: string, vertical: REPORT_VERTICAL_TYPE): IReportingFilterHandler {

		if (!this.filters[type]) {
			let Filter = this.getFilterClassByType(type, vertical);

			if (!Filter) {
				Filter = BaseFilter;
			}

			this.filters[type] = new Filter();
		}

		return this.filters[type];
	}

	private getFilterClassByType(type: any, vertical: REPORT_VERTICAL_TYPE) {

		let filters;
		switch (vertical) {
			case REPORT_VERTICAL_TYPE.PLATFORM:
				filters = platformHandlers;
				break;
			case REPORT_VERTICAL_TYPE.OPEN_RTB:
				filters = openRtbHandlers;
				break;
		}

		for (const key in filters) {
			if (filters[key].type === type) {
				return filters[key];
			}
		}
	}

	public getFilterListValueByLabel(filter: IReportingFilter, label: string, vertical: REPORT_VERTICAL_TYPE): string {
		const handler = this.getFilter(filter.key, vertical);

		if (handler) {
			return handler.getValueByLabel(label);
		}

		return "";
	}

	public getFilterListLabelByValue(filter: IReportingFilter, value: string, vertical: REPORT_VERTICAL_TYPE): string {
		const handler = this.getFilter(filter.key, vertical);

		if (handler) {
			return handler.getLabelByValue(value);
		}

		return "";
	}

}

export default new ReportingFiltersManager();
