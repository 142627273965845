import * as React from "react";
import * as theme from "./theme.scss";
import {Switch, LinkButton, TableCellToggle, DataGridRow, DataGridColumn, Checkbox, Icon} from "@vidazoo/ui";
import * as moment from "moment";
import {IChangeLog} from "../../interfaces/IChangeLog";
import {observer} from "mobx-react";
import {metaDataStore} from "../../../../common/stores";

export interface IChangeLogsRowProps {
	item: IChangeLog;
	columnsWidth: any;
	freezeColumnIndex: number;
	index: number;
	setSelectedItem: (item: any) => void;
	nameByIdAndScope: { [index: string]: any };
}

export interface IChangeLogsRowState {

}

@observer
export default class ChangeLogsRow extends React.Component<IChangeLogsRowProps, IChangeLogsRowState> {

	public static defaultProps: Partial<IChangeLogsRowProps> = {};

	constructor(props, context) {
		super(props, context);
	}

	private onSetSelectedItemDiff = () => {
		const {setSelectedItem, item} = this.props;
		setSelectedItem(item.diff);
	};

	private onSetSelectedItemAuth = () => {
		const {setSelectedItem, item} = this.props;

		const user = metaDataStore.usersById[item.auth.id] || {};
		setSelectedItem({...item.auth, ...user});
	};

	private onGoToClick = () => {
		const {auth} = this.props.item;
		window.open(`https://vidazoo-accounts-client.herokuapp.com/user/${auth.id}/edit`);
	};

	public render(): JSX.Element {

		const {item, index, columnsWidth, freezeColumnIndex, nameByIdAndScope} = this.props;

		return (
			<DataGridRow className={theme.row} index={index} columnsWidth={columnsWidth} key={index}
						 freezeColumnIndex={freezeColumnIndex}>
				<DataGridColumn className={theme.column} tooltip>{item.scope}</DataGridColumn>
				<DataGridColumn className={theme.column} tooltip>{item.account}</DataGridColumn>
				<DataGridColumn className={theme.column} onClick={this.onSetSelectedItemAuth} tooltip
								style={{display: "flex", alignItems: "center"}}>
					{(item.auth && item.auth.id !== "bot") ?
						<LinkButton style={{marginRight: "1rem"}} type="button" onClick={this.onGoToClick}
									iconName="icon-go-to-link-small" iconSize={2}/> : null}
					{item.auth ? item.auth.email : ""}
				</DataGridColumn>
				<DataGridColumn className={theme.column} tooltip>{item.action}</DataGridColumn>
				<DataGridColumn className={theme.column} tooltip>{item.model_name}</DataGridColumn>
				<DataGridColumn className={theme.column} tooltip>
					<a href={item.model_link} target="_blank">
						{nameByIdAndScope[item.scope + item.model_id] ? nameByIdAndScope[item.scope + item.model_id].name : item.model_id}
					</a>
				</DataGridColumn>
				<DataGridColumn
					className={theme.column}>{moment(Number(item.date)).format("MMM DD, YYYY, HH:MM")}</DataGridColumn>
				<DataGridColumn className={theme.column} onClick={this.onSetSelectedItemDiff}
								tooltip>{JSON.stringify(item.diff)}</DataGridColumn>
			</DataGridRow>
		);
	}
}
