import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";

export default class DomainFilter extends BaseFilter {

	public static type = PlatformReportingFilterType.Domain;

	constructor() {
		super();
	}
}
