import BaseFilter from "../BaseFilter";
import {PlatformReportingFilterType} from "../ReportingFilterType";
import devices from "@vidazoo/ui-framework/lib/common/devices";

export default class DeviceFilter extends BaseFilter {

	public static type = PlatformReportingFilterType.Device;

	constructor() {
		super();

		this.items = devices;
		this.valueKey = "key";
		this.labelKey = "label";
		this.allowNew = false;
	}
}
