import {action, transaction, computed, observable} from "mobx";
import {BaseListStore} from "@vidazoo/ui-framework";
import IReportingPreset from "../interfaces/IReportingPreset";
import {notificationsStore} from "../../../common/stores";
import presetReportsAPI from "../api/presetReportsAPI";
import {ReportPresetType} from "../../../common/enums";

export default class PresetReportsStore extends BaseListStore<IReportingPreset, string, any> {

	@observable public publicItems: IReportingPreset[];
	@observable public historyItems: IReportingPreset[];
	@observable public activePreset: { _id: string, name: string };

	constructor() {
		super(notificationsStore, [], "report preset", "name");
		this.activePreset = null;
	}

	@action
	protected onLoadItemsSuccess(res) {
		transaction(() => {
			this.isLoading = false;
			this.items = res.data.results.filter((row) => (row.type === ReportPresetType.Private && !row.isHistory));
			this.publicItems = res.data.results.filter((row) => row.type === ReportPresetType.Public);
			this.historyItems = res.data.results.filter((row) => row.isHistory);
			this.count = res.data.count;
		});
	}

	@action public getItems = () => {
		this.isLoading = true;

		presetReportsAPI.getAll()
			.then((res) => this.onLoadItemsSuccess(res))
			.catch((res) => this.onLoadItemsError(res));
	};

	@action public setActivePreset = (preset) => {
		this.activePreset = preset;
	};

	@action public create = (params) => {
		presetReportsAPI.create(params)
			.then((res) => this.onCreateSuccess(res))
			.catch(() => this.onCreateError());
	};

	@action
	private onCreateSuccess(res) {
		(this.items as any) = this.items.concat(res.data);

		notificationsStore.pushSuccessNotification({
			title: "Operation Complete",
			text: "Preset report created successfully",
			timeout: 5000
		});
	}

	@action
	private onCreateError() {
		notificationsStore.pushErrorNotification({
			title: "Operation Error",
			text: "Failed to create preset report",
			timeout: 5000
		});
	}

	@action public update = (id: string, params) => {
		presetReportsAPI.update(id, params)
			.then((res) => this.onUpdateSuccess(res))
			.catch(() => this.onUpdateError());
	};

	@action
	private onUpdateSuccess(res) {
		notificationsStore.pushSuccessNotification({
			title: "Operation Complete",
			text: "Preset report updated successfully",
			timeout: 5000
		});
	}

	@action
	private onUpdateError() {
		notificationsStore.pushErrorNotification({
			title: "Operation Error",
			text: "Failed to update preset report",
			timeout: 5000
		});
	}

	@action public deleteItem = (id: string) => {
		presetReportsAPI.delete(id)
			.then(() => this.onDeleteItemSuccess(id))
			.catch(() => this.onDeleteItemError());
	};
}
