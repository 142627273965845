import {OpenRtbReportingFilterType} from "../ReportingFilterType";
import countries from "@vidazoo/ui-framework/lib/common/countries";
import BaseFilter from "../BaseFilter";

export default class CountryFilter extends BaseFilter {

	public static type = OpenRtbReportingFilterType.Country;

	constructor() {
		super();

		this.items = countries;
		this.labelKey = "name";
		this.valueKey = "code";
		this.allowNew = false;
	}
}
