import * as _ from "lodash";
import * as moment from "moment";
import {observable, computed, action, transaction, toJS} from "mobx";
import {
	IDictionary,
	FieldType,
	formatNumber,
	IReportingUI, IReportRow, isRegExpStr
} from "@vidazoo/ui-framework";
import {filterReportingResults, extractReportingFieldValue} from "@vidazoo/ui-framework/lib/utils";
import {Density, SortDirection} from "@vidazoo/ui";
import {currentUserStore, notificationsStore, sessionStore} from "../../../common/stores";
import IReportingResults from "../../reporting/interfaces/IReportingResults";
import {multiWordFilter} from "@vidazoo/ui/lib/components/utils";

const defaultFieldFormat = (v) => formatNumber(v, 2);
const DATE_DIMENSIONS_FORMATS = {
	Month: "MM/YYYY",
	Day: "DD/MM/YYYY",
	Hour: "DD/MM/YYYY HH:mm",
	Time: "HH:mm",
};

export default class DataTableStore {

	@observable public ui: IReportingUI;
	@observable public searchQueries: IDictionary<string>;
	@observable public isLoading: boolean;
	@observable public loadedOnce: boolean;
	@observable public isSearched: boolean;
	@observable public error: Error;
	public results: any[];

	constructor(results) {
		this.reset(results);
	}

	@action public reset = (results) => {
		transaction(() => {
			this.ui = this.initialUIParams;
			this.results = results;
			this.isLoading = false;
			this.loadedOnce = false;
			this.isSearched = false;
			this.searchQueries = {};
			this.error = null;
		});
	}

	@action
	private setError(err: Error) {
		transaction(() => {
			this.isLoading = false;
			this.error = err;

			notificationsStore.pushErrorNotification({
				title: "Oops!",
				text: "Something went wrong. try again.",
				timeout: 5000
			});
		});
	}

	@action public setUIParam = (key: string, value: any) => {
		this.ui[key] = value;
	}

	@action
	public setPage(page) {
		this.ui.page = page;
	}

	@action
	public resetSort(sortDirection?: SortDirection, sortBy?: string) {
		transaction(() => {
			this.ui.sortDirection = sortDirection || SortDirection.ASC;
			this.ui.sortBy = sortBy || "";
		});
	}

	@action public loadMore = () => {
		if (this.filteredResults.length > this.ui.page * this.ui.pageSize) {
			this.setPage(this.ui.page + 1);
		}
	}

	@action public setSearchQuery = (key: string, query: string) => {
		transaction(() => {
			this.setPage(1);
			this.searchQueries[key] = query;
		});
	}

	@action public sort = (sortBy: string, withReverseDir: boolean = true) => {
		transaction(() => {

			this.setPage(1);

			if (sortBy === this.ui.sortBy && withReverseDir) {
				this.ui.sortDirection = this.ui.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
			}
			this.ui.sortBy = sortBy;

			const dateFormat = DATE_DIMENSIONS_FORMATS[this.ui.sortBy];

			const getValue = dateFormat
				? (item: any) => moment(extractReportingFieldValue(item.find((col) => col.name === this.ui.sortBy), "value"), dateFormat)
				: (item: any) => extractReportingFieldValue(item.find((col) => col.name === this.ui.sortBy), "value");

			(this.results as any).replace(_.orderBy(this.results, (item) => getValue(item), this.ui.sortDirection.toString() as any));
		});
	}

	@computed
	public get sortDir(): SortDirection {
		return this.ui.sortDirection;
	}

	@computed get filteredResults() {

		const hasQueries = _.compact(_.values(this.searchQueries)).length;

		if (hasQueries) {
			return _.filter(this.results, (item) => {
				const queryResults = [];

				_.forEach((item as any), (group) => {

					const groupQuery = _.find(this.searchQueries, (query, groupName) => {
						return group.name === groupName;
					});

					if (groupQuery) {
						const value: string = group.value ? group.value.toString() : "";
						let isValueMatchToRegExp = false;

						if (isRegExpStr(groupQuery)) {
							isValueMatchToRegExp = !!value.match(new RegExp(_.trim(groupQuery, "/"), "ig"));
						}

						queryResults.push(
							multiWordFilter(value, groupQuery) || isValueMatchToRegExp
						);
					}
				});

				return _.every(queryResults);
			});
		}

		return this.results;
	}

	@computed get filteredTotal(): any[] {
		return this.filteredResults;
	}

	private get initialUIParams(): IReportingUI {
		return {
			density: Density.Medium,
			page: 1,
			pageSize: 50,
			sortDirection: SortDirection.ASC,
			sortBy: ""
		};
	}
}
