import {action, observable} from "mobx";
import {ChartType, TimePresets} from "../../../common/enums";
import reportingService from "../../reporting/services/reportingService";
import {currentUserStore, editChartsManagerStore, metaDataStore} from "../../../common/stores";
import {socketService} from "../../../common/services";

export default class AddChartStore {

	@observable public selectedType: ChartType;
	@observable public selectedChart: string;

	@action public onSelectType = (type: ChartType) => {
		if (type === this.selectedType) {
			this.selectedType = null;
			return;
		}
		this.selectedType = type;
	};

	@action public selectChart = async (value: string) => {
		this.selectedChart = value;
		const storeByChart = await editChartsManagerStore.initChartStore(this.selectedChart);
		storeByChart.getReport();
	};
}
