import {action, observable, runInAction, transaction} from "mobx";
import * as _ from "lodash";
import BaseItemStore, {IBaseItemStore} from "@vidazoo/ui-framework/lib/stores/BaseItemStore";
import {REPORT_VERTICAL_TYPE} from "common/enums";
import {fastClone, guid, IReportingConstraint, IReportingEntry, IReportingFilter} from "@vidazoo/ui-framework";
import {IScheduleReport} from "../interfaces/IScheduleReport";
import {currentUserStore, notificationsStore} from "../../../common/stores";
import reportingService from "../../reporting/services/reportingService";
import reportingFiltersManager from "../../reporting/stores/filters/reportingFiltersManager";
import scheduleReportsAPI from "../api/scheduleReportsAPI";

export interface IBaseScheduleReportStore extends IBaseItemStore<IScheduleReport> {
	groups: IReportingEntry[];
	fields: IReportingEntry[];
	filters: IReportingEntry[];
	addConstraint: (values?: Partial<IReportingConstraint>) => IReportingConstraint;
	setConstraintParam: (constraint: IReportingConstraint, key: string, value: any) => void;
	addFilter: () => IReportingFilter;
	pushFilterValue: (filter: IReportingFilter, value: string, label: string) => void;
	removeFilterValue: (filter: IReportingFilter, value: string) => void;
	setFilterParam: (filter: IReportingFilter, key: string, value: any) => void;
}

export default abstract class BaseScheduleReportStore extends BaseItemStore<IScheduleReport> implements IBaseScheduleReportStore {

	@observable public groups: IReportingEntry[];
	@observable public fields: IReportingEntry[];
	@observable public filters: IReportingEntry[];
	@observable public runNowRecipients: string[];

	constructor() {
		super(notificationsStore, "schedule report");
		this.initReportingService();
	}

	public initReportingService = () => {
		reportingService.initialize();
	};

	@action
	public setItem(item: IScheduleReport, handleFilters?: boolean) {
		transaction(async () => {
			this.item = item;
			this.isLoading = false;

			if (handleFilters && item.filters.length > 0) {
				this.item.filters = [];

				for (let i = 0, len = item.filters.length; i < len; i++) {
					const filter = item.filters[i];

					const entry = reportingService.tryGetFilter(filter.key, this.item.verticalType);

					const newFilter = this.addFilter();

					runInAction(() => {
						newFilter.operator = filter.operator;
					});
					await this.setFilterKey(newFilter, entry.value);

					const stringList = newFilter.allowNew;

					_.forEach(filter.values, (value) => {
						const filterLabel = !stringList
							? reportingFiltersManager.getFilterListLabelByValue(newFilter, value, this.item.verticalType)
							: typeof (value) === "string"
								? value
								: null;

						if (filterLabel) {
							this.pushFilterValue(newFilter, value, filterLabel);
						}
					});
				}
			}
		});
	}

	public resetAgain = () => {
		this.reset();
	};

	@action
	public reset(params?: Partial<IScheduleReport>) {
		transaction(() => {
			super.reset();

			this.item = {
				...this.item,
				name: "",
				description: "",
				schedule: "",
				dateRange: "",
				timezone: "",
				fields: observable([]),
				groups: observable([]),
				filters: observable([]),
				constraints: observable([]),
				delimiter: ";",
				sendEmpty: true,
				recipients: [currentUserStore.email],
				dataSource: currentUserStore.isPublisher() ? "publisher" : "user",
				dataSourceIds: [],
				verticalType: REPORT_VERTICAL_TYPE.PLATFORM,
				...params
			};

			this.runNowRecipients = [currentUserStore.email];
		});
	}

	@action public addConstraint = (values?: Partial<IReportingConstraint>) => {
		this.item.constraints = this.item.constraints.concat({
			id: guid(),
			name: "",
			op: "",
			value: "",
			...values
		});

		return this.item.constraints[this.item.constraints.length - 1];
	};

	@action public setConstraintParam = (constraint: IReportingConstraint, key: string, value: any) => {
		constraint[key] = value;
	};

	@action public addFilter = (): IReportingFilter => {
		const filter = observable<IReportingFilter>({
			id: guid(),
			key: "",
			values: [],
			filterList: [],
			isLoading: false,
			filterValueKey: "",
			filterLabelKey: "",
			allowNew: false,
			exclude: false,
			operator: "",
		});

		this.item.filters.push(filter);

		return filter;
	};

	@action public pushFilterValue = (filter: IReportingFilter, value: string, label: string) => {
		let item: any = value;

		if (filter.filterLabelKey && filter.filterValueKey) {
			item = {
				[filter.filterLabelKey]: label,
				[filter.filterValueKey]: value
			};
		}

		filter.values = filter.values.concat(item);
	};

	@action public removeFilterValue = (filter: IReportingFilter, value: string) => {
		filter.values = filter.filterValueKey
			? _.filter(filter.values, (item) => item[filter.filterValueKey] !== value)
			: _.filter(filter.values, (item) => item !== value);
	};

	@action public setFilterParam = (filter: IReportingFilter, key: string, value: any) => {
		if (key === "key") {
			this.setFilterKey(filter, value);
			return;
		}

		filter[key] = value;
	};

	@action public setFilterKey = (filter: IReportingFilter, key: string): Promise<any> => {
		const filterHandler = reportingFiltersManager.getFilter(key, this.item.verticalType);

		filter.key = key;
		filter.filterLabelKey = filterHandler.labelKey;
		filter.filterValueKey = filterHandler.valueKey;
		filter.allowNew = filterHandler.allowNew;
		filter.isLoading = filterHandler.isLoading;
		filter.values = [];

		return filterHandler.initialize().then(action(() => {
			filter.filterList = filterHandler.items;
			filter.isLoading = filterHandler.isLoading;
		}));
	};

	@action
	public updateParam(key: string, value: any) {
		if (key === "schedule" && value !== "hourly" && this.item.dateRange === "today") {
			this.item.dateRange = "";
		}

		super.updateParam(key, value);
	}

	protected submitify(item?: IScheduleReport) {
		const cloneItem: any = fastClone(item || this.item);

		cloneItem.groups = cloneItem.groups.map((x) => ({value: x.value, label: x.label}));
		cloneItem.fields = cloneItem.fields.map((x) => ({value: x.value, label: x.label}));
		cloneItem.constraints = cloneItem.constraints.map((x) => ({name: x.name, op: x.op, value: x.value}));
		cloneItem.filters = cloneItem.filters.map((x) => {
			const filter = reportingFiltersManager.getFilter(x.key, this.item.verticalType);

			return filter.reportify(x);
		});

		return cloneItem;
	}

	@action public addToRunNowRecipients = (item: any) => {
		this.runNowRecipients = [...this.runNowRecipients, item];
	};

	@action public removeFromRunNowRecipients = (value: string, prop: string = "value") => {
		this.runNowRecipients = this.runNowRecipients.filter((item) => (prop ? item[prop] : item) !== value);
	};

	@action
	protected onLoadFail() {
		notificationsStore.pushErrorNotification({
			title: "Operation Failed",
			text: "Failed to load logs, try again",
			timeout: 5000
		});
	}

	@action public downloadCsv = (id, path) => {
		scheduleReportsAPI.getOneLog(id, path)
			.then((res) => res.data.startsWith("http") ? window.open(res.data) : this.onLoadFail())
			.catch(() => this.onLoadFail());
	};
}
