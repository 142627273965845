import * as React from "react";
import * as PropTypes from "prop-types";
import * as theme from "styles/_baseForm.scss";
import {Chips, FormInput, FormSelect, AggregateChangeComponent, Checkbox, Spacer} from "@vidazoo/ui";
import {FormMode, normalizeEventValue} from "@vidazoo/ui-framework";
import formFieldFactory from "@vidazoo/ui/lib/components/formField";
import TimezonePicker, {ITimezonePickerProps} from "@vidazoo/ui/lib/components/timezonePicker";
import {ReportDateRange, ReportDelimiters, ReportSchedules} from "../../../common/scheduleReportEnums";
import CreateScheduleReportStore from "../../../stores/CreateScheduleReportStore";
import EditScheduleReportStore from "../../../stores/EditScheduleReportStore";
import {ReportVerticalTypes} from "../../../../../common/enums";

const FormTimezonePicker = formFieldFactory<ITimezonePickerProps>(TimezonePicker as any);

export interface IDetailsFormProps {
	mode: FormMode;
	store: EditScheduleReportStore | CreateScheduleReportStore;
}

export interface IDetailsFormState {

}

export default class DetailsForm extends React.Component<IDetailsFormProps, IDetailsFormState> {

	public static defaultProps: Partial<IDetailsFormProps> = {};

	constructor(props, context) {
		super(props, context);
	}

	private onChangeDetail = (e) => {
		const {store} = this.props;
		const value = normalizeEventValue(e);
		store.updateParam(e.target.name, value);
	};

	private onAddRecipient = (value: string, label: string, isNew: boolean, name: string) => {
		const {store} = this.props;
		store.addToArray("recipients", value);
	};

	private onRemoveRecipient = (value: string, label: string, name: string) => {
		const {store} = this.props;
		store.removeFromArray("recipients", value, null);
	};

	public render(): JSX.Element {
		const {mode, store} = this.props;
		const {item} = store;

		const DateRanges = item.schedule === "hourly" ? [{
			label: "Today",
			value: "today"
		}].concat(ReportDateRange) : ReportDateRange;

		return (
			<div className={theme.form}>
				<AggregateChangeComponent onChange={this.onChangeDetail}>
					<div className={theme.row}>
						<FormInput value={item.name} autoFocus className={theme.input} name="name" label="Name" required
								   fill/>
					</div>
					<div className={theme.row}>
						<FormInput value={item.description} className={theme.input} name="description"
								   label="Description" fill/>
					</div>
					<div className={theme.row}>
						<FormSelect items={ReportVerticalTypes} fill maxItems={5}
									searchable required name="verticalType" label="Vertical Type"
									value={item.verticalType}
									className={theme.input} itemValueKey="value" itemLabelKey="label"/>
					</div>
					<div className={theme.row}>
						<FormSelect value={item.schedule} name="schedule" className={theme.input} fill label="Schedule"
									itemLabelKey="label" itemValueKey="value" required items={ReportSchedules}
									maxItems={5}/>
					</div>
					<div className={theme.row}>
						<Chips name="recipients" searchPlaceholder="Add Recipient Email" allowCreate={true}
							   allowOrder={false} onSelect={this.onAddRecipient} onDelete={this.onRemoveRecipient}
							   chips={item.recipients} fill/>
					</div>
					<div className={theme.row}>
						<FormSelect value={item.dateRange} name="dateRange" className={theme.input} fill
									label="Date Range" itemLabelKey="label" itemValueKey="value" required
									items={DateRanges} maxItems={5}/>
						<FormTimezonePicker timezone={item.timezone} name="timezone" label="Timezone" searchable
											required className={theme.input}/>
						<FormSelect value={item.delimiter} name="delimiter" className={theme.input} fill
									label="Delimiter" itemLabelKey="label" itemValueKey="value" required
									items={ReportDelimiters}/>
					</div>
					<div className={theme.row}>
						<Spacer right={48}>
							<Checkbox defaultChecked={item.isActive} name="isActive" label="Active"/>
						</Spacer>
						<Spacer right={48}>
							<Checkbox defaultChecked={item.sendEmpty} name="sendEmpty"
									  label="Send report even if empty"/>
						</Spacer>
					</div>
				</AggregateChangeComponent>
			</div>
		);
	}
}
