/************************************************
*                                               *
*                  BOOTSTRAP                    *
*                                               *
************************************************/

import "styles/main.scss";
import { configure } from "mobx";
import { sessionStore } from "common/stores";
import { stringify } from "qs";
import axios from "axios";

import "./serviceWorker";
import "./svgSpriteBuilder";

/* tslint:disable no-var-requires */
const jsonlint = require("jsonlint-mod");
(window as any).jsonlint = jsonlint;

// axios.defaults.paramsSerializer = (params) => {
// 	return stringify(params, {
// 		arrayFormat: "brackets",
// 		encode: true
// 	});
// };

configure({
	enforceActions: "always"
});

sessionStore.initialize();
