import * as React from "react";
import IDashboard from "../../interfaces/IDashboard";
import {Button, Label, Surface} from "@vidazoo/ui";
import DashboardRow from "./DashboardRow";
import * as selfTheme from "./theme.scss";
import {cloudStore, dashboardsStore} from "../../../../common/stores";
import * as theme from "./theme.scss";
import {observer} from "mobx-react";

export interface IDashboardListProps extends React.InputHTMLAttributes<HTMLInputElement> {
	onAddItem: () => void;
}

class DashboardsList extends React.Component<IDashboardListProps, any> {

	private ref: HTMLElement;

	constructor(props, context) {
		super(props, context);
		this.state = {searchValue: ""};
	}

	public componentDidMount() {

		if (this.ref) {
			const onWheel = (e) => {
				if (e.deltaY === 0) {
					return;
				}
				e.preventDefault();
				this.ref.scrollTo({
					left: this.ref.scrollLeft + e.deltaY,
					behavior: "smooth"
				});
			};
			this.ref.addEventListener("wheel", onWheel);
			return () => this.ref.removeEventListener("wheel", onWheel);
		}
	}

	private setRef = (el) => {
		this.ref = el;
	};

	public render(): JSX.Element {

		const {onAddItem} = this.props;

		return (
			<div>
				<Surface level={1} className={selfTheme.list}
						 style={dashboardsStore.isDashboardsListOpen ? {} : {display: "none"}}>
					<div className={selfTheme.header}>
						<Label weight={600} block={false}>Dashboards</Label>
						<Button iconName="icon-plus-small" medium blue onClick={onAddItem}>Create
							Dashboard</Button>
					</div>
					<div ref={this.setRef} className={theme.rowsOutside}>
						<div className={theme.rows}>
							{dashboardsStore.items.filter((item) => !!cloudStore.dashboardsStarsById[item._id]).map((item) =>
								<DashboardRow key={item._id} item={item}/>)}
							{dashboardsStore.items.filter((item) => !cloudStore.dashboardsStarsById[item._id]).map((item) =>
								<DashboardRow key={item._id} item={item}/>)}
						</div>
					</div>
				</Surface>
				<Button className={selfTheme.btn} iconName={dashboardsStore.isDashboardsListOpen ? "icon-arrow-up-small" : "icon-arrow-down-small"} medium green
						onClick={dashboardsStore.toggleDashboardsListOpen}>Dashboards</Button>
			</div>
		);
	}
}

export default observer(DashboardsList);
