import * as React from "react";
import {observer} from "mobx-react";
import {RouteComponentProps} from "react-router";
import {Surface, Form, Button, PageContainer, SliderPanel, DateTimeRangePicker, LinkButton, Icon} from "@vidazoo/ui";
import ChangeLogsContext from "../../containers/ChangeLogsContext";
import * as theme from "./theme.scss";
import {FilterOptions, FilterTypes, FilterOperators, AUTH_SCOPE_TYPES} from "../../../../common/enums";
import {FormSelect} from "@vidazoo/ui/lib/components/select";
import {IChangeLogFilter} from "../../interfaces/IChangeLogFilter";
import ScopeFilter from "./filters/ScopeFilter";
import AccountFilter from "./filters/AccountFilter";
import PlatformTagsFilter from "./filters/PlatformTagsFilter";
import UsersFilter from "./filters/UsersFilter";
import ActionFilter from "./filters/ActionFilter";
import ModelIdFilter from "./filters/ModelIdFilter";
import ModelNameFilter from "./filters/ModelNameFilter";

export interface IChangeLogsFilterProps extends RouteComponentProps<any> {
	index: number;
	filter: IChangeLogFilter;
}

export interface IChangeLogsFilterState {

}

@observer
export default class ChangeLogsFilter extends React.Component<IChangeLogsFilterProps, IChangeLogsFilterState> {

	public static defaultProps: Partial<IChangeLogsFilterProps> = {};
	public static contextType = ChangeLogsContext;

	constructor(props, context) {
		super(props, context);
	}

	private onChangeFilterParam = (e) => {
		const {filter} = this.props;
		const {setFilterParam} = this.context.store;
		const {name, value} = e.target;
		setFilterParam(filter, name, value);
	};

	private onDelete = () => {
		const {index} = this.props;
		const {removeFilter} = this.context.store;
		removeFilter(index);
	};

	private renderFilterByType = () => {
		const {filter} = this.props;
		switch (filter.type) {
			case FilterTypes.Scope:
				return <ScopeFilter context={this.context} filter={filter}/>;
			case FilterTypes.Account:
				return <AccountFilter context={this.context} filter={filter}/>;
			case FilterTypes.PlatformTags:
				return <PlatformTagsFilter context={this.context} filter={filter}/>;
			case FilterTypes.User:
				return <UsersFilter context={this.context} filter={filter}/>;
			case FilterTypes.Action:
				return <ActionFilter context={this.context} filter={filter}/>;
			case FilterTypes.ModelId:
				return <ModelIdFilter context={this.context} filter={filter}/>;
			case FilterTypes.ModelName:
				return <ModelNameFilter context={this.context} filter={filter}/>;
		}
	};

	public render(): JSX.Element {
		const {index, filter} = this.props;
		const {params} = this.context.store;
		if (params) {
			return (
				<div className={theme.filter}>
					<div className={theme.label}>
						Filter {index + 1}
					</div>
					<div className={theme.actions}>
						<FormSelect
							className={theme.select}
							items={FilterOptions}
							maxItems={5}
							itemValueKey="value"
							itemLabelKey="label"
							value={filter.type}
							name="type"
							required
							hideValidationMessage
							onChange={this.onChangeFilterParam}
							searchable
						/>
						{this.renderFilterByType()}
						<Button className={theme.delete} type="button" medium flat uppercase iconName="icon-delete"
								onClick={this.onDelete}/>
					</div>
				</div>
			);
		}
	}
}
