"use strict"
import * as React from "react";
import * as selfTheme from "./theme.scss";
import {ChromePicker} from "react-color";

interface IColorPickerProps {
	color: string;
	name: string;
	label?: string;
	onChange: (color: any, name: string) => void;
}

interface IColorPickerState {
}

class ColorPicker extends React.Component<IColorPickerProps, IColorPickerState> {
	public state = {
		displayColorPicker: false,
	};

	private handleClick = () => {
		this.setState({displayColorPicker: !this.state.displayColorPicker})
	};

	private handleClose = () => {
		this.setState({displayColorPicker: false})
	};

	private handleChange = (color) => {
		this.props.onChange(color, this.props.name)
	}

	public render() {
		return (
			<div className={selfTheme.colorPicker}>
				<div className={selfTheme.label}>{this.props.label}</div>
				<div className={selfTheme.swatch} onClick={this.handleClick}>
					<div className={selfTheme.color} style={{backgroundColor: this.props.color}}/>
				</div>
				{this.state.displayColorPicker ? <div className={selfTheme.popover}>
					<div className={selfTheme.cover} onClick={this.handleClose}/>
					<ChromePicker color={this.props.color} onChange={this.handleChange}/>
				</div> : null}
			</div>
		)
	}
}

export default ColorPicker;
